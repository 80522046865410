//
// _contact.scss
//

#remove-actions {
    display: none;
}

.contact-sidebar-menu {
    ul {
        li {
            a {
                display: block;
                padding: 6px 0px;
                color: var(--#{$prefix}body-color);
                transition: all 0.5s ease;
                &:hover, &.active {
                    color: var(--#{$prefix}primary);
                }
            }
        }
    }
}