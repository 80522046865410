@charset "UTF-8";
/*
Template Name: Hybrix - Admin & Dashboard Template
Author: Themesbrand
Version: 1.1.0
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
.top-tagbar {
  background-color: #31404a;
  z-index: 1004;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 30px;
  padding: 0 calc(1.5rem * 1.2) 0 calc(1.5rem * 0.8);
  display: flex;
  align-items: center;
}

.topbar-tag-dropdown {
  height: 30px;
  display: flex;
  align-items: center;
}
.topbar-tag-dropdown .btn-icon {
  height: auto;
  width: auto;
}
.topbar-tag-dropdown .dropdown-menu {
  top: 4px !important;
}

#page-topbar {
  position: fixed;
  top: 30px;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: var(--tb-header-bg);
  transition: all 0.1s ease-out;
  border-bottom: 1px solid var(--tb-border-color);
}
#page-topbar.topbar-shadow {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 3px;
}
@media (min-width: 768px) {
  #page-topbar {
    left: 250px;
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 1.5rem 0 calc(1.5rem / 2);
}
@media (max-width: 767.98px) {
  .navbar-header {
    padding: 0 calc(1.5rem / 2) 0 calc(1.5rem / 2);
  }
}
.navbar-header .topbar-head-dropdown .dropdown-menu.show {
  top: 13px !important;
}
.navbar-header .topbar-head-dropdown .notification-actions {
  display: none;
  position: absolute;
  background-color: var(--tb-dropdown-bg);
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7px;
  border-top: 1px solid var(--tb-border-color);
}
.navbar-header .btn-topbar {
  height: 42px;
  width: 42px;
}
@media (max-width: 360px) {
  .navbar-header .btn-topbar {
    height: 36px;
    width: 36px;
  }
}
.navbar-header .user-name-text {
  color: var(--tb-header-item-color);
}
.navbar-header .user-name-sub-text {
  color: var(--tb-header-item-sub-color);
}

/* Search */
.app-search {
  padding: calc(32px / 2) 0;
}
.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 30px;
  box-shadow: none;
  background-color: transparent;
  transition: all 0.5s ease;
}
.app-search .form-control:hover, .app-search .form-control:focus, .app-search .form-control:active {
  background-color: #fff;
}
.app-search span.search-widget-icon {
  position: absolute;
  z-index: 10;
  font-size: 18px;
  line-height: 38px;
  left: 13px;
  top: 0;
  color: #878a99;
  cursor: pointer;
}
.app-search .search-widget-icon-close {
  right: 7px;
  left: auto !important;
}
@media (max-width: 1023.99px) {
  .app-search {
    padding-left: calc(1.5rem / 2);
  }
}

#searchModal {
  top: 6px;
}
#searchModal .form-control {
  padding-left: 45px;
  padding-right: 55px;
}
#searchModal .search-widget-icon {
  position: absolute;
  left: 18px;
  top: 15px;
}
#searchModal .search-widget-icon-close {
  right: 18px;
  left: auto;
}
#searchModal .dropdown-menu {
  top: 94px;
  width: 100%;
}
#searchModal .dropdown-menu .list-group-item {
  border: none !important;
}

.megamenu-list li {
  position: relative;
  padding: 5px 0px;
}
.megamenu-list li a {
  color: var(--tb-body-color);
}

@media (max-width: 767.98px) {
  .logo span.logo-lg {
    display: none;
  }
  .logo span.logo-sm {
    display: inline-block;
  }
}
.header-item {
  height: 70px;
  display: flex;
  align-items: center;
}

.header-profile-user {
  height: 32px;
  width: 32px;
}

.topbar-badge-sm {
  right: 0;
  top: 7px !important;
}

.topbar-badge {
  right: -9px;
  top: 4px !important;
}

@media (min-width: 768px) {
  .topbar-user {
    background-color: var(--tb-topbar-user-bg);
  }
}
.topbar-user .dropdown-menu {
  top: 6px !important;
}

.notification-item {
  padding: 0.75rem 1rem;
  white-space: inherit;
  position: relative;
  border-bottom: 1px solid var(--tb-border-color);
}
.notification-item .form-check-input {
  position: relative;
  z-index: 2;
}
.notification-item .active-badge {
  top: 25px;
  border: 2px solid var(--tb-light);
}
.notification-item.unread-message {
  background-color: rgba(var(--tb-light-rgb), 1.5);
}
.notification-item.unread-message:active, .notification-item.unread-message.active {
  background-color: rgba(var(--tb-light-rgb), 2.5);
}
.notification-item:last-child {
  border-bottom: none;
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  border: 1px solid transparent;
  color: var(--tb-dropdown-link-color);
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  background-color: var(--tb-dropdown-link-hover-bg);
}

.fullscreen-enable [data-toggle=fullscreen] .bx-fullscreen::before {
  content: "\eacb";
}

[data-bs-theme=dark] .mode-layout .bi-sun::before {
  content: "\f497";
}

.mode-auto .mode-layout .bi-sun::before {
  content: "\f496" !important;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }
  .navbar-header .dropdown .dropdown-menu {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  #search-dropdown-reponsive {
    top: 54px !important;
  }
}
@media (min-width: 1024.1px) {
  [data-layout=vertical][data-layout-style=detached] #page-topbar {
    left: 0 !important;
    box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px;
  }
  [data-layout=vertical][data-layout-style=detached] .horizontal-logo {
    display: inline-block;
    padding-left: 0;
    width: auto;
  }
  [data-layout=vertical][data-layout-style=detached] .topnav-hamburger {
    visibility: hidden;
  }
  [data-layout=vertical][data-layout-style=detached] .layout-width {
    max-width: 95%;
    margin: 0 auto;
  }
  [data-layout=vertical][data-layout-style=detached]:is([data-sidebar-size=sm], [data-sidebar-size=sm-hover]) .navbar-brand-box {
    background-color: transparent !important;
    position: relative;
    width: auto;
    text-align: left;
  }
  [data-layout=vertical][data-layout-style=detached]:is([data-sidebar-size=sm], [data-sidebar-size=sm-hover]) .navbar-brand-box .logo-sm {
    display: none;
  }
  [data-layout=vertical][data-layout-style=detached]:is([data-sidebar-size=sm], [data-sidebar-size=sm-hover]) .navbar-brand-box .logo-lg {
    display: block;
  }
}
[data-layout=vertical][data-layout-style=detached][data-topbar=dark] .horizontal-logo .logo-dark {
  display: none;
}
[data-layout=vertical][data-layout-style=detached][data-topbar=dark] .horizontal-logo .logo-light {
  display: block;
}
[data-layout=vertical][data-layout-style=detached]:is([data-sidebar=gradient], [data-sidebar=gradient-2], [data-sidebar=gradient-3], [data-sidebar=gradient-4], [data-sidebar=dark]) .horizontal-logo .logo-dark {
  display: block;
}
[data-layout=vertical][data-layout-style=detached]:is([data-sidebar=gradient], [data-sidebar=gradient-2], [data-sidebar=gradient-3], [data-sidebar=gradient-4], [data-sidebar=dark]) .horizontal-logo .logo-light {
  display: none;
}

[data-layout=horizontal] #page-topbar {
  left: 0;
  border-bottom: 1px solid var(--tb-border-color);
}
@media (min-width: 1024.1px) {
  [data-layout=horizontal] #page-topbar.topbar-shadow {
    box-shadow: none;
  }
}
[data-layout=horizontal] .page-content {
  margin-top: calc(70px + 30px);
  padding: calc(45px + 1.5rem) calc(1.5rem * 0.5) 60px calc(1.5rem * 0.5);
}
@media (max-width: 991.98px) {
  [data-layout=horizontal] .page-content {
    padding-top: calc(10px + 1.5rem);
  }
}
@media (min-width: 1024.1px) {
  [data-layout=horizontal][data-layout-width=boxed] .page-content {
    min-height: calc(100vh - 130px);
  }
}

@media (min-width: 768px) {
  [data-layout=vertical]:is([data-sidebar-size=sm], [data-sidebar-size=sm-hover]) #page-topbar {
    left: 70px;
  }
}
@media (min-width: 768px) {
  [data-layout=vertical][data-sidebar-size=md] #page-topbar {
    left: 180px;
  }
}

@media (min-width: 768px) {
  [data-layout=twocolumn] #page-topbar {
    left: calc(70px + 220px);
  }
}
[data-layout=twocolumn] .horizontal-logo {
  display: none;
}

[data-bs-theme=light][data-topbar=dark] .navbar-header .btn.header-item {
  color: #fff !important;
}
[data-bs-theme=light][data-topbar=dark] .user-name-sub-text {
  color: #fff !important;
}

.page-title-box {
  padding: 0 0 20px;
}
.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}
.page-title-box h4 {
  font-weight: 700;
  font-size: 15px !important;
  text-transform: uppercase;
}

[data-layout=horizontal] .page-title-box {
  padding: 1.2rem 0;
  background-color: transparent !important;
  border-bottom: none;
  border-top: none;
  box-shadow: none;
  margin: 0;
}
@media (min-width: 1024.1px) {
  [data-layout=horizontal] .page-title-box {
    margin: -19px 0 0 0;
  }
}

[data-layout=vertical][data-layout-style=detached] .page-title-box {
  padding: 1.2rem 0;
  background-color: transparent !important;
  border-bottom: none;
  border-top: none;
  box-shadow: none;
  margin: 0;
}
@media (min-width: 1024.1px) {
  [data-layout=vertical][data-layout-style=detached] .page-title-box {
    margin: -19px 0 0 0;
  }
}

.footer {
  bottom: 0;
  padding: 20px calc(1.5rem * 0.5);
  position: absolute;
  right: 0;
  color: var(--tb-footer-color);
  left: 250px;
  height: 60px;
  background-color: var(--tb-footer-bg);
  border-top: 1px solid var(--tb-border-color);
}
@media (max-width: 991.98px) {
  .footer {
    left: 0;
  }
}

[data-layout=vertical][data-sidebar-size=sm] .footer, [data-layout=vertical][data-sidebar-size=sm-hover] .footer {
  left: 70px;
}
@media (max-width: 767.98px) {
  [data-layout=vertical][data-sidebar-size=sm] .footer, [data-layout=vertical][data-sidebar-size=sm-hover] .footer {
    left: 0;
  }
}
[data-layout=vertical][data-sidebar-size=md] .footer {
  left: 180px;
}
@media (max-width: 991.98px) {
  [data-layout=vertical][data-sidebar-size=md] .footer {
    left: 0;
  }
}

[data-layout=horizontal] .footer {
  left: 0 !important;
}

@media (min-width: 1024.1px) {
  [data-layout=vertical][data-layout-style=detached] .footer {
    left: 0 !important;
    background-color: transparent;
  }
}

@media (min-width: 768.1px) {
  [data-layout=twocolumn] .footer {
    left: calc(70px + 220px);
  }
}

.footer-landing {
  border-top: 1px solid #eff2f7;
}
.footer-landing .footer-desc {
  color: rgba(20, 24, 33, 0.8);
}
.footer-landing .logo-light {
  display: none;
}
.footer-landing .logo-dark {
  display: block;
}
.footer-landing .footer-title {
  color: #1f242e;
}
.footer-landing .footer-link a {
  color: rgba(20, 24, 33, 0.8);
  line-height: 38px;
  font-size: 14px;
  transition: all 0.5s;
}
.footer-landing .footer-link a:hover {
  color: rgba(20, 24, 33, 0.9);
  text-decoration: underline !important;
}
.footer-landing .footer-social i {
  width: 34px;
  height: 34px;
  display: inline-block;
  line-height: 34px;
  background-color: rgba(20, 24, 33, 0.1);
  color: rgba(20, 24, 33, 0.6);
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.5s ease;
}
.footer-landing .footer-social i:hover {
  color: #fff;
  background-color: #438eff;
}
.footer-landing .footer-border-alt {
  padding: 30px 0px;
  border-top: 1px solid rgba(20, 24, 33, 0.1);
  color: rgba(20, 24, 33, 0.8);
}

[data-footer=dark] .footer-landing {
  background-color: #141821;
  border-top-color: #141821;
}
[data-footer=dark] .footer-landing .logo-light {
  display: block;
}
[data-footer=dark] .footer-landing .logo-dark {
  display: none;
}
[data-footer=dark] .footer-landing .footer-link a {
  color: rgba(255, 255, 255, 0.4);
  line-height: 38px;
  font-size: 14px;
  transition: all 0.5s;
}
[data-footer=dark] .footer-landing .footer-link a:hover {
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.5s;
  text-decoration: underline !important;
}
[data-footer=dark] .footer-landing .footer-social i {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  transition: all 0.5s;
}
[data-footer=dark] .footer-landing .footer-social i:hover {
  color: #fff;
  background-color: #8561f9;
}
[data-footer=dark] .footer-landing .footer-title {
  color: #e9ebec;
}
[data-footer=dark] .footer-landing .footer-desc {
  color: rgba(255, 255, 255, 0.4);
}
[data-footer=dark] .footer-landing .footer-border-alt {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.5);
}

.app-content {
  margin-left: 250px;
  overflow: hidden;
}
.app-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

.main-content {
  transition: all 0.1s ease-out;
}
@media (min-width: 768px) {
  .main-content {
    margin-left: 250px;
  }
}

.page-content {
  padding: calc(70px + 30px + 1.5rem) calc(1.5rem * 0.7) 60px calc(1.5rem * 0.5);
}

.navbar-menu {
  width: 250px;
  z-index: 1002;
  background: var(--tb-vertical-menu-bg);
  border-right: 1px solid var(--tb-vertical-menu-border);
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 30px;
  padding: 0 0 calc(70px + 25px) 0;
  transition: all 0.1s ease-out;
}
.navbar-menu .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.5rem;
  color: var(--tb-vertical-menu-item-color);
  font-size: 0.9375rem;
  font-family: "Gantari", sans-serif;
}
.navbar-menu .navbar-nav .nav-link.active {
  color: var(--tb-vertical-menu-item-active-color);
}
.navbar-menu .navbar-nav .nav-link:hover {
  color: var(--tb-vertical-menu-item-hover-color);
}
.navbar-menu .navbar-nav .nav-link i {
  display: inline-block;
  min-width: var(--tb-vertical-menu-item-icon-width);
  font-size: 16px;
  line-height: inherit;
}
.navbar-menu .navbar-nav .nav-link svg {
  width: 18px;
  margin-right: 0.665rem;
  color: var(--tb-vertical-menu-item-color);
}
.navbar-menu .navbar-nav .nav-link .badge {
  margin-left: auto;
  margin-right: -2px;
  z-index: 1;
}
.navbar-menu .navbar-nav .nav-link:hover {
  color: var(--tb-vertical-menu-item-hover-color);
}
.navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  display: block;
  content: "\f0142";
  font-family: "Material Design Icons";
  margin-left: auto;
  transition: transform 0.2s;
  font-size: 1.05rem;
  position: absolute;
  right: 18px;
  color: var(--tb-vertical-menu-title-color);
}
.navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
  color: var(--tb-vertical-menu-item-active-color);
}
.navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  transform: rotate(90deg);
  color: var(--tb-vertical-menu-item-active-color);
}
.navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:before {
  opacity: 1;
  background-color: var(--tb-vertical-menu-item-active-color);
}
.navbar-menu .navbar-nav .nav-link.collapsed.active::after {
  transform: rotate(90deg);
}
.navbar-menu .navbar-nav .nav-sm {
  padding-left: var(--tb-vertical-menu-item-icon-width);
}
.navbar-menu .navbar-nav .nav-sm .nav-link {
  padding: 0.55rem 1.5rem !important;
  color: var(--tb-vertical-menu-sub-item-color);
  white-space: none;
  position: relative;
  font-size: 0.9375rem;
  font-family: "Gantari", sans-serif;
}
.navbar-menu .navbar-nav .nav-sm .nav-link:hover {
  color: var(--tb-vertical-menu-sub-item-hover-color);
}
.navbar-menu .navbar-nav .nav-sm .nav-link.active {
  color: var(--tb-vertical-menu-item-active-color);
}
.navbar-menu .navbar-nav .nav-sm .nav-sm {
  padding-left: 15px;
}
.navbar-menu .navbar-nav .nav-sm .nav-sm .nav-link:before {
  height: 5px;
  width: 5px;
  left: 5px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid;
  top: 16px;
}
.navbar-menu .btn-vertical-sm-hover {
  color: var(--tb-secondary-color);
  display: none;
}

.navbar-brand-box {
  text-align: center;
  padding: 0 1.3rem;
  transition: all 0.1s ease-out;
}
@media (max-width: 767.98px) {
  .navbar-brand-box {
    display: none;
  }
}

.hamburger-icon {
  width: 20px;
  height: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.hamburger-icon span {
  background-color: var(--tb-header-item-sub-color);
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 2px;
  display: block;
  left: 0px;
}
.hamburger-icon span:nth-child(1) {
  top: 0;
  width: 80%;
}
.hamburger-icon span:nth-child(2) {
  top: 6px;
}
.hamburger-icon span:nth-child(3) {
  bottom: 0;
  width: 60%;
}
.vertical-menu-btn:hover .hamburger-icon:not(.open) span:nth-child(1) {
  top: -1px;
}
.vertical-menu-btn:hover .hamburger-icon:not(.open) span:nth-child(3) {
  bottom: -1px;
}
.hamburger-icon.open {
  transform: rotate(-90deg);
}
.hamburger-icon.open span:nth-child(1) {
  left: 1px;
  top: 5px;
  width: 20px;
  transform: rotate(90deg);
  transition-delay: 150ms;
}
.hamburger-icon.open span:nth-child(2) {
  left: 3px;
  top: 13px;
  width: 10px;
  transform: rotate(45deg);
  transition-delay: 50ms;
}
.hamburger-icon.open span:nth-child(3) {
  left: 9px;
  top: 13px;
  width: 10px;
  transform: rotate(-45deg);
  transition-delay: 100ms;
}

.logo {
  line-height: 70px;
}
.logo .logo-sm {
  display: none;
}

.logo-light {
  display: none;
}

[data-layout=vertical] {
  /********** sm-hover **********/
  /********** sm-hover-active **********/
  /********** sidebar sm **********/
  /********** sidebar md **********/
  /********** dark **********/
  /********** light **********/
  /********** detached **********/
}
[data-layout=vertical] .app-menu .row {
  margin: 0;
}
[data-layout=vertical] .app-menu .row > * {
  width: 100%;
  padding: 0;
}
@media (max-width: 767.98px) {
  [data-layout=vertical] .app-menu {
    margin-left: -100%;
    padding: 10px 0 20px 0;
  }
}
[data-layout=vertical] .navbar-menu .container-fluid {
  padding: 0;
}
@media (max-width: 767.98px) {
  [data-layout=vertical] .navbar-brand-box {
    display: none;
  }
}
[data-layout=vertical] .horizontal-logo {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .main-content {
  margin-left: 70px;
}
@media (max-width: 767.98px) {
  [data-layout=vertical][data-sidebar-size=sm-hover] .main-content {
    margin-left: 0;
  }
}
[data-layout=vertical][data-sidebar-size=sm-hover] .logo span.logo-lg {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .logo span.logo-sm {
  display: inline-block;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .btn-vertical-sm-hover {
  display: inline-block;
}
@media (min-width: 768px) {
  [data-layout=vertical][data-sidebar-size=sm-hover] .topnav-hamburger {
    display: none;
  }
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu {
  width: 70px;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .btn-vertical-sm-hover {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .btn-vertical-sm-hover i.ri-record-circle-line:before {
  content: "\eb7d";
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .badge {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .menu-title {
  text-align: center;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .menu-title span {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .menu-title i {
  display: block;
  line-height: 36px;
  font-size: 1rem;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .nav-link span {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .nav-link i {
  font-size: 18px;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .nav-link:after, [data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .nav-link:before {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu .navbar-nav .menu-dropdown {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover {
  width: 250px !important;
  border-right: 1px solid #eff2f7;
}
@media (min-width: 1024.99px) {
  [data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .btn-vertical-sm-hover {
    display: inline-block;
  }
  [data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .navbar-brand-box {
    text-align: left;
  }
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .navbar-nav .menu-dropdown.show {
  display: block;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .nav-link i {
  font-size: 18px;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .nav-link span, [data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .nav-link:after, [data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .nav-link:before {
  display: inline-block;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .logo span.logo-lg {
  display: inline-block;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .logo span.logo-sm {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .menu-title {
  text-align: left;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .menu-title span {
  display: inline-block;
}
[data-layout=vertical][data-sidebar-size=sm-hover] .navbar-menu:hover .menu-title i {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover-active] .navbar-brand-box {
  text-align: left;
}
[data-layout=vertical][data-sidebar-size=sm-hover-active] .topnav-hamburger {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm-hover-active] .btn-vertical-sm-hover {
  display: inline-block;
}
@media (min-width: 1025px) {
  [data-layout=vertical]:is([data-sidebar-size=sm-hover], [data-sidebar-size=sm-hover-active]) .navbar-header {
    padding-left: 1.5rem;
  }
}
@media (min-width: 768px) {
  [data-layout=vertical][data-sidebar-size=sm] {
    min-height: 1400px;
  }
  [data-layout=vertical][data-sidebar-size=sm] .main-content {
    margin-left: 70px;
  }
}
[data-layout=vertical][data-sidebar-size=sm] #page-topbar {
  z-index: calc(1002 + 1);
}
[data-layout=vertical][data-sidebar-size=sm] .logo span.logo-lg {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm] .logo span.logo-sm {
  display: inline-block;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu {
  position: absolute;
  width: 70px !important;
  padding-top: 70px;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .simplebar-mask,
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .simplebar-content-wrapper {
  overflow: visible !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .simplebar-scrollbar,
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .vertical-menu-btn {
  display: none !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .simplebar-offset {
  bottom: 0 !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .badge {
  display: none !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .menu-title {
  text-align: center;
  font-size: 1rem;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .menu-title span {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .menu-title i {
  display: block;
  line-height: 36px;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-link span {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-link i {
  font-size: 18px;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-link svg {
  margin-right: 0px;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-link:after, [data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-link:before {
  display: none;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .menu-dropdown {
  display: none;
  height: auto !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-item {
  position: relative;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-item:hover > a.menu-link {
  position: relative;
  width: calc(200px + 70px);
  color: #fff;
  background: var(--tb-vertical-menu-item-hover-bg);
  transition: none;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-item:hover > a.menu-link span {
  display: inline-block;
  padding-left: 25px;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-item:hover > a.menu-link:after {
  display: block;
  transform: rotate(90deg);
  color: #fff;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-item:hover > .menu-dropdown {
  display: block;
  left: 70px;
  position: absolute;
  width: 200px;
  background: var(--tb-vertical-menu-bg);
  height: auto !important;
  padding: 0.5rem 0;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm {
  padding: 0;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm .nav-item:hover > .nav-link {
  color: var(--tb-vertical-menu-item-hover-color);
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm .nav-item:hover > .nav-link:after {
  color: inherit;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm .nav-link:after {
  display: block !important;
  transform: rotate(0deg) !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm .menu-dropdown {
  left: 100% !important;
  top: 0;
  border-radius: 3px !important;
}
[data-layout=vertical][data-sidebar-size=sm] .navbar-brand-box {
  position: fixed;
  padding: 0;
  width: 69px;
  z-index: 1;
  top: 30px;
  background: var(--tb-vertical-menu-bg);
}
[data-layout=vertical][data-sidebar-size=sm][data-layout-style=detached] .navbar-brand-box {
  top: auto;
}
@media (min-width: 768px) {
  [data-layout=vertical][data-sidebar-size=md] .main-content {
    margin-left: 180px;
  }
}
[data-layout=vertical][data-sidebar-size=md] .navbar-brand-box {
  width: 180px;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu {
  width: 180px !important;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-link {
  display: block;
  text-align: center;
  padding: 0.55rem 0.525rem;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-link i {
  display: block;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-link svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-link:before {
  display: none !important;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  position: relative;
  display: inline-block;
  right: 0;
  top: 3px;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-link.menu-link[data-bs-toggle=collapse]:after {
  display: none;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .badge {
  display: none !important;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .navbar-nav .nav-sm {
  padding-left: 0;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .menu-title {
  text-align: center;
}
[data-layout=vertical][data-sidebar-size=md] .navbar-menu .menu-title span {
  text-decoration: underline;
}
[data-layout=vertical][data-sidebar=dark][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm .nav-link:after {
  display: block !important;
  transform: rotate(0deg) !important;
}
[data-layout=vertical][data-sidebar=dark][data-sidebar-size=sm] .navbar-menu .navbar-nav .nav-sm .menu-dropdown {
  left: 100% !important;
  top: 0;
  border-radius: 3px !important;
}
[data-layout=vertical][data-sidebar=dark] .logo-dark {
  display: none;
}
[data-layout=vertical][data-sidebar=dark] .logo-light {
  display: inline-block;
}
[data-layout=vertical]:is([data-sidebar=gradient], [data-sidebar=gradient-2], [data-sidebar=gradient-3], [data-sidebar=gradient-4]) .logo-dark {
  display: none;
}
[data-layout=vertical]:is([data-sidebar=gradient], [data-sidebar=gradient-2], [data-sidebar=gradient-3], [data-sidebar=gradient-4]) .logo-light {
  display: inline-block;
}
[data-layout=vertical][data-sidebar=light] .logo-dark {
  display: inline-block;
}
[data-layout=vertical][data-sidebar=light] .logo-light {
  display: none;
}
[data-layout=vertical][data-layout-style=detached] #layout-wrapper,
[data-layout=vertical][data-layout-style=detached] .main-content {
  min-height: 100vh;
}
@media (min-width: 1024.1px) {
  [data-layout=vertical][data-layout-style=detached] .main-content {
    position: relative;
  }
  [data-layout=vertical][data-layout-style=detached] #layout-wrapper {
    max-width: 95%;
    margin: 0 auto;
    padding-left: 1.5rem;
  }
  [data-layout=vertical][data-layout-style=detached] .navbar-header {
    padding-left: 1.5rem;
  }
  [data-layout=vertical][data-layout-style=detached] .navbar-menu {
    top: calc(70px + calc(1.5rem * 2.5));
    bottom: 1.5rem;
    padding: 0;
    border-right: var(--tb-vertical-menu-bg);
    border-radius: 5px;
    padding: 10px 0;
    z-index: 1;
  }
  [data-layout=vertical][data-layout-style=detached] .navbar-menu .navbar-brand-box {
    display: none;
  }
}
[data-layout=vertical][data-layout-style=detached] .footer {
  border-top: 1px dashed var(--tb-border-color);
}
[data-layout=vertical][data-layout-style=detached] .auth-page-wrapper .footer {
  border-top: none;
}
@media (min-width: 768px) {
  [data-layout=vertical][data-layout-style=detached][data-sidebar-size=sm] #layout-wrapper,
  [data-layout=vertical][data-layout-style=detached][data-sidebar-size=sm] .main-content {
    min-height: 1400px;
  }
}

.menu-title {
  letter-spacing: 0.05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--tb-vertical-menu-title-color);
  font-weight: 600;
}
.menu-title span {
  padding: 12px 20px;
  display: inline-block;
}
.menu-title i {
  display: none;
}

.vertical-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(20, 24, 33, 0.35);
  z-index: 1003;
  display: none;
}

.vertical-sidebar-enable .vertical-overlay {
  display: block;
}
.vertical-sidebar-enable .app-menu {
  margin-left: 0 !important;
  z-index: 1004;
}

[dir=rtl] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  transform: rotate(180deg);
}
[dir=rtl] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  transform: rotate(270deg);
}

.bg-vertical-gradient {
  background: linear-gradient(to left, #285599, #1b3966);
}

.bg-vertical-gradient-2 {
  background: linear-gradient(to left, #2c6a74, #1e464d);
}

.bg-vertical-gradient-3 {
  background: linear-gradient(to left, #503a95, #352764);
}

.bg-vertical-gradient-4 {
  background: linear-gradient(to right, #1b7a45, #12512e);
}

.sidebar-background {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
  opacity: 0.07;
}

[data-sidebar-image=img-1] .sidebar-background {
  background-image: url("../images/sidebar/img-1.jpg");
}

[data-sidebar-image=img-2] .sidebar-background {
  background-image: url("../images/sidebar/img-2.jpg");
}

[data-sidebar-image=img-3] .sidebar-background {
  background-image: url("../images/sidebar/img-3.jpg");
}

[data-sidebar-image=img-4] .sidebar-background {
  background-image: url("../images/sidebar/img-4.jpg");
}

[data-topbar=dark] .btn-ghost-dark {
  color: #eff2f7;
}
[data-topbar=dark] .btn-ghost-dark:hover, [data-topbar=dark] .btn-ghost-dark:focus, [data-topbar=dark] .btn-ghost-dark:active {
  color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.1);
}

.back-btn {
  position: fixed;
  left: 12px;
  bottom: 24px;
}

[data-layout=horizontal] .main-content {
  margin-left: 0;
}
@media (min-width: 1024.1px) {
  [data-layout=horizontal] .layout-width,
  [data-layout=horizontal] .container-fluid {
    max-width: 90%;
    margin: 0 auto;
  }
  [data-layout=horizontal] .topnav-hamburger {
    visibility: hidden;
  }
}
[data-layout=horizontal] .horizontal-logo {
  padding-left: calc(1.5rem * 0.5);
  width: auto;
}
@media (max-width: 1024.98px) {
  [data-layout=horizontal] .horizontal-logo {
    padding-left: 1.5rem;
  }
}
[data-layout=horizontal] .navbar-menu {
  background: var(--tb-topnav-bg);
  border-right-color: transparent;
  padding: 0 calc(1.5rem * 0.5);
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  position: fixed;
  top: calc(30px + 70px);
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  bottom: auto;
}
@media (max-width: 575.98px) {
  [data-layout=horizontal] .navbar-menu .container-fluid {
    padding: 0;
  }
}
[data-layout=horizontal] .navbar-menu .navbar-nav {
  flex-direction: row;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-sm {
  padding-left: 0;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-sm .nav-link:before {
  opacity: 0 !important;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-sm .nav-link:hover, [data-layout=horizontal] .navbar-menu .navbar-nav .nav-sm .nav-link.active {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link {
  color: var(--tb-topnav-item-color);
  padding: 0.75rem 1.2rem;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link i {
  line-height: 1;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link.active {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link.active:after {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .navbar-menu .navbar-nav > .nav-item > .nav-link[data-bs-toggle=collapse]:after {
  right: 0px;
  transform: rotate(90deg) !important;
}
[data-layout=horizontal] .navbar-menu .navbar-nav > li:nth-of-type(2) > .nav-link.menu-link {
  padding-left: 0;
}
[data-layout=horizontal] .navbar-menu .navbar-brand-box {
  display: none;
}
[data-layout=horizontal] .navbar-nav .nav-item {
  position: relative;
}
[data-layout=horizontal] .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
  right: 10px;
  transform: rotate(0deg) !important;
}
[data-layout=horizontal] .navbar-nav .nav-item > .nav-link > .badge {
  display: none;
}
[data-layout=horizontal] .navbar-nav .nav-item:hover > .nav-link {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .navbar-nav .nav-item:hover > .nav-link:after {
  color: var(--tb-topnav-item-color-active);
}
@media (min-width: 1024.1px) {
  [data-layout=horizontal] .navbar-nav .nav-item:hover > .menu-dropdown {
    display: block;
    height: auto !important;
  }
}
[data-layout=horizontal] .navbar-nav .nav-item.active {
  color: var(--tb-topnav-item-color-active);
}
[data-layout=horizontal] .menu-dropdown {
  position: absolute;
  min-width: 12rem;
  padding: 0.5rem 0;
  box-shadow: 0 0px 5px rgba(15, 34, 58, 0.15);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  z-index: 1000;
  background-color: var(--tb-secondary-bg);
  background-clip: padding-box;
  border: var(--tb-border-width) solid var(--tb-border-color-translucent);
  border-radius: var(--tb-border-radius-lg);
  display: none;
}
[data-layout=horizontal] .menu-dropdown .menu-dropdown {
  top: 0;
  left: 100%;
}
[data-layout=horizontal] .mega-dropdown-menu {
  width: 40rem;
}
[data-layout=horizontal] .menu-title {
  display: none;
}
[data-layout=horizontal] .dropdown-custom-right {
  left: -100% !important;
  right: 100%;
}
@media (max-width: 1024px) {
  [data-layout=horizontal] .navbar-menu {
    display: none;
  }
}

@media (max-width: 1024px) {
  [data-layout=horizontal] .menu .navbar-menu {
    display: block;
    max-height: 360px;
    overflow-y: auto;
    padding-left: 0;
  }
  [data-layout=horizontal] .menu .navbar-menu .navbar-nav {
    flex-direction: column;
  }
  [data-layout=horizontal] .menu .navbar-menu .navbar-nav > li:nth-of-type(2) > .nav-link.menu-link {
    padding-left: 1.5rem;
  }
  [data-layout=horizontal] .menu .navbar-menu .navbar-nav .nav-sm .nav-link:before {
    opacity: 1 !important;
  }
  [data-layout=horizontal] .menu .menu-dropdown {
    position: relative;
    min-width: 100%;
    box-shadow: none;
    padding-left: 28px;
    left: 0;
    animation: none;
    padding-top: 0;
  }
  [data-layout=horizontal] .menu .menu-dropdown.show {
    display: block;
  }
  [data-layout=horizontal] .menu .dropdown-custom-right {
    left: 0 !important;
  }
  [data-layout=horizontal] .menu .nav-item .nav-link[data-bs-toggle=collapse]:after {
    right: 0px;
  }
  [data-layout=horizontal] .menu .mega-dropdown-menu {
    width: 100%;
  }
}
[data-layout=horizontal][data-topbar=dark] .navbar-header .horizontal-logo .logo-dark {
  display: none;
}
[data-layout=horizontal][data-topbar=dark] .navbar-header .horizontal-logo .logo-light {
  display: block;
}

[data-bs-theme=dark][data-topbar=light] .navbar-header .horizontal-logo .logo-dark {
  display: block;
}
[data-bs-theme=dark][data-topbar=light] .navbar-header .horizontal-logo .logo-light {
  display: none;
}

[dir=rtl][data-layout=horizontal] .navbar-menu .navbar-nav > .nav-item > .nav-link[data-bs-toggle=collapse]:after {
  transform: rotate(-90deg) !important;
}
[dir=rtl][data-layout=horizontal] .navbar-nav .nav-item .nav-link[data-bs-toggle=collapse]:after {
  transform: rotate(-180deg) !important;
}

[data-layout=twocolumn] .app-menu {
  padding-bottom: 0;
  width: 220px;
  left: 70px;
}
[data-layout=twocolumn] .app-menu .menu-link {
  letter-spacing: 0.05em;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--tb-vertical-menu-title-color) !important;
  font-weight: 600;
}
[data-layout=twocolumn] .app-menu .menu-link:after {
  display: none !important;
}
[data-layout=twocolumn] .app-menu .menu-link i {
  display: none;
}
[data-layout=twocolumn] .app-menu .navbar-nav {
  height: calc(100vh - 70px);
}
@media (max-width: 767.98px) {
  [data-layout=twocolumn] .app-menu .navbar-nav {
    padding-top: 16px;
  }
}
[data-layout=twocolumn] .app-menu .navbar-nav > li:not(.twocolumn-item-show) {
  display: none;
}
[data-layout=twocolumn] .app-menu .navbar-nav .twocolumn-item-show > div {
  display: block !important;
  height: auto !important;
}
[data-layout=twocolumn] .app-menu .navbar-nav > .nav-item > .menu-dropdown {
  display: block !important;
  height: auto !important;
}
[data-layout=twocolumn] .app-menu .navbar-nav .nav-item .menu-dropdown .row {
  margin: 0;
}
[data-layout=twocolumn] .app-menu .navbar-nav .nav-item .menu-dropdown .row .col-lg-4 {
  width: 100%;
  padding: 0;
}
[data-layout=twocolumn] .app-menu .navbar-nav .nav-sm .nav-link {
  color: var(--tb-vertical-menu-item-color);
}
[data-layout=twocolumn] .app-menu .navbar-nav .nav-sm .nav-link.active {
  color: var(--tb-vertical-menu-item-active-color);
}
[data-layout=twocolumn] .app-menu .container-fluid {
  padding: 0;
}
[data-layout=twocolumn] .main-content {
  margin-left: calc(220px + 70px);
}
@media (max-width: 767.98px) {
  [data-layout=twocolumn] .main-content {
    margin-left: 70px;
  }
}
[data-layout=twocolumn] .twocolumn-iconview {
  width: 70px;
  background-color: var(--tb-twocolumn-menu-iconview-bg);
  height: 100%;
  left: -70px;
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  top: 0;
  position: absolute;
  padding: 0;
  text-align: center;
}
[data-layout=twocolumn] .twocolumn-iconview li {
  position: relative;
}
[data-layout=twocolumn] .twocolumn-iconview li .nav-icon::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
[data-layout=twocolumn] .twocolumn-iconview .nav-icon {
  width: 42px;
  height: 42px;
  line-height: 42px;
  color: var(--tb-vertical-menu-item-color);
  z-index: 1;
  font-size: 22px;
  text-align: center;
  border-radius: 3px;
  margin: 5px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
[data-layout=twocolumn] .twocolumn-iconview .nav-icon .icon-dual {
  width: 18px;
  color: var(--tb-vertical-menu-item-color);
}
[data-layout=twocolumn] .twocolumn-iconview .nav-icon.active {
  background-color: var(--tb-vertical-menu-item-active-bg);
  color: var(--tb-vertical-menu-item-active-color);
}
[data-layout=twocolumn] .menu-title {
  display: none;
}
@media (max-width: 991.98px) {
  [data-layout=twocolumn] .logo span.logo-lg {
    display: block;
  }
  [data-layout=twocolumn] .logo span.logo-sm {
    display: none;
  }
}
[data-layout=twocolumn][data-sidebar=dark] .app-menu .navbar-brand-box .logo-light, [data-layout=twocolumn][data-sidebar=gradient] .app-menu .navbar-brand-box .logo-light, [data-layout=twocolumn][data-sidebar=gradient-2] .app-menu .navbar-brand-box .logo-light, [data-layout=twocolumn][data-sidebar=gradient-3] .app-menu .navbar-brand-box .logo-light, [data-layout=twocolumn][data-sidebar=gradient-4] .app-menu .navbar-brand-box .logo-light {
  display: block;
}
[data-layout=twocolumn][data-sidebar=dark] .app-menu .navbar-brand-box .logo-dark, [data-layout=twocolumn][data-sidebar=gradient] .app-menu .navbar-brand-box .logo-dark, [data-layout=twocolumn][data-sidebar=gradient-2] .app-menu .navbar-brand-box .logo-dark, [data-layout=twocolumn][data-sidebar=gradient-3] .app-menu .navbar-brand-box .logo-dark, [data-layout=twocolumn][data-sidebar=gradient-4] .app-menu .navbar-brand-box .logo-dark {
  display: none;
}
[data-layout=twocolumn] .twocolumn-panel .app-menu {
  width: 0;
  border-right: 0;
}
@media (max-width: 575.98px) {
  [data-layout=twocolumn] .twocolumn-panel .app-menu {
    display: none;
  }
}
[data-layout=twocolumn] .twocolumn-panel .navbar-brand-box,
[data-layout=twocolumn] .twocolumn-panel .navbar-nav {
  display: none;
}
[data-layout=twocolumn] .twocolumn-panel .main-content {
  margin-left: 70px;
}
[data-layout=twocolumn] .twocolumn-panel #page-topbar,
[data-layout=twocolumn] .twocolumn-panel .footer {
  left: 70px;
}
@media (max-width: 575.98px) {
  [data-layout=twocolumn] .twocolumn-panel .main-content {
    margin-left: 0;
  }
  [data-layout=twocolumn] .twocolumn-panel #page-topbar,
  [data-layout=twocolumn] .twocolumn-panel .footer {
    left: 0;
  }
}
@media (max-width: 767.98px) {
  [data-layout=twocolumn] #page-topbar,
  [data-layout=twocolumn] .footer {
    left: 70px;
  }
}
@media (max-width: 575.98px) {
  [data-layout=twocolumn] .main-content {
    margin-left: 0;
  }
  [data-layout=twocolumn] #page-topbar,
  [data-layout=twocolumn] .footer {
    left: 0;
  }
}

[data-layout-width=boxed] body {
  background-color: var(--tb-boxed-body-bg);
}
[data-layout-width=boxed] #layout-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px;
  background-color: var(--tb-body-bg);
}
[data-layout-width=boxed][data-layout=vertical] #layout-wrapper {
  min-height: 100vh;
}
[data-layout-width=boxed] #page-topbar,
[data-layout-width=boxed] .footer {
  max-width: 1300px;
  margin: 0 auto;
  left: 0 !important;
}
@media (min-width: 768px) {
  [data-layout-width=boxed][data-sidebar-size=sm-hover][data-layout=vertical] #layout-wrapper, [data-layout-width=boxed][data-sidebar-size=sm][data-layout=vertical] #layout-wrapper {
    min-height: 1400px;
  }
}
@media (max-width: 767.98px) {
  [data-layout-width=boxed][data-sidebar-size=sm-hover][data-layout=vertical] .main-content, [data-layout-width=boxed][data-sidebar-size=sm][data-layout=vertical] .main-content {
    margin-left: 0;
  }
}
[data-layout-width=boxed][data-sidebar-size=sm-hover] #page-topbar,
[data-layout-width=boxed][data-sidebar-size=sm-hover] .footer, [data-layout-width=boxed][data-sidebar-size=sm] #page-topbar,
[data-layout-width=boxed][data-sidebar-size=sm] .footer {
  left: 0 !important;
  max-width: calc(1300px - 70px);
}
@media (min-width: 768px) {
  [data-layout-width=boxed][data-sidebar-size=sm-hover] #page-topbar,
  [data-layout-width=boxed][data-sidebar-size=sm-hover] .footer, [data-layout-width=boxed][data-sidebar-size=sm] #page-topbar,
  [data-layout-width=boxed][data-sidebar-size=sm] .footer {
    left: 70px !important;
  }
}
[data-layout-width=boxed][data-sidebar-size=sm-hover-active] #page-topbar,
[data-layout-width=boxed][data-sidebar-size=sm-hover-active] .footer, [data-layout-width=boxed][data-sidebar-size=lg] #page-topbar,
[data-layout-width=boxed][data-sidebar-size=lg] .footer {
  max-width: calc(1300px - 250px);
}
@media (min-width: 768px) {
  [data-layout-width=boxed][data-sidebar-size=sm-hover-active] #page-topbar,
  [data-layout-width=boxed][data-sidebar-size=sm-hover-active] .footer, [data-layout-width=boxed][data-sidebar-size=lg] #page-topbar,
  [data-layout-width=boxed][data-sidebar-size=lg] .footer {
    left: 250px !important;
  }
}
[data-layout-width=boxed][data-sidebar-size=md] #page-topbar,
[data-layout-width=boxed][data-sidebar-size=md] .footer {
  max-width: calc(1300px - 180px);
}
@media (min-width: 768px) {
  [data-layout-width=boxed][data-sidebar-size=md] #page-topbar,
  [data-layout-width=boxed][data-sidebar-size=md] .footer {
    left: 180px !important;
  }
}
[data-layout-width=boxed][data-layout=vertical][data-layout-style=detached] body {
  background-color: var(--tb-body-bg);
}
@media (min-width: 1024.1px) {
  [data-layout-width=boxed][data-layout=vertical][data-layout-style=detached] #layout-wrapper {
    max-width: 1300px;
    box-shadow: none;
  }
  [data-layout-width=boxed][data-layout=vertical][data-layout-style=detached] .layout-width {
    max-width: 1300px;
  }
}
[data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=sm-hover] #page-topbar,
[data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=sm-hover] .footer, [data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=sm] #page-topbar,
[data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=sm] .footer, [data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=md] #page-topbar,
[data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=md] .footer, [data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=lg] #page-topbar,
[data-layout-width=boxed][data-layout=vertical][data-layout-style=detached][data-sidebar-size=lg] .footer {
  max-width: 100%;
  left: 0 !important;
}

[data-layout=horizontal][data-layout-width=boxed] #page-topbar,
[data-layout=horizontal][data-layout-width=boxed] #layout-wrapper,
[data-layout=horizontal][data-layout-width=boxed] .footer {
  max-width: 100%;
}
[data-layout=horizontal][data-layout-width=boxed] .container-fluid,
[data-layout=horizontal][data-layout-width=boxed] .navbar-header {
  max-width: 1300px;
}
[data-layout=horizontal][data-layout-width=boxed] .navbar-header {
  padding: 0 calc(1.5rem / 2) 0 0;
}
[data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=sm-hover] #page-topbar,
[data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=sm-hover] .footer, [data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=sm] [data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=sm-hover-active] #page-topbar,
[data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=sm] [data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=sm-hover-active] .footer, [data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=lg] #page-topbar,
[data-layout=horizontal][data-layout-width=boxed][data-sidebar-size=lg] .footer {
  left: 0 !important;
}

@media (min-width: 992px) {
  [data-layout-position=scrollable] #page-topbar,
  [data-layout-position=scrollable] .navbar-menu {
    position: absolute;
  }
}
@media (min-width: 992px) {
  [data-layout-position=scrollable][data-layout=horizontal] #page-topbar,
  [data-layout-position=scrollable][data-layout=horizontal] .topnav {
    position: absolute;
  }
}

.ecommerce-navbar {
  height: 70px;
  transition: all 0.5s ease;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 34px;
  z-index: 1002;
}
.ecommerce-navbar .navbar-nav .nav-item .nav-link {
  color: #141821;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 14px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ecommerce-navbar .navbar-nav .nav-item .nav-link:hover, .ecommerce-navbar .navbar-nav .nav-item .nav-link.active {
  color: #438eff;
}
.ecommerce-navbar .dropdown-menu-list .nav-item .nav-link {
  height: auto;
  display: inline-block;
  font-size: 14px;
  padding: 0.35rem 0px;
  background-color: transparent !important;
  transition: all 0.5s ease;
}
.ecommerce-navbar .dropdown-menu-list .nav-item .nav-link::before {
  content: "";
  background-color: #438eff;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 2px;
  transition: all 0.5s ease;
  opacity: 0;
}
.ecommerce-navbar .dropdown-menu-list .nav-item .nav-link:hover {
  padding-left: 13px;
  color: #438eff;
}
.ecommerce-navbar .dropdown-menu-list .nav-item .nav-link:hover::before {
  opacity: 1;
}
.ecommerce-navbar .dropdown-menu-list.submenu .nav-item .nav-link::before {
  left: 1.45rem;
}
.ecommerce-navbar .dropdown-menu-list.submenu .nav-item .nav-link:hover {
  padding-left: 37px !important;
}
.ecommerce-navbar .navbar-collapse {
  height: 100% !important;
}
.ecommerce-navbar.is-sticky {
  background-color: #fff !important;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  top: 0;
}

.dropdown-custom-right {
  left: auto !important;
  right: 100%;
}

.dropdown-hover:hover > .dropdown-menu {
  display: block;
}

@media (min-width: 992px) {
  .dropdown-hover:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-hover:hover .dropdown-toggle::after {
    transition: all 0.5s ease;
    transform: rotate(180deg);
  }
  .dropdown-menu.dropdown-menu-center {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .navbar-nav .dropdown .dropdown-menu.submenu {
    width: 200px;
  }
  .navbar-nav .dropdown .dropdown-menu.submenu.dropdown-custom-right {
    left: -100% !important;
    right: 100%;
  }
  .navbar-nav .dropdown .dropdown-menu.submenu .nav-item .nav-link {
    width: 100%;
    padding: 0.35rem 1.5rem;
  }
  .navbar-nav .dropdown .dropdown-menu.submenu .nav-item .nav-link.dropdown-toggle::after {
    float: right;
    transform: rotate(-90deg);
    margin-top: 3px;
  }
  .navbar-nav .dropdown .dropdown-menu.submenu .nav-item.dropdown-hover .dropdown-menu {
    left: 100%;
    top: 0;
  }
}
.bg-overlay.navbar-overlay {
  position: fixed;
  z-index: 9;
}

.navbar-collapse:not(.show) + .navbar-overlay {
  display: none;
}

@media (max-width: 991.98px) {
  .ecommerce-navbar .navbar-collapse {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh !important;
    background-color: var(--tb-vertical-menu-bg);
    width: 250px;
    z-index: 999;
    overflow-y: auto;
    transform: translateX(0);
    transition: all 0.4s;
  }
  .ecommerce-navbar .navbar-collapse:not(.show) {
    transform: translateX(-250px);
  }
  .ecommerce-navbar .navbar-nav .nav-item .nav-link {
    justify-content: flex-start;
    height: 40px;
    width: 100%;
  }
  .ecommerce-navbar .navbar-nav .nav-item .nav-link.dropdown-toggle:after {
    margin-left: auto;
  }
  .ecommerce-navbar .navbar-nav .dropdown-menu {
    box-shadow: none;
    position: relative;
    left: auto !important;
    right: auto !important;
    width: 100%;
  }
  .ecommerce-navbar .navbar-nav .dropdown-menu.submenu {
    padding-left: 12px;
  }
  .ecommerce-navbar .dropdown-menu-list .nav-item .nav-link {
    height: auto;
    padding: 0.35rem 1.2rem;
    display: flex;
  }
  .ecommerce-navbar .dropdown-menu-list .menu-title {
    padding: 0 1.2rem;
  }
}
.dropdown-mega-full {
  position: static !important;
}
.dropdown-mega-full .dropdown-menu {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}

.avatar-xxs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
}
.avatar-group .avatar-group-item {
  margin-left: -12px;
  border: 2px solid var(--tb-secondary-bg);
  border-radius: 50%;
  transition: all 0.2s;
}
.avatar-group .avatar-group-item:hover {
  position: relative;
  transform: translateY(-2px);
  z-index: 1;
}

.accordion .accordion-button {
  font-weight: 500;
}
.accordion .accordion-body {
  color: var(--tb-secondary-color);
}
.accordion.accordion-icon-none .accordion-button::after {
  content: "";
  background-image: none !important;
}
.accordion.accordion-icon-none .accordion-button:not(.collapsed)::after {
  content: "";
}

.custom-accordionwithicon .accordion-button::after {
  background-image: none !important;
  font-family: "Material Design Icons";
  content: "\f0142";
  font-size: 1.1rem;
  vertical-align: middle;
  line-height: 0.8;
}
.custom-accordionwithicon .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "\f0140";
  margin-right: -3px;
}

.custom-accordionwithicon-plus .accordion-button::after {
  background-image: none !important;
  font-family: "Material Design Icons";
  content: "\f0415";
  font-size: 1.1rem;
  vertical-align: middle;
  line-height: 0.8;
}
.custom-accordionwithicon-plus .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "\f0374";
  margin-right: -3px;
}

.lefticon-accordion .accordion-button {
  padding-left: 2.75rem;
}
.lefticon-accordion .accordion-button::after {
  position: absolute;
  left: 1.25rem;
  top: 14px;
}
.lefticon-accordion .accordion-button:not(.collapsed)::after {
  top: 20px;
}

.accordion-border-box .accordion-item {
  border-top: var(--tb-border-width) solid var(--tb-border-color);
  border-radius: var(--tb-border-radius);
}
.accordion-border-box .accordion-item:not(:first-of-type) {
  margin-top: 8px;
}
.accordion-border-box .accordion-item .accordion-button {
  border-radius: var(--tb-border-radius);
}
.accordion-border-box .accordion-item .accordion-button:not(.collapsed) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-accordion-border .accordion-item {
  border-left: 3px solid var(--tb-border-color);
}

.accordion-primary .accordion-item {
  border-color: rgba(67, 142, 255, 0.6);
}
.accordion-primary .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-primary .accordion-item .accordion-button:not(.collapsed) {
  color: #438eff;
  background-color: rgba(67, 142, 255, 0.1) !important;
}
.accordion-primary .accordion-item .accordion-button::after {
  color: #438eff;
}

.accordion-fill-primary .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-primary .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #438eff !important;
}

.accordion-secondary .accordion-item {
  border-color: rgba(133, 97, 249, 0.6);
}
.accordion-secondary .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-secondary .accordion-item .accordion-button:not(.collapsed) {
  color: #8561f9;
  background-color: rgba(133, 97, 249, 0.1) !important;
}
.accordion-secondary .accordion-item .accordion-button::after {
  color: #8561f9;
}

.accordion-fill-secondary .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-secondary .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #8561f9 !important;
}

.accordion-success .accordion-item {
  border-color: rgba(45, 203, 115, 0.6);
}
.accordion-success .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-success .accordion-item .accordion-button:not(.collapsed) {
  color: #2dcb73;
  background-color: rgba(45, 203, 115, 0.1) !important;
}
.accordion-success .accordion-item .accordion-button::after {
  color: #2dcb73;
}

.accordion-fill-success .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-success .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #2dcb73 !important;
}

.accordion-info .accordion-item {
  border-color: rgba(74, 176, 193, 0.6);
}
.accordion-info .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-info .accordion-item .accordion-button:not(.collapsed) {
  color: #4ab0c1;
  background-color: rgba(74, 176, 193, 0.1) !important;
}
.accordion-info .accordion-item .accordion-button::after {
  color: #4ab0c1;
}

.accordion-fill-info .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-info .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #4ab0c1 !important;
}

.accordion-warning .accordion-item {
  border-color: rgba(246, 183, 73, 0.6);
}
.accordion-warning .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-warning .accordion-item .accordion-button:not(.collapsed) {
  color: #f6b749;
  background-color: rgba(246, 183, 73, 0.1) !important;
}
.accordion-warning .accordion-item .accordion-button::after {
  color: #f6b749;
}

.accordion-fill-warning .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-warning .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #f6b749 !important;
}

.accordion-danger .accordion-item {
  border-color: rgba(255, 108, 108, 0.6);
}
.accordion-danger .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-danger .accordion-item .accordion-button:not(.collapsed) {
  color: #ff6c6c;
  background-color: rgba(255, 108, 108, 0.1) !important;
}
.accordion-danger .accordion-item .accordion-button::after {
  color: #ff6c6c;
}

.accordion-fill-danger .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-danger .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #ff6c6c !important;
}

.accordion-light .accordion-item {
  border-color: rgba(239, 242, 247, 0.6);
}
.accordion-light .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-light .accordion-item .accordion-button:not(.collapsed) {
  color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.1) !important;
}
.accordion-light .accordion-item .accordion-button::after {
  color: #eff2f7;
}

.accordion-fill-light .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-light .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #eff2f7 !important;
}

.accordion-dark .accordion-item {
  border-color: rgba(20, 24, 33, 0.6);
}
.accordion-dark .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-dark .accordion-item .accordion-button:not(.collapsed) {
  color: #141821;
  background-color: rgba(20, 24, 33, 0.1) !important;
}
.accordion-dark .accordion-item .accordion-button::after {
  color: #141821;
}

.accordion-fill-dark .accordion-item .accordion-button {
  box-shadow: none;
}
.accordion-fill-dark .accordion-item .accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #141821 !important;
}

[data-layout-mode=dark] .accordion-button:not(.collapsed) {
  color: var(--tb-accordion-button-active-color);
}
[data-layout-mode=dark] .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-layout-mode=dark] .accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23adb5bd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

[dir=rtl] .custom-accordionwithicon .accordion-button::after {
  transform: rotate(180deg);
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.border-double {
  border-style: double !important;
}

.border-top-double {
  border-top-style: double !important;
}

.border-bottom-double {
  border-bottom-style: double !important;
}

.border-end-double {
  border-right-style: double !important;
}

.border-start-double {
  border-left-style: double !important;
}

.list-group-flush.border-double {
  border: none !important;
}
.list-group-flush.border-double .list-group-item {
  border-style: double !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-end-dashed {
  border-right-style: dashed !important;
}

.border-start-dashed {
  border-left-style: dashed !important;
}

.list-group-flush.border-dashed {
  border: none !important;
}
.list-group-flush.border-dashed .list-group-item {
  border-style: dashed !important;
}

.border-groove {
  border-style: groove !important;
}

.border-top-groove {
  border-top-style: groove !important;
}

.border-bottom-groove {
  border-bottom-style: groove !important;
}

.border-end-groove {
  border-right-style: groove !important;
}

.border-start-groove {
  border-left-style: groove !important;
}

.list-group-flush.border-groove {
  border: none !important;
}
.list-group-flush.border-groove .list-group-item {
  border-style: groove !important;
}

.border-outset {
  border-style: outset !important;
}

.border-top-outset {
  border-top-style: outset !important;
}

.border-bottom-outset {
  border-bottom-style: outset !important;
}

.border-end-outset {
  border-right-style: outset !important;
}

.border-start-outset {
  border-left-style: outset !important;
}

.list-group-flush.border-outset {
  border: none !important;
}
.list-group-flush.border-outset .list-group-item {
  border-style: outset !important;
}

.border-ridge {
  border-style: ridge !important;
}

.border-top-ridge {
  border-top-style: ridge !important;
}

.border-bottom-ridge {
  border-bottom-style: ridge !important;
}

.border-end-ridge {
  border-right-style: ridge !important;
}

.border-start-ridge {
  border-left-style: ridge !important;
}

.list-group-flush.border-ridge {
  border: none !important;
}
.list-group-flush.border-ridge .list-group-item {
  border-style: ridge !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-top-dotted {
  border-top-style: dotted !important;
}

.border-bottom-dotted {
  border-bottom-style: dotted !important;
}

.border-end-dotted {
  border-right-style: dotted !important;
}

.border-start-dotted {
  border-left-style: dotted !important;
}

.list-group-flush.border-dotted {
  border: none !important;
}
.list-group-flush.border-dotted .list-group-item {
  border-style: dotted !important;
}

.border-inset {
  border-style: inset !important;
}

.border-top-inset {
  border-top-style: inset !important;
}

.border-bottom-inset {
  border-bottom-style: inset !important;
}

.border-end-inset {
  border-right-style: inset !important;
}

.border-start-inset {
  border-left-style: inset !important;
}

.list-group-flush.border-inset {
  border: none !important;
}
.list-group-flush.border-inset .list-group-item {
  border-style: inset !important;
}

ol {
  list-style: none;
}

.ff-base {
  font-family: var(--tb-font-sans-serif);
}

.ff-secondary {
  font-family: "Gantari", sans-serif;
}

.fw-medium {
  font-weight: 500;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 4px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
  text-align: center;
  transition: all 0.4s;
}
.social-list-item:hover {
  color: #878a99;
  background-color: #eff2f7;
}

.bg-pattern {
  background: url("../images/modal-bg.png") var(--tb-secondary-bg);
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.icon-xs {
  height: 16px;
  width: 16px;
}

.icon-sm {
  height: 18px;
  width: 18px;
}

.icon-md {
  height: 22px;
  width: 22px;
}

.icon-lg {
  height: 24px;
  width: 24px;
}

.icon-xl {
  height: 28px;
  width: 28px;
}

.icon-xxl {
  height: 32px;
  width: 32px;
}

.icon-dual {
  color: #adb5bd;
  fill: rgba(173, 181, 189, 0.16);
}

.icon-dual-primary {
  color: #438eff;
  fill: rgba(67, 142, 255, 0.16);
}

.icon-dual-secondary {
  color: #8561f9;
  fill: rgba(133, 97, 249, 0.16);
}

.icon-dual-success {
  color: #2dcb73;
  fill: rgba(45, 203, 115, 0.16);
}

.icon-dual-info {
  color: #4ab0c1;
  fill: rgba(74, 176, 193, 0.16);
}

.icon-dual-warning {
  color: #f6b749;
  fill: rgba(246, 183, 73, 0.16);
}

.icon-dual-danger {
  color: #ff6c6c;
  fill: rgba(255, 108, 108, 0.16);
}

.icon-dual-light {
  color: #eff2f7;
  fill: rgba(239, 242, 247, 0.16);
}

.icon-dual-dark {
  color: #141821;
  fill: rgba(20, 24, 33, 0.16);
}

.search-box {
  position: relative;
}
.search-box .form-control {
  padding-left: 40px;
}
.search-box .search-icon {
  font-size: 14px;
  position: absolute;
  left: 13px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--tb-secondary-color);
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: #000;
}

.customizer-setting {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1000;
}

code {
  user-select: all;
}

.layout-rightside {
  width: 350px;
  margin-right: calc(0px - 1.5rem * 1.2);
  margin-top: calc(1px - 1.5rem * 1.19);
  height: calc(100vh - 60px);
  position: sticky;
  top: calc(30px + 70px);
  overflow: hidden;
}

@media (max-width: 1699.98px) {
  .layout-rightside-col {
    display: none;
    position: fixed !important;
    height: 100vh;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1004;
  }
  .layout-rightside-col .overlay {
    position: fixed;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(20, 24, 33, 0.2);
  }
  .layout-rightside-col .layout-rightside {
    margin-top: 0px;
    height: 100%;
    margin-right: calc(0px - 1.5rem * 0.5);
  }
  .layout-rightside-col .card-body {
    overflow-y: auto;
    padding-bottom: 1rem !important;
  }
}
.layout-rightside-col .widget-userlist {
  height: calc(100vh - 100px);
}
@media (max-width: 1699.98px) {
  .layout-rightside-col .widget-userlist {
    height: 100vh;
  }
}
.layout-rightside-col .widget-user-chatlist {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: var(--tb-secondary-bg);
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.2s;
}
.layout-rightside-col .layout-rightside.show .widget-user-chatlist {
  transform: translateX(0);
  opacity: 1;
}
.layout-rightside-col .chat-conversation {
  height: calc(100vh - 275px);
}
@media (max-width: 1699.98px) {
  .layout-rightside-col .chat-conversation {
    height: calc(100vh - 190px);
  }
}
.layout-rightside-col .chat-conversation .conversation-list {
  max-width: 90%;
}

.btn-icon.layout-rightside-btn {
  display: none;
}
@media (max-width: 1699.98px) {
  .btn-icon.layout-rightside-btn {
    display: block;
    position: fixed;
    bottom: 40px;
    right: 80px;
    z-index: 1000;
  }
}

@media (min-width: 1700px) {
  [data-layout=horizontal] .layout-rightside,
  [data-layout-style=detached] .layout-rightside {
    margin-top: calc(28px - 1.5rem);
    margin-right: 0;
    height: calc(100% - (1.5rem / 5));
  }
}

.object-cover {
  object-fit: cover;
}

.text-truncate-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card-logo-light {
  display: var(--tb-card-logo-light);
}

.card-logo-dark {
  display: var(--tb-card-logo-dark);
}

[data-layout-mode=dark] .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

#back-to-top {
  position: fixed;
  bottom: 100px;
  right: 28px;
  transition: all 0.5s ease;
  display: none;
  z-index: 1000;
}
#back-to-top:hover {
  animation: fade-up 1.5s infinite linear;
}

@keyframes fade-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.section {
  position: relative;
  padding: 80px 0;
}

.svg-shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: 0px;
  left: 0;
}
.svg-shape > svg {
  width: 100%;
  height: auto;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tb-secondary-bg);
  z-index: 9999;
}

[data-preloader=disable] #preloader {
  opacity: 0;
  visibility: hidden;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

[type=tel]::placeholder,
[type=url]::placeholder,
[type=email]::placeholder,
[type=number]::placeholder {
  text-align: left;
}

.main-chart .chart-border-left {
  border-left: 1.4px solid #eff2f7;
  padding: 2px 20px;
}
.main-chart .chart-border-left:last-child {
  margin-right: 0px;
}

/* Activity */
.activity-feed {
  list-style: none;
}
.activity-feed .feed-item {
  position: relative;
  padding-bottom: 27px;
  padding-left: 16px;
  border-left: 2px solid #eff2f7;
}
.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #438eff;
  background-color: var(--tb-secondary-bg);
}
.activity-feed .feed-item:last-child {
  border-color: transparent;
}

.mini-stats-wid {
  position: relative;
}
.mini-stats-wid .mini-stat-icon {
  overflow: hidden;
  position: relative;
}
.mini-stats-wid .mini-stat-icon:before, .mini-stats-wid .mini-stat-icon:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 69px;
  background-color: rgba(45, 203, 115, 0.1);
  left: 3px;
  transform: rotate(32deg);
  top: -8px;
  transition: all 0.4s;
}
.mini-stats-wid .mini-stat-icon::after {
  left: 27px;
  width: 8px;
  transition: all 0.2s;
}
.mini-stats-wid:hover .mini-stat-icon::after {
  left: 60px;
}
.mini-stats-wid:hover .mini-stat-icon::before {
  left: 50px;
}

.button-items {
  margin-left: -8px;
  margin-bottom: -12px;
}
.button-items .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

[dir=rtl] .modal-open {
  padding-left: 0px !important;
}

.icon-demo-content {
  color: var(--tb-gray-500);
}
.icon-demo-content div {
  user-select: all;
}
.icon-demo-content i {
  font-size: 22px;
  margin-right: 10px;
  color: var(--tb-gray-600);
  transition: all 0.4s;
  vertical-align: middle;
}
.icon-demo-content svg {
  margin-right: 10px;
  transition: all 0.4s;
  height: 20px;
}
.icon-demo-content .col-lg-4 {
  margin-top: 24px;
}
.icon-demo-content .col-lg-4:hover i, .icon-demo-content .col-lg-4:hover svg {
  color: #438eff;
  transform: scale(1.5);
}

.grid-structure .grid-container {
  background-color: #f3f6f9;
  margin-top: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 10px 20px;
}

.img-switch .card-radio .form-check-input {
  display: none;
}
.img-switch .card-radio .form-check-input:checked + .form-check-label::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(67, 142, 255, 0.5);
}
.img-switch .card-radio .form-check-input:checked + .form-check-label::after {
  content: "\eb80";
  font-family: "remixicon";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: 18px;
  color: #fff;
}

.colorscheme-cardradio .dark .bg-dark {
  background-color: var(--tb-gray-900) !important;
}
.colorscheme-cardradio .dark .bg-dark-subtle {
  background-color: var(--tb-gray-700) !important;
}

[data-bs-target="#collapseBgGradient"].active {
  border-color: #438eff !important;
}
[data-bs-target="#collapseBgGradient"].active::before {
  content: "\eb80";
  font-family: remixicon;
  position: absolute;
  top: 2px;
  right: 6px;
  font-size: 16px;
  color: #438eff;
}

[data-bs-theme=dark] .colorscheme-cardradio .form-check-label {
  background-color: var(--tb-dark);
}
[data-bs-theme=dark] .colorscheme-cardradio .bg-light {
  background-color: rgba(var(--tb-light-rgb), 0.1) !important;
}
[data-bs-theme=dark] .colorscheme-cardradio .bg-soft-light {
  background-color: rgba(var(--tb-dark-rgb), 0.1) !important;
}
[data-bs-theme=dark] .colorscheme-cardradio .dark .bg-dark {
  background-color: var(--tb-secondary-bg) !important;
}
[data-bs-theme=dark] .colorscheme-cardradio .dark .bg-dark-subtle {
  background-color: var(--tb-gray-700) !important;
}

@media print {
  .vertical-menu,
  .right-bar,
  .page-title-box,
  .navbar-header,
  .app-menu,
  .footer,
  #back-to-top {
    display: none !important;
  }
  .card-body,
  .main-content,
  .right-bar,
  .page-content,
  body {
    padding: 0;
    margin: 0;
  }
  .card {
    border: 0;
    box-shadow: none !important;
  }
  .invoice-details .d-sm-flex {
    display: flex !important;
  }
  .address.col-sm-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
    max-width: 100% !important;
  }
}
.ribbon-box {
  position: relative;
}
.ribbon-box .ribbon {
  padding: 5px 12px;
  box-shadow: 2px 5px 10px rgba(20, 24, 33, 0.15);
  color: #fff;
  font-size: 0.9063rem;
  font-weight: 600;
  position: absolute;
  left: -1px;
  top: 10px;
  z-index: 1;
}
.ribbon-box .ribbon.round-shape {
  border-radius: 0 30px 30px 0;
}
.ribbon-box .ribbon.ribbon-shape {
  display: inline-block;
}
.ribbon-box .ribbon.ribbon-shape::before, .ribbon-box .ribbon.ribbon-shape::after {
  content: "";
  position: absolute;
  border-radius: 5px 0 0 5px;
}
.ribbon-box .ribbon.ribbon-shape::before {
  width: 7px;
  height: 124%;
  top: 0;
  left: -6.5px;
  padding: 0 0 7px;
}
.ribbon-box .ribbon.ribbon-shape::after {
  width: 5px;
  height: 5px;
  bottom: -5px;
  left: -4.5px;
}
.ribbon-box .ribbon.vertical-shape {
  position: absolute;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 70px;
  top: -2px;
  transform: rotate(-90deg) translateX(-27%);
  font-weight: 500;
}
.ribbon-box .ribbon.vertical-shape:after {
  content: "";
  border-top: 18px solid #438eff;
  border-bottom: 18px solid #438eff;
  border-right: 11px solid transparent;
  border-left: 11px solid transparent;
  position: absolute;
  left: -11px;
  top: 0;
}
.ribbon-box.ribbon-circle .ribbon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 20px;
  top: 20px;
}
.ribbon-box.ribbon-fill {
  overflow: hidden;
}
.ribbon-box.ribbon-fill .ribbon {
  transform: rotate(-45deg);
  width: 93px;
  height: 52px;
  left: -36px;
  top: -16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.ribbon-box.ribbon-fill.ribbon-sm .ribbon {
  padding: 2px 12px;
  width: 78px;
  height: 42px;
  font-size: 12px;
  box-shadow: none;
}
.ribbon-box.right .ribbon {
  position: absolute;
  left: auto;
  right: 0;
}
.ribbon-box.right .ribbon.round-shape {
  border-radius: 30px 0 0 30px;
}
.ribbon-box.right .ribbon.ribbon-shape {
  text-align: right;
}
.ribbon-box.right .ribbon.ribbon-shape::before {
  right: -6.5px;
  left: auto;
  border-radius: 0 5px 5px 0;
}
.ribbon-box.right .ribbon.ribbon-shape::after {
  right: -4.5px;
  left: auto;
  border-radius: 0 5px 5px 0;
}
.ribbon-box.right.ribbon-circle .ribbon {
  left: auto;
  right: 20px;
}
.ribbon-box.right .icon-ribbon {
  right: 24px;
  left: auto;
}
.ribbon-box.right.ribbon-fill .ribbon {
  transform: rotate(45deg);
  right: -38px;
  left: auto;
}
.ribbon-box.right.ribbon-box .ribbon-two {
  left: auto;
  right: -5px;
}
.ribbon-box.right.ribbon-box .ribbon-two span {
  left: auto;
  right: -21px;
  transform: rotate(45deg);
}
.ribbon-box .ribbon-content {
  clear: both;
}
.ribbon-box .ribbon-primary {
  background: #438eff;
}
.ribbon-box .ribbon-primary.vertical-shape:after {
  border-top: 18px solid #438eff;
  border-bottom: 18px solid #438eff;
}
.ribbon-box .ribbon-primary:before {
  border-color: #106fff transparent transparent;
}
.ribbon-box .ribbon-primary.ribbon-shape::before {
  background-color: #438eff;
}
.ribbon-box .ribbon-primary.ribbon-shape::after {
  background-color: #a9cbff;
}
.ribbon-box.right .ribbon-primary {
  background: #438eff;
}
.ribbon-box.right .ribbon-primary.ribbon-shape::before {
  border-right-color: #438eff;
  border-top-color: #438eff;
}
.ribbon-box.right .ribbon-primary.ribbon-shape::after {
  border-right-color: #438eff;
  border-bottom-color: #438eff;
}
.ribbon-box .ribbon-secondary {
  background: #8561f9;
}
.ribbon-box .ribbon-secondary.vertical-shape:after {
  border-top: 18px solid #8561f9;
  border-bottom: 18px solid #8561f9;
}
.ribbon-box .ribbon-secondary:before {
  border-color: #5f30f7 transparent transparent;
}
.ribbon-box .ribbon-secondary.ribbon-shape::before {
  background-color: #8561f9;
}
.ribbon-box .ribbon-secondary.ribbon-shape::after {
  background-color: #d1c3fd;
}
.ribbon-box.right .ribbon-secondary {
  background: #8561f9;
}
.ribbon-box.right .ribbon-secondary.ribbon-shape::before {
  border-right-color: #8561f9;
  border-top-color: #8561f9;
}
.ribbon-box.right .ribbon-secondary.ribbon-shape::after {
  border-right-color: #8561f9;
  border-bottom-color: #8561f9;
}
.ribbon-box .ribbon-success {
  background: #2dcb73;
}
.ribbon-box .ribbon-success.vertical-shape:after {
  border-top: 18px solid #2dcb73;
  border-bottom: 18px solid #2dcb73;
}
.ribbon-box .ribbon-success:before {
  border-color: #24a15b transparent transparent;
}
.ribbon-box .ribbon-success.ribbon-shape::before {
  background-color: #2dcb73;
}
.ribbon-box .ribbon-success.ribbon-shape::after {
  background-color: #7ce2a9;
}
.ribbon-box.right .ribbon-success {
  background: #2dcb73;
}
.ribbon-box.right .ribbon-success.ribbon-shape::before {
  border-right-color: #2dcb73;
  border-top-color: #2dcb73;
}
.ribbon-box.right .ribbon-success.ribbon-shape::after {
  border-right-color: #2dcb73;
  border-bottom-color: #2dcb73;
}
.ribbon-box .ribbon-info {
  background: #4ab0c1;
}
.ribbon-box .ribbon-info.vertical-shape:after {
  border-top: 18px solid #4ab0c1;
  border-bottom: 18px solid #4ab0c1;
}
.ribbon-box .ribbon-info:before {
  border-color: #3792a1 transparent transparent;
}
.ribbon-box .ribbon-info.ribbon-shape::before {
  background-color: #4ab0c1;
}
.ribbon-box .ribbon-info.ribbon-shape::after {
  background-color: #96d1db;
}
.ribbon-box.right .ribbon-info {
  background: #4ab0c1;
}
.ribbon-box.right .ribbon-info.ribbon-shape::before {
  border-right-color: #4ab0c1;
  border-top-color: #4ab0c1;
}
.ribbon-box.right .ribbon-info.ribbon-shape::after {
  border-right-color: #4ab0c1;
  border-bottom-color: #4ab0c1;
}
.ribbon-box .ribbon-warning {
  background: #f6b749;
}
.ribbon-box .ribbon-warning.vertical-shape:after {
  border-top: 18px solid #f6b749;
  border-bottom: 18px solid #f6b749;
}
.ribbon-box .ribbon-warning:before {
  border-color: #f4a418 transparent transparent;
}
.ribbon-box .ribbon-warning.ribbon-shape::before {
  background-color: #f6b749;
}
.ribbon-box .ribbon-warning.ribbon-shape::after {
  background-color: #fbddaa;
}
.ribbon-box.right .ribbon-warning {
  background: #f6b749;
}
.ribbon-box.right .ribbon-warning.ribbon-shape::before {
  border-right-color: #f6b749;
  border-top-color: #f6b749;
}
.ribbon-box.right .ribbon-warning.ribbon-shape::after {
  border-right-color: #f6b749;
  border-bottom-color: #f6b749;
}
.ribbon-box .ribbon-danger {
  background: #ff6c6c;
}
.ribbon-box .ribbon-danger.vertical-shape:after {
  border-top: 18px solid #ff6c6c;
  border-bottom: 18px solid #ff6c6c;
}
.ribbon-box .ribbon-danger:before {
  border-color: #ff3939 transparent transparent;
}
.ribbon-box .ribbon-danger.ribbon-shape::before {
  background-color: #ff6c6c;
}
.ribbon-box .ribbon-danger.ribbon-shape::after {
  background-color: #ffd2d2;
}
.ribbon-box.right .ribbon-danger {
  background: #ff6c6c;
}
.ribbon-box.right .ribbon-danger.ribbon-shape::before {
  border-right-color: #ff6c6c;
  border-top-color: #ff6c6c;
}
.ribbon-box.right .ribbon-danger.ribbon-shape::after {
  border-right-color: #ff6c6c;
  border-bottom-color: #ff6c6c;
}
.ribbon-box .ribbon-light {
  background: #eff2f7;
}
.ribbon-box .ribbon-light.vertical-shape:after {
  border-top: 18px solid #eff2f7;
  border-bottom: 18px solid #eff2f7;
}
.ribbon-box .ribbon-light:before {
  border-color: #cdd6e6 transparent transparent;
}
.ribbon-box .ribbon-light.ribbon-shape::before {
  background-color: #eff2f7;
}
.ribbon-box .ribbon-light.ribbon-shape::after {
  background-color: white;
}
.ribbon-box.right .ribbon-light {
  background: #eff2f7;
}
.ribbon-box.right .ribbon-light.ribbon-shape::before {
  border-right-color: #eff2f7;
  border-top-color: #eff2f7;
}
.ribbon-box.right .ribbon-light.ribbon-shape::after {
  border-right-color: #eff2f7;
  border-bottom-color: #eff2f7;
}
.ribbon-box .ribbon-dark {
  background: #141821;
}
.ribbon-box .ribbon-dark.vertical-shape:after {
  border-top: 18px solid #141821;
  border-bottom: 18px solid #141821;
}
.ribbon-box .ribbon-dark:before {
  border-color: #010101 transparent transparent;
}
.ribbon-box .ribbon-dark.ribbon-shape::before {
  background-color: #141821;
}
.ribbon-box .ribbon-dark.ribbon-shape::after {
  background-color: #3a4661;
}
.ribbon-box.right .ribbon-dark {
  background: #141821;
}
.ribbon-box.right .ribbon-dark.ribbon-shape::before {
  border-right-color: #141821;
  border-top-color: #141821;
}
.ribbon-box.right .ribbon-dark.ribbon-shape::after {
  border-right-color: #141821;
  border-bottom-color: #141821;
}

.ribbon-box .trending-ribbon {
  transform: translateX(-50px);
  transition: all 0.5s ease;
}
.ribbon-box .trending-ribbon .trending-ribbon-text {
  transition: all 0.5s ease;
  opacity: 0;
}
.ribbon-box:hover .trending-ribbon {
  transform: translateX(0);
}
.ribbon-box:hover .trending-ribbon .trending-ribbon-text {
  opacity: 1;
}
.ribbon-box.right .trending-ribbon {
  transform: translateX(50px);
  transition: all 0.5s ease;
}
.ribbon-box.right .trending-ribbon .trending-ribbon-text {
  transition: all 0.5s ease;
  opacity: 0;
}
.ribbon-box.right:hover .trending-ribbon {
  transform: translateX(0);
}
.ribbon-box.right:hover .trending-ribbon .trending-ribbon-text {
  opacity: 1;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-border-primary .toast-body {
  color: #438eff;
  border-bottom: 3px solid #438eff;
}

.toast-border-secondary .toast-body {
  color: #8561f9;
  border-bottom: 3px solid #8561f9;
}

.toast-border-success .toast-body {
  color: #2dcb73;
  border-bottom: 3px solid #2dcb73;
}

.toast-border-info .toast-body {
  color: #4ab0c1;
  border-bottom: 3px solid #4ab0c1;
}

.toast-border-warning .toast-body {
  color: #f6b749;
  border-bottom: 3px solid #f6b749;
}

.toast-border-danger .toast-body {
  color: #ff6c6c;
  border-bottom: 3px solid #ff6c6c;
}

.toast-border-light .toast-body {
  color: #eff2f7;
  border-bottom: 3px solid #eff2f7;
}

.toast-border-dark .toast-body {
  color: #141821;
  border-bottom: 3px solid #141821;
}

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}

.scrollspy-example-2 {
  position: relative;
  height: 370px;
  overflow: auto;
}

:root {
  --tb-card-logo-dark: block;
  --tb-card-logo-light: none;
  --tb-vertical-menu-bg: #fff;
  --tb-vertical-menu-border: #eff2f7;
  --tb-vertical-menu-item-color: #6d7080;
  --tb-vertical-menu-item-hover-color: var(--tb-primary);
  --tb-vertical-menu-item-hover-bg: var(--tb-primary);
  --tb-vertical-menu-item-active-color: var(--tb-primary);
  --tb-vertical-menu-item-active-bg: rgba(67, 142, 255, 0.15);
  --tb-vertical-menu-sub-item-color: #7c7f90;
  --tb-vertical-menu-sub-item-hover-color: var(--tb-primary);
  --tb-vertical-menu-sub-item-active-color: var(--tb-primary);
  --tb-vertical-menu-title-color: #919da9;
  --tb-vertical-menu-item-icon-width: 1.75rem;
  --tb-header-bg: #fff;
  --tb-header-item-color: #1f242e;
  --tb-header-item-sub-color: var(--tb-secondary-color);
  --tb-topbar-search-bg: #f3f3f9;
  --tb-topbar-user-bg: #f3f6f9;
  --tb-footer-bg: #fff;
  --tb-footer-color: #98a6ad;
  --tb-topnav-bg: #fff;
  --tb-topnav-item-color: #6d7080;
  --tb-topnav-item-color-active: var(--tb-primary);
  --tb-twocolumn-menu-iconview-bg: #fff;
  --tb-twocolumn-menu-bg: #fff;
  --tb-boxed-body-bg: #f5f5f5;
}
:root[data-sidebar=dark] {
  --tb-vertical-menu-bg: #1f242e;
  --tb-vertical-menu-border: #1c2932;
  --tb-vertical-menu-item-color: #a2aac4;
  --tb-vertical-menu-item-hover-color: #fff;
  --tb-vertical-menu-item-hover-bg: #1c2932;
  --tb-vertical-menu-item-active-color: #fff;
  --tb-vertical-menu-item-active-bg: rgba(255, 255, 255, 0.15);
  --tb-vertical-menu-sub-item-color: #a2aac4;
  --tb-vertical-menu-sub-item-hover-color: #fff;
  --tb-vertical-menu-sub-item-active-color: #fff;
  --tb-vertical-menu-title-color: #838fb9;
  --tb-twocolumn-menu-iconview-bg: #1c2932;
}
:root[data-topbar=dark] {
  --tb-header-bg: #1f242e;
  --tb-header-item-color: #b0c4d9;
  --tb-header-item-sub-color: #878a99;
  --tb-topbar-user-bg: rgba(255, 255, 255, 0.05);
  --tb-topbar-user-color: rgba(255, 255, 255, 0.08);
}
:root:is([data-sidebar=gradient], [data-sidebar=gradient-2], [data-sidebar=gradient-3], [data-sidebar=gradient-4]) {
  --tb-vertical-menu-item-color: rgba(255, 255, 255, 0.5);
  --tb-vertical-menu-item-hover-color: #fff;
  --tb-vertical-menu-item-active-color: #fff;
  --tb-vertical-menu-item-active-bg: rgba(255, 255, 255, 0.15);
  --tb-vertical-menu-sub-item-color: rgba(255, 255, 255, 0.5);
  --tb-vertical-menu-sub-item-hover-color: #fff;
  --tb-vertical-menu-sub-item-active-color: #fff;
  --tb-vertical-menu-title-color: rgba(255, 255, 255, 0.5);
}
:root[data-sidebar=gradient] {
  --tb-vertical-menu-bg: linear-gradient(to left, #285599, #1b3966);
  --tb-vertical-menu-border: #285599;
  --tb-vertical-menu-item-hover-bg: linear-gradient(to left, #285599, #1b3966);
  --tb-twocolumn-menu-iconview-bg: #1b3966;
}
:root[data-sidebar=gradient-2] {
  --tb-vertical-menu-bg: linear-gradient(to left, #2c6a74, #1e464d);
  --tb-vertical-menu-border: #2c6a74;
  --tb-vertical-menu-item-hover-bg: linear-gradient(to left, #2c6a74, #1e464d);
  --tb-twocolumn-menu-iconview-bg: #1e464d;
}
:root[data-sidebar=gradient-3] {
  --tb-vertical-menu-bg: linear-gradient(to left, #503a95, #352764);
  --tb-vertical-menu-border: #503a95;
  --tb-vertical-menu-item-hover-bg: linear-gradient(to left, #503a95, #352764);
  --tb-twocolumn-menu-iconview-bg: #352764;
}
:root[data-sidebar=gradient-4] {
  --tb-vertical-menu-bg: linear-gradient(to right, #1b7a45, #12512e);
  --tb-vertical-menu-border: #1b7a45;
  --tb-vertical-menu-item-hover-bg: linear-gradient(to right, #1b7a45, #12512e);
  --tb-twocolumn-menu-iconview-bg: #12512e;
}

[data-bs-theme=dark] {
  --tb-light: #2b313e;
  --tb-light-rgb: 43, 49, 62;
  --tb-dark: #2b313e;
  --tb-dark-rgb: 43, 49, 62;
  --tb-card-logo-dark: none;
  --tb-card-logo-light: block;
  --tb-footer-bg: #1f242e;
  --tb-footer-color: #878a99;
  --tb-topnav-bg: #1c2932;
  --tb-topnav-item-color: #a2aac4;
  --tb-topnav-item-color-active: #fff;
  --tb-boxed-body-bg: #11151c;
}
[data-bs-theme=dark] .table-light {
  --tb-table-color: white;
  --tb-table-bg: var(--tb-light);
  --tb-table-border-color: var(--tb-border-color);
  --tb-table-striped-bg: var(--tb-light);
  --tb-table-striped-color: white;
  --tb-table-active-bg: var(--tb-light);
  --tb-table-active-color: white;
  --tb-table-hover-bg: var(--tb-light);
  --tb-table-hover-color: white;
}
[data-bs-theme=dark] .btn-dark {
  --tb-btn-bg: #2e3543;
  --tb-btn-border-color: #2e3543;
  --tb-btn-hover-bg: #323949;
  --tb-btn-hover-border-color: #323949;
  --tb-btn-active-bg: #323949;
  --tb-btn-active-border-color: #323949;
  --tb-btn-disabled-bg: #323949;
  --tb-btn-disabled-border-color: #323949;
}
[data-bs-theme=dark] .btn-outline-dark {
  --tb-btn-color: #fff;
  --tb-btn-border-color: #2e3543;
  --tb-btn-hover-bg: #2e3543;
  --tb-btn-hover-border-color: #2e3543;
  --tb-btn-active-bg: #2e3543;
  --tb-btn-active-border-color: #2e3543;
}

html {
  position: relative;
  min-height: 100%;
}

b,
strong {
  font-weight: 600;
}

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid var(--tb-border-color-translucent);
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid var(--tb-border-color-translucent);
  text-align: right;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px;
  }
}

.row > * {
  position: relative;
}

.alert-solid .alert-link {
  color: #fff;
}

.alert-outline {
  background-color: var(--tb-secondary-bg);
  border-width: 2px;
}

.alert-borderless {
  border-width: 0;
}

.alert-label-icon {
  position: relative;
  padding-left: 60px;
  border: 0;
}
.alert-label-icon .label-icon {
  position: absolute;
  width: 45px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-label-icon.label-arrow {
  overflow: hidden;
}
.alert-label-icon.label-arrow .label-icon:after {
  content: "";
  position: absolute;
  border: 6px solid transparent;
  border-left-color: #438eff;
  right: -12px;
}

.alert-border-left {
  border-left: 3px solid;
}

.alert-top-border {
  background-color: var(--tb-secondary-bg);
  border-color: var(--tb-border-color);
  border-top: 2px solid;
  color: var(--tb-body-color);
}

.alert-additional {
  padding: 0;
}
.alert-additional .alert-body {
  padding: 0.8rem 1rem;
}
.alert-additional .alert-content {
  padding: 0.8rem 1rem;
  border-bottom-left-radius: var(--tb-border-radius);
  border-bottom-right-radius: var(--tb-border-radius);
  margin: 0 -var(--tb-border-width) -var(--tb-border-width) -var(--tb-border-width);
}

.rounded-label .label-icon {
  width: 45px;
  height: 26px;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 0 30px 30px 0;
}

.alert-solid.alert-primary {
  --tb-alert-bg: #438eff;
  --tb-alert-color: #fff;
  --tb-alert-border-color: #438eff;
}

.alert-outline.alert-primary {
  color: #438eff;
}

.alert-border-left.alert-primary {
  border-left-color: #438eff;
}

.alert-top-border.alert-primary {
  border-top-color: #438eff;
}

.rounded-label.alert-primary .label-icon {
  background-color: #438eff;
  color: #fff;
}

.label-arrow.alert-primary .label-icon {
  color: #fff;
  background-color: #438eff;
}
.label-arrow.alert-primary .label-icon:after {
  border-left-color: #438eff !important;
}

.alert.alert-primary .btn-close::after {
  color: #438eff;
}

.alert-additional.alert-primary .alert-content {
  background-color: #438eff;
  color: #fff;
}

.alert-solid.alert-secondary {
  --tb-alert-bg: #8561f9;
  --tb-alert-color: #fff;
  --tb-alert-border-color: #8561f9;
}

.alert-outline.alert-secondary {
  color: #8561f9;
}

.alert-border-left.alert-secondary {
  border-left-color: #8561f9;
}

.alert-top-border.alert-secondary {
  border-top-color: #8561f9;
}

.rounded-label.alert-secondary .label-icon {
  background-color: #8561f9;
  color: #fff;
}

.label-arrow.alert-secondary .label-icon {
  color: #fff;
  background-color: #8561f9;
}
.label-arrow.alert-secondary .label-icon:after {
  border-left-color: #8561f9 !important;
}

.alert.alert-secondary .btn-close::after {
  color: #8561f9;
}

.alert-additional.alert-secondary .alert-content {
  background-color: #8561f9;
  color: #fff;
}

.alert-solid.alert-success {
  --tb-alert-bg: #2dcb73;
  --tb-alert-color: #fff;
  --tb-alert-border-color: #2dcb73;
}

.alert-outline.alert-success {
  color: #2dcb73;
}

.alert-border-left.alert-success {
  border-left-color: #2dcb73;
}

.alert-top-border.alert-success {
  border-top-color: #2dcb73;
}

.rounded-label.alert-success .label-icon {
  background-color: #2dcb73;
  color: #fff;
}

.label-arrow.alert-success .label-icon {
  color: #fff;
  background-color: #2dcb73;
}
.label-arrow.alert-success .label-icon:after {
  border-left-color: #2dcb73 !important;
}

.alert.alert-success .btn-close::after {
  color: #2dcb73;
}

.alert-additional.alert-success .alert-content {
  background-color: #2dcb73;
  color: #fff;
}

.alert-solid.alert-info {
  --tb-alert-bg: #4ab0c1;
  --tb-alert-color: #fff;
  --tb-alert-border-color: #4ab0c1;
}

.alert-outline.alert-info {
  color: #4ab0c1;
}

.alert-border-left.alert-info {
  border-left-color: #4ab0c1;
}

.alert-top-border.alert-info {
  border-top-color: #4ab0c1;
}

.rounded-label.alert-info .label-icon {
  background-color: #4ab0c1;
  color: #fff;
}

.label-arrow.alert-info .label-icon {
  color: #fff;
  background-color: #4ab0c1;
}
.label-arrow.alert-info .label-icon:after {
  border-left-color: #4ab0c1 !important;
}

.alert.alert-info .btn-close::after {
  color: #4ab0c1;
}

.alert-additional.alert-info .alert-content {
  background-color: #4ab0c1;
  color: #fff;
}

.alert-solid.alert-warning {
  --tb-alert-bg: #f6b749;
  --tb-alert-color: #fff;
  --tb-alert-border-color: #f6b749;
}

.alert-outline.alert-warning {
  color: #f6b749;
}

.alert-border-left.alert-warning {
  border-left-color: #f6b749;
}

.alert-top-border.alert-warning {
  border-top-color: #f6b749;
}

.rounded-label.alert-warning .label-icon {
  background-color: #f6b749;
  color: #fff;
}

.label-arrow.alert-warning .label-icon {
  color: #fff;
  background-color: #f6b749;
}
.label-arrow.alert-warning .label-icon:after {
  border-left-color: #f6b749 !important;
}

.alert.alert-warning .btn-close::after {
  color: #f6b749;
}

.alert-additional.alert-warning .alert-content {
  background-color: #f6b749;
  color: #fff;
}

.alert-solid.alert-danger {
  --tb-alert-bg: #ff6c6c;
  --tb-alert-color: #000;
  --tb-alert-border-color: #ff6c6c;
}

.alert-outline.alert-danger {
  color: #ff6c6c;
}

.alert-border-left.alert-danger {
  border-left-color: #ff6c6c;
}

.alert-top-border.alert-danger {
  border-top-color: #ff6c6c;
}

.rounded-label.alert-danger .label-icon {
  background-color: #ff6c6c;
  color: #000;
}

.label-arrow.alert-danger .label-icon {
  color: #000;
  background-color: #ff6c6c;
}
.label-arrow.alert-danger .label-icon:after {
  border-left-color: #ff6c6c !important;
}

.alert.alert-danger .btn-close::after {
  color: #ff6c6c;
}

.alert-additional.alert-danger .alert-content {
  background-color: #ff6c6c;
  color: #000;
}

.alert-solid.alert-light {
  --tb-alert-bg: #eff2f7;
  --tb-alert-color: #000;
  --tb-alert-border-color: #eff2f7;
}

.alert-outline.alert-light {
  color: #eff2f7;
}

.alert-border-left.alert-light {
  border-left-color: #eff2f7;
}

.alert-top-border.alert-light {
  border-top-color: #eff2f7;
}

.rounded-label.alert-light .label-icon {
  background-color: #eff2f7;
  color: #000;
}

.label-arrow.alert-light .label-icon {
  color: #000;
  background-color: #eff2f7;
}
.label-arrow.alert-light .label-icon:after {
  border-left-color: #eff2f7 !important;
}

.alert.alert-light .btn-close::after {
  color: #eff2f7;
}

.alert-additional.alert-light .alert-content {
  background-color: #eff2f7;
  color: #000;
}

.alert-solid.alert-dark {
  --tb-alert-bg: #141821;
  --tb-alert-color: #fff;
  --tb-alert-border-color: #141821;
}

.alert-outline.alert-dark {
  color: #141821;
}

.alert-border-left.alert-dark {
  border-left-color: #141821;
}

.alert-top-border.alert-dark {
  border-top-color: #141821;
}

.rounded-label.alert-dark .label-icon {
  background-color: #141821;
  color: #fff;
}

.label-arrow.alert-dark .label-icon {
  color: #fff;
  background-color: #141821;
}
.label-arrow.alert-dark .label-icon:after {
  border-left-color: #141821 !important;
}

.alert.alert-dark .btn-close::after {
  color: #141821;
}

.alert-additional.alert-dark .alert-content {
  background-color: #141821;
  color: #fff;
}

.alert-dismissible .btn-close {
  background: transparent !important;
}
.alert-dismissible .btn-close::after {
  background: transparent !important;
  content: "\f0156" !important;
  font-size: 18px;
  line-height: 15px;
  font-family: "Material Design Icons" !important;
}

.alert-outline.alert-dark {
  color: var(--tb-dark);
}

.alert-modern {
  overflow: hidden;
  position: relative;
}
.alert-modern .icons {
  position: absolute;
  top: -20px;
  opacity: 0.08;
  font-size: 60px;
  transform: rotate(-45deg);
}

.badge-outline-primary {
  color: #438eff;
  border: 1px solid #438eff;
  background-color: transparent;
}

.badge-outline-secondary {
  color: #8561f9;
  border: 1px solid #8561f9;
  background-color: transparent;
}

.badge-outline-success {
  color: #2dcb73;
  border: 1px solid #2dcb73;
  background-color: transparent;
}

.badge-outline-info {
  color: #4ab0c1;
  border: 1px solid #4ab0c1;
  background-color: transparent;
}

.badge-outline-warning {
  color: #f6b749;
  border: 1px solid #f6b749;
  background-color: transparent;
}

.badge-outline-danger {
  color: #ff6c6c;
  border: 1px solid #ff6c6c;
  background-color: transparent;
}

.badge-outline-light {
  color: #eff2f7;
  border: 1px solid #eff2f7;
  background-color: transparent;
}

.badge-outline-dark {
  color: #141821;
  border: 1px solid #141821;
  background-color: transparent;
}

.badge-outline {
  background-color: var(--tb-secondary-bg);
  border: 1px solid;
}

.badge-soft-primary {
  color: #438eff;
  background-color: rgba(67, 142, 255, 0.1);
}

.badge-outline.badge-primary {
  color: #438eff;
  border: 1px solid #438eff;
  background-color: transparent;
}

.badge-label.bg-primary:before {
  border-right-color: #438eff;
}

.badge-soft-secondary {
  color: #8561f9;
  background-color: rgba(133, 97, 249, 0.1);
}

.badge-outline.badge-secondary {
  color: #8561f9;
  border: 1px solid #8561f9;
  background-color: transparent;
}

.badge-label.bg-secondary:before {
  border-right-color: #8561f9;
}

.badge-soft-success {
  color: #2dcb73;
  background-color: rgba(45, 203, 115, 0.1);
}

.badge-outline.badge-success {
  color: #2dcb73;
  border: 1px solid #2dcb73;
  background-color: transparent;
}

.badge-label.bg-success:before {
  border-right-color: #2dcb73;
}

.badge-soft-info {
  color: #4ab0c1;
  background-color: rgba(74, 176, 193, 0.1);
}

.badge-outline.badge-info {
  color: #4ab0c1;
  border: 1px solid #4ab0c1;
  background-color: transparent;
}

.badge-label.bg-info:before {
  border-right-color: #4ab0c1;
}

.badge-soft-warning {
  color: #f6b749;
  background-color: rgba(246, 183, 73, 0.1);
}

.badge-outline.badge-warning {
  color: #f6b749;
  border: 1px solid #f6b749;
  background-color: transparent;
}

.badge-label.bg-warning:before {
  border-right-color: #f6b749;
}

.badge-soft-danger {
  color: #ff6c6c;
  background-color: rgba(255, 108, 108, 0.1);
}

.badge-outline.badge-danger {
  color: #ff6c6c;
  border: 1px solid #ff6c6c;
  background-color: transparent;
}

.badge-label.bg-danger:before {
  border-right-color: #ff6c6c;
}

.badge-soft-light {
  color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.1);
}

.badge-outline.badge-light {
  color: #eff2f7;
  border: 1px solid #eff2f7;
  background-color: transparent;
}

.badge-label.bg-light:before {
  border-right-color: #eff2f7;
}

.badge-soft-dark {
  color: #141821;
  background-color: rgba(20, 24, 33, 0.1);
}

.badge-outline.badge-dark {
  color: #141821;
  border: 1px solid #141821;
  background-color: transparent;
}

.badge-label.bg-dark:before {
  border-right-color: #141821;
}

.badge-border {
  border-left: 2px solid;
}

.badge-label {
  margin-left: 8px;
  position: relative;
}
.badge-label:before {
  content: "";
  position: absolute;
  border: 8px solid transparent;
  border-right-color: #438eff;
  left: -14px;
  top: 0;
}

.badge-gradient-primary {
  background: linear-gradient(135deg, #438eff 0%, #2dcb73 100%);
}

.badge-gradient-secondary {
  background: linear-gradient(135deg, #8561f9 0%, #4ab0c1 100%);
}

.badge-gradient-success {
  background: linear-gradient(135deg, #2dcb73 0%, #f6b749 100%);
}

.badge-gradient-danger {
  background: linear-gradient(135deg, #ff6c6c 0%, #8561f9 100%);
}

.badge-gradient-warning {
  background: linear-gradient(135deg, #f6b749 0%, #e7970b 100%);
}

.badge-gradient-info {
  background: linear-gradient(135deg, #4ab0c1 0%, #2dcb73 100%);
}

.badge-gradient-dark {
  background: linear-gradient(135deg, #141821 0%, #438eff 100%);
}

button,
a {
  outline: none !important;
  position: relative;
}

.btn.btn-hover {
  transition: all 0.5s ease;
}
.btn.btn-hover:hover {
  transform: translateY(-5px);
}

.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: transparent;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: calc(1.2rem + 1.5em + 2px);
  width: calc(1.2rem + 1.5em + 2px);
  padding: 0;
}
.btn-icon i,
.btn-icon svg,
.btn-icon img {
  vertical-align: middle;
}
.btn-icon.btn-sm {
  height: calc(0.5rem + 1.5em + 2px);
  width: calc(0.5rem + 1.5em + 2px);
}
.btn-icon.btn-lg {
  height: calc(1.4rem + 1.5em + 2px);
  width: calc(1.4rem + 1.5em + 2px);
}

.btn-soft-primary {
  --tb-btn-color: #438eff;
  --tb-btn-bg: rgba(67, 142, 255, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #3c80e6;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 67, 142, 255;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #3672cc;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-primary {
  --tb-btn-color: #fff;
  --tb-btn-bg: #106fff;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #0166ff;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 67, 142, 255;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #0062f6;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-secondary {
  --tb-btn-color: #8561f9;
  --tb-btn-bg: rgba(133, 97, 249, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #7857e0;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 133, 97, 249;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #6a4ec7;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-secondary {
  --tb-btn-color: #fff;
  --tb-btn-bg: #5f30f7;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #5421f7;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 133, 97, 249;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #4c17f6;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-success {
  --tb-btn-color: #2dcb73;
  --tb-btn-bg: rgba(45, 203, 115, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #29b768;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 45, 203, 115;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #24a25c;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-success {
  --tb-btn-color: #fff;
  --tb-btn-bg: #24a15b;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #219554;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 45, 203, 115;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #1f8c50;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-info {
  --tb-btn-color: #4ab0c1;
  --tb-btn-bg: rgba(74, 176, 193, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #439eae;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 74, 176, 193;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #3b8d9a;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-info {
  --tb-btn-color: #fff;
  --tb-btn-bg: #3792a1;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #338795;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 74, 176, 193;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #31818e;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-warning {
  --tb-btn-color: #f6b749;
  --tb-btn-bg: rgba(246, 183, 73, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #dda542;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 246, 183, 73;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #c5923a;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-warning {
  --tb-btn-color: #fff;
  --tb-btn-bg: #f4a418;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #f19d0c;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 246, 183, 73;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #e7970b;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-danger {
  --tb-btn-color: #ff6c6c;
  --tb-btn-bg: rgba(255, 108, 108, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #e66161;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 255, 108, 108;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #cc5656;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-danger {
  --tb-btn-color: #fff;
  --tb-btn-bg: #ff3939;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #ff2a2a;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 255, 108, 108;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #ff2020;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-light {
  --tb-btn-color: #eff2f7;
  --tb-btn-bg: rgba(239, 242, 247, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #d7dade;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 239, 242, 247;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #bfc2c6;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-light {
  --tb-btn-color: #fff;
  --tb-btn-bg: #cdd6e6;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #c3cee1;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 239, 242, 247;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #bcc9de;
  --tb-btn-active-border-color: transparent;
}

.btn-soft-dark {
  --tb-btn-color: #141821;
  --tb-btn-bg: rgba(20, 24, 33, 0.1);
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: #12161e;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 20, 24, 33;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: #10131a;
  --tb-btn-active-border-color: transparent;
}

.btn-darken-dark {
  --tb-btn-color: #fff;
  --tb-btn-bg: #010101;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #fff;
  --tb-btn-hover-bg: black;
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 20, 24, 33;
  --tb-btn-active-color: var(--tb-btn-hover-color);
  --tb-btn-active-bg: black;
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-primary {
  --tb-btn-color: #438eff;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #438eff;
  --tb-btn-hover-bg: rgba(67, 142, 255, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 67, 142, 255;
  --tb-btn-active-color: #438eff;
  --tb-btn-active-bg: rgba(67, 142, 255, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-secondary {
  --tb-btn-color: #8561f9;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #8561f9;
  --tb-btn-hover-bg: rgba(133, 97, 249, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 133, 97, 249;
  --tb-btn-active-color: #8561f9;
  --tb-btn-active-bg: rgba(133, 97, 249, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-success {
  --tb-btn-color: #2dcb73;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #2dcb73;
  --tb-btn-hover-bg: rgba(45, 203, 115, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 45, 203, 115;
  --tb-btn-active-color: #2dcb73;
  --tb-btn-active-bg: rgba(45, 203, 115, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-info {
  --tb-btn-color: #4ab0c1;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #4ab0c1;
  --tb-btn-hover-bg: rgba(74, 176, 193, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 74, 176, 193;
  --tb-btn-active-color: #4ab0c1;
  --tb-btn-active-bg: rgba(74, 176, 193, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-warning {
  --tb-btn-color: #f6b749;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #f6b749;
  --tb-btn-hover-bg: rgba(246, 183, 73, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 246, 183, 73;
  --tb-btn-active-color: #f6b749;
  --tb-btn-active-bg: rgba(246, 183, 73, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-danger {
  --tb-btn-color: #ff6c6c;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #ff6c6c;
  --tb-btn-hover-bg: rgba(255, 108, 108, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 255, 108, 108;
  --tb-btn-active-color: #ff6c6c;
  --tb-btn-active-bg: rgba(255, 108, 108, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-light {
  --tb-btn-color: #eff2f7;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #eff2f7;
  --tb-btn-hover-bg: rgba(239, 242, 247, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 239, 242, 247;
  --tb-btn-active-color: #eff2f7;
  --tb-btn-active-bg: rgba(239, 242, 247, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-ghost-dark {
  --tb-btn-color: #141821;
  --tb-btn-bg: transparent;
  --tb-btn-border-color: transparent;
  --tb-btn-hover-color: #141821;
  --tb-btn-hover-bg: rgba(20, 24, 33, 0.1);
  --tb-btn-hover-border-color: transparent;
  --tb-btn-focus-shadow-rgb: 20, 24, 33;
  --tb-btn-active-color: #141821;
  --tb-btn-active-bg: rgba(20, 24, 33, 0.1);
  --tb-btn-active-border-color: transparent;
}

.btn-label {
  position: relative;
  padding-left: 48px;
}
.btn-label .label-icon {
  position: absolute;
  width: 40.69px;
  left: -1px;
  top: -1px;
  bottom: -1px;
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-label.btn-light .label-icon {
  background-color: rgba(20, 24, 33, 0.05);
}
.btn-label.right {
  padding-left: 0.9rem;
  padding-right: 44px;
}
.btn-label.right .label-icon {
  right: -1px;
  left: auto;
}

.btn-animation {
  overflow: hidden;
  transition: border-color 0.3s, background-color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.btn-animation::after {
  content: attr(data-text);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translate3d(0, 25%, 0);
  padding: 0.6rem 0.9rem;
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.btn-animation > span {
  display: block;
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.btn-animation:hover::after {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.btn-animation:hover > span {
  opacity: 0;
  transform: translate3d(0, -25%, 0);
}

.btn-primary.btn-animation {
  background-color: #438eff;
  border-color: #438eff;
}
.btn-primary.btn-animation:hover, .btn-primary.btn-animation:focus, .btn-primary.btn-animation:active, .btn-primary.btn-animation:focus-visible {
  color: #438eff;
  background-color: rgba(67, 142, 255, 0.1);
}

.btn-secondary.btn-animation {
  background-color: #8561f9;
  border-color: #8561f9;
}
.btn-secondary.btn-animation:hover, .btn-secondary.btn-animation:focus, .btn-secondary.btn-animation:active, .btn-secondary.btn-animation:focus-visible {
  color: #8561f9;
  background-color: rgba(133, 97, 249, 0.1);
}

.btn-success.btn-animation {
  background-color: #2dcb73;
  border-color: #2dcb73;
}
.btn-success.btn-animation:hover, .btn-success.btn-animation:focus, .btn-success.btn-animation:active, .btn-success.btn-animation:focus-visible {
  color: #2dcb73;
  background-color: rgba(45, 203, 115, 0.1);
}

.btn-info.btn-animation {
  background-color: #4ab0c1;
  border-color: #4ab0c1;
}
.btn-info.btn-animation:hover, .btn-info.btn-animation:focus, .btn-info.btn-animation:active, .btn-info.btn-animation:focus-visible {
  color: #4ab0c1;
  background-color: rgba(74, 176, 193, 0.1);
}

.btn-warning.btn-animation {
  background-color: #f6b749;
  border-color: #f6b749;
}
.btn-warning.btn-animation:hover, .btn-warning.btn-animation:focus, .btn-warning.btn-animation:active, .btn-warning.btn-animation:focus-visible {
  color: #f6b749;
  background-color: rgba(246, 183, 73, 0.1);
}

.btn-danger.btn-animation {
  background-color: #ff6c6c;
  border-color: #ff6c6c;
}
.btn-danger.btn-animation:hover, .btn-danger.btn-animation:focus, .btn-danger.btn-animation:active, .btn-danger.btn-animation:focus-visible {
  color: #ff6c6c;
  background-color: rgba(255, 108, 108, 0.1);
}

.btn-light.btn-animation {
  background-color: #eff2f7;
  border-color: #eff2f7;
}
.btn-light.btn-animation:hover, .btn-light.btn-animation:focus, .btn-light.btn-animation:active, .btn-light.btn-animation:focus-visible {
  color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.1);
}

.btn-dark.btn-animation {
  background-color: #141821;
  border-color: #141821;
}
.btn-dark.btn-animation:hover, .btn-dark.btn-animation:focus, .btn-dark.btn-animation:active, .btn-dark.btn-animation:focus-visible {
  color: #141821;
  background-color: rgba(20, 24, 33, 0.1);
}

.btn-group-vertical label {
  margin-bottom: 0;
}

.btn-group.radio .btn {
  border: none;
}
.btn-group.radio .btn-check:active + .btn-light,
.btn-group.radio .btn-check:checked + .btn-light,
.btn-group.radio .btn-light.active,
.btn-group.radio .btn-light.dropdown-toggle.show,
.btn-group.radio .btn-light:active {
  background-color: rgba(74, 176, 193, 0.2);
  color: #4ab0c1;
}

[data-bs-theme=dark] .btn-check:focus + .btn-light,
[data-bs-theme=dark] .btn-check:focus + .btn-outline-light,
[data-bs-theme=dark] .btn-check:focus + .btn-soft-light,
[data-bs-theme=dark] .btn-soft-light:focus {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-light-rgb), 0.75);
  border-color: rgba(var(--tb-light-rgb), 0.75);
}
[data-bs-theme=dark] .btn-check:active + .btn-light,
[data-bs-theme=dark] .btn-check:active + .btn-outline-light,
[data-bs-theme=dark] .btn-check:active + .btn-soft-light,
[data-bs-theme=dark] .btn-check:checked + .btn-light,
[data-bs-theme=dark] .btn-check:checked + .btn-outline-light,
[data-bs-theme=dark] .btn-check:checked + .btn-soft-light,
[data-bs-theme=dark] .btn-light.active,
[data-bs-theme=dark] .btn-light:active,
[data-bs-theme=dark] .btn-outline-light.active,
[data-bs-theme=dark] .btn-outline-light:active,
[data-bs-theme=dark] .btn-soft-light.active,
[data-bs-theme=dark] .btn-soft-light:active,
[data-bs-theme=dark] .show > .btn-light.dropdown-toggle,
[data-bs-theme=dark] .show > .btn-outline-light.dropdown-toggle,
[data-bs-theme=dark] .show > .btn-soft-light.dropdown-toggle {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-light-rgb), 0.75);
  border-color: rgba(var(--tb-light-rgb), 0.75);
}
[data-bs-theme=dark] .btn-check:focus + .btn-dark,
[data-bs-theme=dark] .btn-check:focus + .btn-outline-dark,
[data-bs-theme=dark] .btn-check:focus + .btn-soft-dark,
[data-bs-theme=dark] .btn-soft-dark:focus {
  color: var(--tb-light);
  background-color: rgba(var(--tb-dark-rgb), 0.75);
  border-color: rgba(var(--tb-dark-rgb), 0.75);
}
[data-bs-theme=dark] .btn-check:active + .btn-dark,
[data-bs-theme=dark] .btn-check:active + .btn-outline-dark,
[data-bs-theme=dark] .btn-check:active + .btn-soft-dark,
[data-bs-theme=dark] .btn-check:checked + .btn-dark,
[data-bs-theme=dark] .btn-check:checked + .btn-outline-dark,
[data-bs-theme=dark] .btn-check:checked + .btn-soft-dark,
[data-bs-theme=dark] .btn-dark.active,
[data-bs-theme=dark] .btn-dark:active,
[data-bs-theme=dark] .btn-outline-dark.active,
[data-bs-theme=dark] .btn-outline-dark:active,
[data-bs-theme=dark] .btn-soft-dark.active,
[data-bs-theme=dark] .btn-soft-dark:active,
[data-bs-theme=dark] .show > .btn-dark.dropdown-toggle,
[data-bs-theme=dark] .show > .btn-outline-dark.dropdown-toggle,
[data-bs-theme=dark] .show > .btn-soft-dark.dropdown-toggle {
  color: var(--tb-light);
  background-color: rgba(var(--tb-dark-rgb), 0.75);
  border-color: rgba(var(--tb-dark-rgb), 0.75);
}
[data-bs-theme=dark] .btn-soft-light {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-light-rgb), 0.1);
}
[data-bs-theme=dark] .btn-soft-light:hover {
  color: var(--tb-dark);
  background-color: var(--tb-light);
}
[data-bs-theme=dark] .btn-soft-dark {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-dark-rgb), 0.1);
}
[data-bs-theme=dark] .btn-soft-dark:hover {
  color: var(--tb-light);
  background-color: var(--tb-dark);
}
[data-bs-theme=dark] .btn-light,
[data-bs-theme=dark] .btn-light:active,
[data-bs-theme=dark] .btn-light.active,
[data-bs-theme=dark] .btn-light:hover {
  color: var(--tb-btn-active-color);
  background-color: rgba(var(--tb-light-rgb), 0.75);
  border-color: rgba(var(--tb-light-rgb), 0.75);
}
[data-bs-theme=dark] .btn-ghost-light {
  color: var(--tb-dark);
}
[data-bs-theme=dark] .btn-ghost-light:hover, [data-bs-theme=dark] .btn-ghost-light:focus, [data-bs-theme=dark] .btn-ghost-light:active {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-light-rgb), 0.5);
}
[data-bs-theme=dark] .btn-dark.btn-animation {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-dark-rgb), 0.1);
  border-color: rgba(var(--tb-dark-rgb), 0.1);
}
[data-bs-theme=dark] .btn-dark.btn-animation:hover {
  color: var(--tb-dark);
  background-color: rgba(var(--tb-dark-rgb), 0.01);
}

.btn-load .spinner-border,
.btn-load .spinner-grow {
  height: 19px;
  width: 19px;
}

.link-effect {
  display: inline-flex;
  padding-bottom: 3px;
  overflow: hidden;
}
.link-effect::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #438eff;
  transform: translateX(-100%);
  transition: all 0.5s ease;
}
.link-effect:hover::before {
  transform: translateX(0);
}

.btn-primary.btn-border,
.btn-outline-primary.btn-border,
.btn-soft-primary.btn-border {
  border-bottom: 2px solid #1a75ff;
}

.link-effect.link-primary::before {
  background-color: #438eff;
}

.btn-secondary.btn-border,
.btn-outline-secondary.btn-border,
.btn-soft-secondary.btn-border {
  border-bottom: 2px solid #673af8;
}

.link-effect.link-secondary::before {
  background-color: #8561f9;
}

.btn-success.btn-border,
.btn-outline-success.btn-border,
.btn-soft-success.btn-border {
  border-bottom: 2px solid #26aa60;
}

.link-effect.link-success::before {
  background-color: #2dcb73;
}

.btn-info.btn-border,
.btn-outline-info.btn-border,
.btn-soft-info.btn-border {
  border-bottom: 2px solid #3a99a8;
}

.link-effect.link-info::before {
  background-color: #4ab0c1;
}

.btn-warning.btn-border,
.btn-outline-warning.btn-border,
.btn-soft-warning.btn-border {
  border-bottom: 2px solid #f4a822;
}

.link-effect.link-warning::before {
  background-color: #f6b749;
}

.btn-danger.btn-border,
.btn-outline-danger.btn-border,
.btn-soft-danger.btn-border {
  border-bottom: 2px solid #ff4343;
}

.link-effect.link-danger::before {
  background-color: #ff6c6c;
}

.btn-light.btn-border,
.btn-outline-light.btn-border,
.btn-soft-light.btn-border {
  border-bottom: 2px solid #d4dce9;
}

.link-effect.link-light::before {
  background-color: #eff2f7;
}

.btn-dark.btn-border,
.btn-outline-dark.btn-border,
.btn-soft-dark.btn-border {
  border-bottom: 2px solid #050608;
}

.link-effect.link-dark::before {
  background-color: #141821;
}

.custom-toggle .icon-off {
  display: none;
}
.custom-toggle.active .icon-on {
  display: none;
}
.custom-toggle.active .icon-off {
  display: block;
}

.btn-soft-dark {
  color: #fff !important;
}

.breadcrumb-item > a {
  color: var(--tb-breadcrumb-divider-color);
}
.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";
  font-size: 15px;
  line-height: 20px;
}

.breadcrumb-light {
  --tb-breadcrumb-divider-color: rgba(255, 255, 255, 0.75);
  --tb-breadcrumb-item-active-color: #fff;
}

.card {
  margin-bottom: 1.5rem;
}

.card-header {
  border-bottom: 1px solid var(--tb-border-color);
}

.card-header-dropdown .dropdown-btn {
  padding: 1.2rem 0;
}

.card-footer {
  border-top: 1px solid var(--tb-border-color);
}

.card-drop {
  color: #141821;
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
}

.card-title-desc {
  margin-bottom: 24px;
}

.card-height-100 {
  height: calc(100% - 1.5rem);
}

.card-animate {
  transition: all 0.4s;
}
.card-animate:hover {
  transform: translateY(calc(-1.5rem / 5));
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.card-primary {
  color: #fff;
  background-color: #438eff;
}
.card-primary .card-header, .card-primary .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-primary .card-title {
  color: #fff;
}

.card-secondary {
  color: #fff;
  background-color: #8561f9;
}
.card-secondary .card-header, .card-secondary .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-secondary .card-title {
  color: #fff;
}

.card-success {
  color: #fff;
  background-color: #2dcb73;
}
.card-success .card-header, .card-success .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-success .card-title {
  color: #fff;
}

.card-info {
  color: #fff;
  background-color: #4ab0c1;
}
.card-info .card-header, .card-info .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-info .card-title {
  color: #fff;
}

.card-warning {
  color: #fff;
  background-color: #f6b749;
}
.card-warning .card-header, .card-warning .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-warning .card-title {
  color: #fff;
}

.card-danger {
  color: #fff;
  background-color: #ff6c6c;
}
.card-danger .card-header, .card-danger .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-danger .card-title {
  color: #fff;
}

.card-light {
  color: #fff;
  background-color: #eff2f7;
}
.card-light .card-header, .card-light .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-light .card-title {
  color: #fff;
}

.card-dark {
  color: #fff;
  background-color: #141821;
}
.card-dark .card-header, .card-dark .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-color: transparent;
}
.card-dark .card-title {
  color: #fff;
}

.card-border-primary {
  border-color: #438eff !important;
}
.card-border-primary .card-header, .card-border-primary .card-footer {
  border-color: #438eff;
}

.card-border-secondary {
  border-color: #8561f9 !important;
}
.card-border-secondary .card-header, .card-border-secondary .card-footer {
  border-color: #8561f9;
}

.card-border-success {
  border-color: #2dcb73 !important;
}
.card-border-success .card-header, .card-border-success .card-footer {
  border-color: #2dcb73;
}

.card-border-info {
  border-color: #4ab0c1 !important;
}
.card-border-info .card-header, .card-border-info .card-footer {
  border-color: #4ab0c1;
}

.card-border-warning {
  border-color: #f6b749 !important;
}
.card-border-warning .card-header, .card-border-warning .card-footer {
  border-color: #f6b749;
}

.card-border-danger {
  border-color: #ff6c6c !important;
}
.card-border-danger .card-header, .card-border-danger .card-footer {
  border-color: #ff6c6c;
}

.card-border-light {
  border-color: #eff2f7 !important;
}
.card-border-light .card-header, .card-border-light .card-footer {
  border-color: #eff2f7;
}

.card-border-dark {
  border-color: #141821 !important;
}
.card-border-dark .card-header, .card-border-dark .card-footer {
  border-color: #141821;
}

.card-light {
  background-color: var(--tb-light);
}
.card-light .card-header, .card-light .card-footer {
  color: var(--tb-body-color) !important;
  background-color: rgba(var(--tb-dark-rgb), 0.1);
}
.card-light .card-title, .card-light .card-text {
  color: var(--tb-body-color) !important;
}

.stretched-link {
  position: static;
}

.card-preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--tb-light-rgb), 0.6);
  z-index: 9999;
}

.card-status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

.custom-loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.card-overlay {
  position: relative;
  overflow: hidden;
}
.card-overlay:before {
  content: "";
  background-color: rgba(67, 142, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.card-overlay .card-header, .card-overlay .card-footer {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.card-toolbar-menu {
  line-height: 0.8;
}
.card-toolbar-menu a {
  font-size: 17px;
}
.card-toolbar-menu .minimize-card .plus {
  display: none;
}
.card-toolbar-menu .minimize-card .minus {
  display: block;
}
.card-toolbar-menu .minimize-card.collapsed .plus {
  display: block;
}
.card-toolbar-menu .minimize-card.collapsed .minus {
  display: none;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  font-size: 15px;
  line-height: 15px;
  content: "\f0140";
  font-family: "Material Design Icons";
}

.dropdown-menu {
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  z-index: 1000;
}
.dropdown-menu.dropdown-megamenu {
  padding: 20px;
  left: 0 !important;
  right: 0 !important;
}
.dropdown-menu[data-popper-placement=top-start] {
  animation-name: DropDownSlideDown;
}

@keyframes DropDownSlide {
  100% {
    margin-top: -1px;
  }
  0% {
    margin-top: 8px;
  }
}
@keyframes DropDownSlideDown {
  100% {
    margin-bottom: 0;
  }
  0% {
    margin-bottom: 8px;
  }
}
@media (min-width: 600px) {
  .dropdown-menu-xl {
    width: 420px;
  }
  .dropdown-menu-lg {
    width: 320px;
  }
  .dropdown-menu-md {
    width: 240px;
  }
}
.dropdown-toggle-split {
  border-left: none;
}
.dropdown-toggle-split::after {
  margin-left: 0px;
}
.dropdown-toggle-split:before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.12);
  top: -var(--tb-border-width);
  bottom: -var(--tb-border-width);
  right: -var(--tb-border-width);
  left: 0;
  border-radius: 0 var(--tb-border-radius) var(--tb-border-radius) 0;
}

.dropdown-mega {
  position: static !important;
}

.dropdown-mega-menu-xl {
  width: 38rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

[dir=ltr] .dropdown-menu-start {
  --bs-position: end;
}
[dir=ltr] .dropdown-menu-end {
  --bs-position: start;
}

.dropdown-head .nav-tabs-custom {
  border: 0;
}
.dropdown-head .nav-tabs-custom .nav-link {
  color: var(--tb-secondary-color);
}
.dropdown-head .nav-tabs-custom .nav-link.active {
  background-color: var(--tb-dropdown-bg);
}
.dropdown-head .nav-tabs-custom .nav-link:hover {
  color: #141821;
}

.dropdownmenu-primary .dropdown-item:focus, .dropdownmenu-primary .dropdown-item:hover, .dropdownmenu-primary .dropdown-item.active {
  background-color: rgba(67, 142, 255, 0.07);
  color: #438eff;
}

.dropdownmenu-secondary .dropdown-item:focus, .dropdownmenu-secondary .dropdown-item:hover, .dropdownmenu-secondary .dropdown-item.active {
  background-color: rgba(133, 97, 249, 0.07);
  color: #8561f9;
}

.dropdownmenu-success .dropdown-item:focus, .dropdownmenu-success .dropdown-item:hover, .dropdownmenu-success .dropdown-item.active {
  background-color: rgba(45, 203, 115, 0.07);
  color: #2dcb73;
}

.dropdownmenu-info .dropdown-item:focus, .dropdownmenu-info .dropdown-item:hover, .dropdownmenu-info .dropdown-item.active {
  background-color: rgba(74, 176, 193, 0.07);
  color: #4ab0c1;
}

.dropdownmenu-warning .dropdown-item:focus, .dropdownmenu-warning .dropdown-item:hover, .dropdownmenu-warning .dropdown-item.active {
  background-color: rgba(246, 183, 73, 0.07);
  color: #f6b749;
}

.dropdownmenu-danger .dropdown-item:focus, .dropdownmenu-danger .dropdown-item:hover, .dropdownmenu-danger .dropdown-item.active {
  background-color: rgba(255, 108, 108, 0.07);
  color: #ff6c6c;
}

.dropdownmenu-light .dropdown-item:focus, .dropdownmenu-light .dropdown-item:hover, .dropdownmenu-light .dropdown-item.active {
  background-color: rgba(239, 242, 247, 0.07);
  color: #eff2f7;
}

.dropdownmenu-dark .dropdown-item:focus, .dropdownmenu-dark .dropdown-item:hover, .dropdownmenu-dark .dropdown-item.active {
  background-color: rgba(20, 24, 33, 0.07);
  color: #141821;
}

.nav-tabs-custom {
  border-bottom: 1px solid var(--tb-border-color);
}
.nav-tabs-custom .nav-item {
  position: relative;
}
.nav-tabs-custom .nav-item .nav-link {
  border: none;
}
.nav-tabs-custom .nav-item .nav-link::after {
  content: "";
  background: #438eff;
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: all 250ms ease 0s;
  transform: scale(0);
}
.nav-tabs-custom .nav-item .nav-link.active, .nav-tabs-custom .nav-item .nav-link:hover {
  color: #438eff;
}
.nav-tabs-custom .nav-item .nav-link.active:after, .nav-tabs-custom .nav-item .nav-link:hover:after {
  transform: scale(1);
}
.nav-tabs-custom.card-header-tabs {
  margin-top: -1rem;
}
.nav-tabs-custom.card-header-tabs .nav-link {
  padding: 1.2rem 1.2rem;
}

.vertical-nav .nav .nav-link {
  padding: 24px 16px;
  text-align: center;
  margin-bottom: 8px;
}
.vertical-nav .nav .nav-link .nav-icon {
  font-size: 24px;
}

.navtab-bg li > a {
  background-color: #e9ebec;
  margin: 0 5px;
}

.arrow-navtabs .nav-item .nav-link {
  position: relative;
  text-align: center;
}
.arrow-navtabs .nav-item .nav-link:before {
  content: "";
  position: absolute;
  border: 6px solid transparent;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .arrow-navtabs .nav-item .nav-link:before {
    transition: none;
  }
}
.arrow-navtabs .nav-item .nav-link.active:before {
  border-top-color: #438eff;
}

.custom-hover-nav-tabs .nav-item {
  text-align: center;
  overflow: hidden;
}
.custom-hover-nav-tabs .nav-item .nav-link {
  width: 120px;
  height: 45px;
  position: relative;
  border-radius: 0px;
}
.custom-hover-nav-tabs .nav-item .nav-link .nav-icon {
  font-size: 22px;
}
.custom-hover-nav-tabs .nav-item .nav-link .nav-titl {
  font-size: 14px;
}
.custom-hover-nav-tabs .nav-item .nav-link.active .nav-tab-position {
  color: #fff;
}
.custom-hover-nav-tabs .nav-item .nav-link .nav-tab-position {
  position: absolute;
  left: 0;
  right: 0;
  transition: all 0.4s;
}
.custom-hover-nav-tabs .nav-item .nav-link .nav-tab-position.nav-icon {
  top: 50%;
  transform: translateY(-50%);
}
.custom-hover-nav-tabs .nav-item .nav-link .nav-tab-position.nav-titl {
  bottom: -20px;
}
.custom-hover-nav-tabs .nav-item .nav-link:hover .nav-titl {
  bottom: 50%;
  transform: translateY(50%);
}
.custom-hover-nav-tabs .nav-item .nav-link:hover .nav-icon {
  top: -20px;
}

.custom-verti-nav-pills .nav-link {
  background-color: var(--tb-light);
  margin-top: 7px;
  position: relative;
}
@media (min-width: 992px) {
  .custom-verti-nav-pills .nav-link::before {
    content: "";
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    border: 12px solid transparent;
    border-left-color: transparent;
    transition: border-left-color 0.04 ease;
  }
  .custom-verti-nav-pills .nav-link.active::before {
    border-left-color: #438eff;
  }
}

.animation-nav li {
  position: relative;
}
.animation-nav li a {
  color: var(--tb-body-color);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: all 0.4s;
  z-index: 1;
}
.animation-nav li a span {
  position: relative;
}
.animation-nav li a::before {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  right: 0;
  height: 100%;
  transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
  background-color: #438eff;
  z-index: -1;
}
.animation-nav li a:hover, .animation-nav li a.active {
  color: #fff;
  background-color: transparent !important;
}
.animation-nav li a:hover::before, .animation-nav li a.active::before {
  width: 100%;
  left: 0;
}

.nav-border-top .nav-link {
  border-top: 3px solid transparent;
}
.nav-border-top .nav-link.active {
  border-top-color: #438eff;
}

.nav-border-top-primary .nav-link.active {
  color: #438eff;
  border-top-color: #438eff;
}

.nav-border-top-secondary .nav-link.active {
  color: #8561f9;
  border-top-color: #8561f9;
}

.nav-border-top-success .nav-link.active {
  color: #2dcb73;
  border-top-color: #2dcb73;
}

.nav-border-top-info .nav-link.active {
  color: #4ab0c1;
  border-top-color: #4ab0c1;
}

.nav-border-top-warning .nav-link.active {
  color: #f6b749;
  border-top-color: #f6b749;
}

.nav-border-top-danger .nav-link.active {
  color: #ff6c6c;
  border-top-color: #ff6c6c;
}

.nav-border-top-light .nav-link.active {
  color: #eff2f7;
  border-top-color: #eff2f7;
}

.nav-border-top-dark .nav-link.active {
  color: #141821;
  border-top-color: #141821;
}

.nav-custom {
  background-color: #438eff;
  border-radius: calc(0.3rem * 2);
}
.nav-custom .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.nav-custom .nav-item .nav-link.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.nav-custom.nav-custom-light .nav-item .nav-link {
  color: rgba(var(--tb-body-rgb), 0.75);
}
.nav-custom.nav-custom-light .nav-item .nav-link.active {
  color: #eff2f7;
  background-color: #438eff;
}

.nav-custom-primary {
  background-color: #438eff;
}

.nav-custom-secondary {
  background-color: #8561f9;
}

.nav-custom-success {
  background-color: #2dcb73;
}

.nav-custom-info {
  background-color: #4ab0c1;
}

.nav-custom-warning {
  background-color: #f6b749;
}

.nav-custom-danger {
  background-color: #ff6c6c;
}

.nav-custom-light {
  background-color: #eff2f7;
}

.nav-custom-dark {
  background-color: #141821;
}

.nav-custom-light {
  background-color: var(--tb-light);
}

.nav-primary .nav-link.active {
  color: #fff;
  background-color: #438eff;
}
.nav-primary.nav-tabs .nav-link.active {
  color: #438eff;
  background-color: var(--tb-secondary-bg);
}
.nav-primary.nav-tabs-custom .nav-link.active, .nav-primary.nav-tabs-custom .nav-link:hover {
  color: #438eff;
  background-color: var(--tb-secondary-bg);
}
.nav-primary.nav-tabs-custom .nav-link.active::after, .nav-primary.nav-tabs-custom .nav-link:hover::after {
  background-color: #438eff;
}
.nav-primary.arrow-navtabs .nav-link.active::before {
  border-top-color: #438eff;
}
.nav-primary.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #438eff;
}

.nav-secondary .nav-link.active {
  color: #fff;
  background-color: #8561f9;
}
.nav-secondary.nav-tabs .nav-link.active {
  color: #8561f9;
  background-color: var(--tb-secondary-bg);
}
.nav-secondary.nav-tabs-custom .nav-link.active, .nav-secondary.nav-tabs-custom .nav-link:hover {
  color: #8561f9;
  background-color: var(--tb-secondary-bg);
}
.nav-secondary.nav-tabs-custom .nav-link.active::after, .nav-secondary.nav-tabs-custom .nav-link:hover::after {
  background-color: #8561f9;
}
.nav-secondary.arrow-navtabs .nav-link.active::before {
  border-top-color: #8561f9;
}
.nav-secondary.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #8561f9;
}

.nav-success .nav-link.active {
  color: #fff;
  background-color: #2dcb73;
}
.nav-success.nav-tabs .nav-link.active {
  color: #2dcb73;
  background-color: var(--tb-secondary-bg);
}
.nav-success.nav-tabs-custom .nav-link.active, .nav-success.nav-tabs-custom .nav-link:hover {
  color: #2dcb73;
  background-color: var(--tb-secondary-bg);
}
.nav-success.nav-tabs-custom .nav-link.active::after, .nav-success.nav-tabs-custom .nav-link:hover::after {
  background-color: #2dcb73;
}
.nav-success.arrow-navtabs .nav-link.active::before {
  border-top-color: #2dcb73;
}
.nav-success.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #2dcb73;
}

.nav-info .nav-link.active {
  color: #fff;
  background-color: #4ab0c1;
}
.nav-info.nav-tabs .nav-link.active {
  color: #4ab0c1;
  background-color: var(--tb-secondary-bg);
}
.nav-info.nav-tabs-custom .nav-link.active, .nav-info.nav-tabs-custom .nav-link:hover {
  color: #4ab0c1;
  background-color: var(--tb-secondary-bg);
}
.nav-info.nav-tabs-custom .nav-link.active::after, .nav-info.nav-tabs-custom .nav-link:hover::after {
  background-color: #4ab0c1;
}
.nav-info.arrow-navtabs .nav-link.active::before {
  border-top-color: #4ab0c1;
}
.nav-info.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #4ab0c1;
}

.nav-warning .nav-link.active {
  color: #fff;
  background-color: #f6b749;
}
.nav-warning.nav-tabs .nav-link.active {
  color: #f6b749;
  background-color: var(--tb-secondary-bg);
}
.nav-warning.nav-tabs-custom .nav-link.active, .nav-warning.nav-tabs-custom .nav-link:hover {
  color: #f6b749;
  background-color: var(--tb-secondary-bg);
}
.nav-warning.nav-tabs-custom .nav-link.active::after, .nav-warning.nav-tabs-custom .nav-link:hover::after {
  background-color: #f6b749;
}
.nav-warning.arrow-navtabs .nav-link.active::before {
  border-top-color: #f6b749;
}
.nav-warning.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #f6b749;
}

.nav-danger .nav-link.active {
  color: #fff;
  background-color: #ff6c6c;
}
.nav-danger.nav-tabs .nav-link.active {
  color: #ff6c6c;
  background-color: var(--tb-secondary-bg);
}
.nav-danger.nav-tabs-custom .nav-link.active, .nav-danger.nav-tabs-custom .nav-link:hover {
  color: #ff6c6c;
  background-color: var(--tb-secondary-bg);
}
.nav-danger.nav-tabs-custom .nav-link.active::after, .nav-danger.nav-tabs-custom .nav-link:hover::after {
  background-color: #ff6c6c;
}
.nav-danger.arrow-navtabs .nav-link.active::before {
  border-top-color: #ff6c6c;
}
.nav-danger.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #ff6c6c;
}

.nav-light .nav-link.active {
  color: #fff;
  background-color: #eff2f7;
}
.nav-light.nav-tabs .nav-link.active {
  color: #eff2f7;
  background-color: var(--tb-secondary-bg);
}
.nav-light.nav-tabs-custom .nav-link.active, .nav-light.nav-tabs-custom .nav-link:hover {
  color: #eff2f7;
  background-color: var(--tb-secondary-bg);
}
.nav-light.nav-tabs-custom .nav-link.active::after, .nav-light.nav-tabs-custom .nav-link:hover::after {
  background-color: #eff2f7;
}
.nav-light.arrow-navtabs .nav-link.active::before {
  border-top-color: #eff2f7;
}
.nav-light.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #eff2f7;
}

.nav-dark .nav-link.active {
  color: #fff;
  background-color: #141821;
}
.nav-dark.nav-tabs .nav-link.active {
  color: #141821;
  background-color: var(--tb-secondary-bg);
}
.nav-dark.nav-tabs-custom .nav-link.active, .nav-dark.nav-tabs-custom .nav-link:hover {
  color: #141821;
  background-color: var(--tb-secondary-bg);
}
.nav-dark.nav-tabs-custom .nav-link.active::after, .nav-dark.nav-tabs-custom .nav-link:hover::after {
  background-color: #141821;
}
.nav-dark.arrow-navtabs .nav-link.active::before {
  border-top-color: #141821;
}
.nav-dark.custom-verti-nav-pills .nav-link.active::before {
  border-left-color: #141821;
}

.progress-nav {
  position: relative;
  margin-right: 1rem;
  margin-left: 1rem;
}
.progress-nav .progress {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.progress-nav .nav {
  margin-right: -1rem;
  margin-left: -1rem;
  justify-content: space-between;
}
.progress-nav .nav .nav-link {
  width: 2rem;
  height: 2rem;
  background-color: var(--tb-light);
  padding: 0;
  color: var(--tb-body-color);
  font-weight: 500;
}
.progress-nav .nav .nav-link.active, .progress-nav .nav .nav-link.done {
  background-color: #438eff;
  color: #fff;
}

.step-arrow-nav .nav {
  background-color: var(--tb-light);
}
.step-arrow-nav .nav .nav-link {
  border-radius: 0;
  position: relative;
  font-weight: 500;
  color: var(--tb-body-color);
}
.step-arrow-nav .nav .nav-link::before {
  content: "";
  position: absolute;
  border: 7px solid transparent;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
}
.step-arrow-nav .nav .nav-link.done {
  background-color: rgba(67, 142, 255, 0.05);
  color: #438eff;
}
.step-arrow-nav .nav .nav-link.done::before {
  border-left-color: transparent;
}
.step-arrow-nav .nav .nav-link.active {
  background-color: rgba(67, 142, 255, 0.1);
  color: #438eff;
  box-shadow: none;
}
.step-arrow-nav .nav .nav-link.active::before {
  border-left-color: rgba(67, 142, 255, 0.1);
}
.step-arrow-nav .nav .nav-item:last-child .nav-link:before {
  display: none;
}

.vertical-navs-step .nav {
  gap: 16px;
}
.vertical-navs-step .nav .nav-link {
  text-align: left;
  background-color: rgba(var(--tb-light-rgb), 0.4);
  border: 1px solid var(--tb-border-color);
  color: var(--tb-body-color);
}
.vertical-navs-step .nav .nav-link .step-title {
  font-weight: 600;
}
.vertical-navs-step .nav .nav-link .step-icon {
  color: #ff6c6c;
  vertical-align: middle;
  font-weight: 500;
  float: left;
}
.vertical-navs-step .nav .nav-link.active .step-icon, .vertical-navs-step .nav .nav-link.done .step-icon {
  color: #2dcb73;
}
.vertical-navs-step .nav .nav-link.active .step-icon:before, .vertical-navs-step .nav .nav-link.done .step-icon:before {
  content: "\eb80";
}
.vertical-navs-step .nav .nav-link.active {
  border-color: #438eff;
}
.vertical-navs-step .nav .nav-link.done {
  border-color: #2dcb73;
}

.nav-custom-outline.nav .nav-link {
  border: 1px solid transparent;
  border-bottom: 2px solid transparent;
}
.nav-custom-outline.nav .nav-link.active {
  border-color: #438eff;
  background-color: transparent;
  color: #438eff;
}

.nav-primary.nav-custom-outline .nav-link.active {
  color: #438eff;
  border-color: #438eff;
}

.nav-secondary.nav-custom-outline .nav-link.active {
  color: #8561f9;
  border-color: #8561f9;
}

.nav-success.nav-custom-outline .nav-link.active {
  color: #2dcb73;
  border-color: #2dcb73;
}

.nav-info.nav-custom-outline .nav-link.active {
  color: #4ab0c1;
  border-color: #4ab0c1;
}

.nav-warning.nav-custom-outline .nav-link.active {
  color: #f6b749;
  border-color: #f6b749;
}

.nav-danger.nav-custom-outline .nav-link.active {
  color: #ff6c6c;
  border-color: #ff6c6c;
}

.nav-light.nav-custom-outline .nav-link.active {
  color: #eff2f7;
  border-color: #eff2f7;
}

.nav-dark.nav-custom-outline .nav-link.active {
  color: #141821;
  border-color: #141821;
}

.nav-customs.nav {
  padding-left: 34px;
  overflow: hidden;
}
.nav-customs.nav .nav-link {
  position: relative;
  display: block;
  float: right;
  background-color: var(--tb-secondary-bg);
  margin-right: 46px;
  transition: all 0.5s ease;
}
.nav-customs.nav .nav-link::before, .nav-customs.nav .nav-link::after {
  display: block;
  content: " ";
  position: absolute;
  top: -1px;
  bottom: -1px;
  width: 37px;
  background-color: var(--tb-secondary-bg);
  transition: all 0.5s ease;
}
.nav-customs.nav .nav-link::before {
  border-radius: 0 8px 0 0;
  right: -24px;
  transform: skew(30deg, 0deg);
}
.nav-customs.nav .nav-link::after {
  border-radius: 8px 0 0 0;
  left: -24px;
  transform: skew(-30deg, 0deg);
}
.nav-customs.nav .nav-link.active, .nav-customs.nav .nav-link.active:before, .nav-customs.nav .nav-link.active:after {
  background-color: #438eff;
  color: #fff;
}
.nav-customs.nav .nav-link.active {
  z-index: 1;
}

.table > thead {
  border-color: var(--tb-border-color);
}
.table > :not(:first-child) {
  border-top-width: var(--tb-border-width);
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.table-card {
  margin: -1.2rem -1.2rem;
}
.table-card th:first-child,
.table-card td:first-child {
  padding-left: 16px;
}
.table-card th:last-child,
.table-card td:last-child {
  padding-right: 16px;
}
.table-card .table > :not(:first-child) {
  border-top-width: var(--tb-border-width);
}

.border-primary.table > thead {
  border-color: #438eff !important;
}

.table-primary.table > thead {
  border-bottom-color: #76adff !important;
}

.border-secondary.table > thead {
  border-color: #8561f9 !important;
}

.table-secondary.table > thead {
  border-bottom-color: #ab92fb !important;
}

.border-success.table > thead {
  border-color: #2dcb73 !important;
}

.table-success.table > thead {
  border-bottom-color: #52d98e !important;
}

.border-info.table > thead {
  border-color: #4ab0c1 !important;
}

.table-info.table > thead {
  border-bottom-color: #70c1ce !important;
}

.border-warning.table > thead {
  border-color: #f6b749 !important;
}

.table-warning.table > thead {
  border-bottom-color: #f8ca7a !important;
}

.border-danger.table > thead {
  border-color: #ff6c6c !important;
}

.table-danger.table > thead {
  border-bottom-color: #ff9f9f !important;
}

.border-light.table > thead {
  border-color: #eff2f7 !important;
}

.table-light.table > thead {
  border-bottom-color: white !important;
}

.border-dark.table > thead {
  border-color: #141821 !important;
}

.table-dark.table > thead {
  border-bottom-color: #272f41 !important;
}

.table .form-check {
  padding-left: 0px;
  margin-bottom: 0px;
}
.table .form-check .form-check-input {
  margin-left: 0px;
  margin-top: 0px;
  float: none;
  vertical-align: middle;
}
.table .sort {
  position: relative;
}
.table .sort::before {
  content: "\f035d";
  position: absolute;
  right: 0.5rem;
  top: 18px;
  font-size: 0.8rem;
  font-family: "Material Design Icons";
}
.table .sort::after {
  position: absolute;
  right: 0.5rem;
  content: "\f0360";
  font-family: "Material Design Icons";
  font-size: 0.8rem;
  top: 12px;
}

.modal-title {
  font-weight: 600;
}

.modal-dialog:not(.modal-dialog-scrollable) .modal-header {
  padding-bottom: 0;
}
.modal-dialog:not(.modal-dialog-scrollable) .modal-header .btn-close {
  margin-top: -1.25rem 1.25rem;
}
.modal-dialog:not(.modal-dialog-scrollable) .modal-footer {
  padding-top: 0;
}

.modal.fadeInRight .modal-dialog {
  opacity: 0;
  transform: translateX(20%);
  transition: all 0.3s ease-in-out;
}
.modal.fadeInRight.show .modal-dialog {
  opacity: 1;
  transform: translateX(0);
}
.modal.fadeInLeft .modal-dialog {
  animation: fadeInLeft 0.3s ease-in-out;
  transform: translate(-50%, 0);
}
.modal.fadeInLeft.show .modal-dialog {
  transform: none;
}
.modal.fadeInUp .modal-dialog {
  animation: fadeInUp 0.3s ease-in-out;
  transform: translate(0, 30%);
}
.modal.fadeInUp.show .modal-dialog {
  transform: none;
}
.modal.flip {
  perspective: 1300px;
}
.modal.flip .modal-dialog {
  opacity: 0;
  transform: rotateY(-70deg);
  transition: all 0.3s;
}
.modal.flip.show .modal-dialog {
  opacity: 1;
  transform: rotateY(0deg);
}
.modal.zoomIn .modal-dialog {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s ease;
}
.modal.zoomIn.show .modal-dialog {
  opacity: 1;
  transform: scale(1);
}

.modal-dialog-right {
  margin-right: 1.75rem;
}

.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1.75rem);
}
@media (min-width: 576px) {
  .modal-dialog-bottom {
    min-height: calc(100% - 3.5rem);
  }
}

.modal-dialog-bottom-right {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1.75rem);
  margin-right: 1.75rem;
}
@media (min-width: 576px) {
  .modal-dialog-bottom-right {
    min-height: calc(100% - 3.5rem);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.login-modal {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1016%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(64%2c 81%2c 137%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c650.704C122.328%2c648.746%2c159.175%2c473.043%2c255.674%2c397.837C339.724%2c332.333%2c461.529%2c324.924%2c526.449%2c240.421C598.428%2c146.73%2c655.546%2c24.847%2c631.015%2c-90.726C606.666%2c-205.444%2c482.926%2c-263.497%2c401.565%2c-347.958C325.215%2c-427.217%2c275.543%2c-549.012%2c167.826%2c-571.563C60.344%2c-594.065%2c-27.703%2c-482.932%2c-135.163%2c-460.325C-256.336%2c-434.833%2c-401.929%2c-509.651%2c-497.972%2c-431.495C-592.807%2c-354.321%2c-579.865%2c-206.886%2c-595.603%2c-85.635C-611.133%2c34.016%2c-656.761%2c169.183%2c-588.884%2c268.934C-520.854%2c368.909%2c-362.458%2c340.324%2c-260.989%2c406.106C-158.875%2c472.306%2c-121.679%2c652.651%2c0%2c650.704' fill='%2333416e'%3e%3c/path%3e%3cpath d='M1440 995.672C1519.728 984.741 1563.12 899.779 1626.466 850.1469999999999 1682.6390000000001 806.135 1756.261 782.602 1791.2939999999999 720.431 1827.571 656.052 1835.537 577.6610000000001 1820.814 505.247 1806.518 434.933 1753.2640000000001 383.16999999999996 1710.941 325.228 1664.475 261.614 1634.992 175.16000000000003 1560.657 149.07999999999998 1485.96 122.87299999999999 1402.146 155.543 1332.03 192.289 1269.541 225.038 1232.754 287.251 1189.969 343.347 1149.925 395.849 1115.781 448.9 1089.96 509.672 1056 589.599 988.9680000000001 671.1659999999999 1015.557 753.837 1041.91 835.774 1142.714 863.61 1217.498 906.22 1288.388 946.611 1359.167 1006.755 1440 995.672' fill='%234d61a4'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1016'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-size: cover;
  background-position: center;
}

.pagination-separated .page-item .page-link {
  margin-left: 0.35rem;
  border-radius: 0.25rem;
}

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px !important;
  border: none;
  min-width: 32px;
  min-height: 32px;
  text-align: center;
}
.pagination-rounded.pagination-sm .page-link {
  min-width: 25px;
  min-height: 25px;
}

.progress-sm {
  height: 5px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 16px;
}

.custom-progess {
  position: relative;
}
.custom-progess .progress-icon {
  position: absolute;
  top: -12px;
}
.custom-progess .progress-icon .avatar-title {
  background: var(--tb-secondary-bg);
}

.animated-progress {
  position: relative;
}
.animated-progress .progress-bar {
  position: relative;
  border-radius: 6px;
  animation: animate-positive 2s;
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.custom-progress {
  height: 15px;
  padding: 4px;
  border-radius: 30px;
}
.custom-progress .progress-bar {
  position: relative;
  border-radius: 30px;
}
.custom-progress .progress-bar::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 7px;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
}

.progress-label {
  overflow: visible;
}
.progress-label .progress-bar {
  position: relative;
  overflow: visible;
}
.progress-label .progress-bar .label {
  position: absolute;
  top: -25px;
  right: -9px;
  background-color: #438eff;
  color: #fff;
  display: inline-block;
  line-height: 18px;
  padding: 0 4px;
  border-radius: 4px;
}
.progress-label .progress-bar .label:after {
  content: "";
  position: absolute;
  border: 4px solid transparent;
  border-top-color: #438eff;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
}

.progress-step-arrow {
  height: 3.25rem;
}
.progress-step-arrow .progress-bar {
  position: relative;
  overflow: initial;
  font-size: 0.875rem;
  color: #fff;
}
.progress-step-arrow .progress-bar::after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  bottom: 15px;
  right: -20px;
  z-index: 1;
}

.progress-primary .progress-bar {
  background-color: #438eff;
}
.progress-primary .progress-bar::after {
  border-left-color: #438eff;
}
.progress-primary .progress-bar:nth-child(2) {
  background-color: rgba(67, 142, 255, 0.1) !important;
  color: #438eff !important;
}
.progress-primary .progress-bar:nth-child(2)::after {
  border-left-color: rgba(67, 142, 255, 0.1);
}

.progress-secondary .progress-bar {
  background-color: #8561f9;
}
.progress-secondary .progress-bar::after {
  border-left-color: #8561f9;
}
.progress-secondary .progress-bar:nth-child(2) {
  background-color: rgba(133, 97, 249, 0.1) !important;
  color: #8561f9 !important;
}
.progress-secondary .progress-bar:nth-child(2)::after {
  border-left-color: rgba(133, 97, 249, 0.1);
}

.progress-success .progress-bar {
  background-color: #2dcb73;
}
.progress-success .progress-bar::after {
  border-left-color: #2dcb73;
}
.progress-success .progress-bar:nth-child(2) {
  background-color: rgba(45, 203, 115, 0.1) !important;
  color: #2dcb73 !important;
}
.progress-success .progress-bar:nth-child(2)::after {
  border-left-color: rgba(45, 203, 115, 0.1);
}

.progress-info .progress-bar {
  background-color: #4ab0c1;
}
.progress-info .progress-bar::after {
  border-left-color: #4ab0c1;
}
.progress-info .progress-bar:nth-child(2) {
  background-color: rgba(74, 176, 193, 0.1) !important;
  color: #4ab0c1 !important;
}
.progress-info .progress-bar:nth-child(2)::after {
  border-left-color: rgba(74, 176, 193, 0.1);
}

.progress-warning .progress-bar {
  background-color: #f6b749;
}
.progress-warning .progress-bar::after {
  border-left-color: #f6b749;
}
.progress-warning .progress-bar:nth-child(2) {
  background-color: rgba(246, 183, 73, 0.1) !important;
  color: #f6b749 !important;
}
.progress-warning .progress-bar:nth-child(2)::after {
  border-left-color: rgba(246, 183, 73, 0.1);
}

.progress-danger .progress-bar {
  background-color: #ff6c6c;
}
.progress-danger .progress-bar::after {
  border-left-color: #ff6c6c;
}
.progress-danger .progress-bar:nth-child(2) {
  background-color: rgba(255, 108, 108, 0.1) !important;
  color: #ff6c6c !important;
}
.progress-danger .progress-bar:nth-child(2)::after {
  border-left-color: rgba(255, 108, 108, 0.1);
}

.progress-light .progress-bar {
  background-color: #eff2f7;
}
.progress-light .progress-bar::after {
  border-left-color: #eff2f7;
}
.progress-light .progress-bar:nth-child(2) {
  background-color: rgba(239, 242, 247, 0.1) !important;
  color: #eff2f7 !important;
}
.progress-light .progress-bar:nth-child(2)::after {
  border-left-color: rgba(239, 242, 247, 0.1);
}

.progress-dark .progress-bar {
  background-color: #141821;
}
.progress-dark .progress-bar::after {
  border-left-color: #141821;
}
.progress-dark .progress-bar:nth-child(2) {
  background-color: rgba(20, 24, 33, 0.1) !important;
  color: #141821 !important;
}
.progress-dark .progress-bar:nth-child(2)::after {
  border-left-color: rgba(20, 24, 33, 0.1);
}

.popover {
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.custom-blockquote.blockquote {
  padding: 16px;
  border-left: 3px solid;
}
.custom-blockquote.blockquote.blockquote-outline {
  background-color: var(--tb-secondary-bg) !important;
  border: 1px solid;
  border-left: 3px solid;
}

.custom-blockquote.blockquote.blockquote-primary {
  color: #438eff;
  border-color: #438eff;
  background-color: rgba(67, 142, 255, 0.15);
}
.custom-blockquote.blockquote.blockquote-primary .blockquote-footer {
  color: #438eff;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-primary {
  border-color: #438eff;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-primary .blockquote-footer {
  color: #438eff;
}

.custom-blockquote.blockquote.blockquote-secondary {
  color: #8561f9;
  border-color: #8561f9;
  background-color: rgba(133, 97, 249, 0.15);
}
.custom-blockquote.blockquote.blockquote-secondary .blockquote-footer {
  color: #8561f9;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-secondary {
  border-color: #8561f9;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-secondary .blockquote-footer {
  color: #8561f9;
}

.custom-blockquote.blockquote.blockquote-success {
  color: #2dcb73;
  border-color: #2dcb73;
  background-color: rgba(45, 203, 115, 0.15);
}
.custom-blockquote.blockquote.blockquote-success .blockquote-footer {
  color: #2dcb73;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-success {
  border-color: #2dcb73;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-success .blockquote-footer {
  color: #2dcb73;
}

.custom-blockquote.blockquote.blockquote-info {
  color: #4ab0c1;
  border-color: #4ab0c1;
  background-color: rgba(74, 176, 193, 0.15);
}
.custom-blockquote.blockquote.blockquote-info .blockquote-footer {
  color: #4ab0c1;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-info {
  border-color: #4ab0c1;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-info .blockquote-footer {
  color: #4ab0c1;
}

.custom-blockquote.blockquote.blockquote-warning {
  color: #f6b749;
  border-color: #f6b749;
  background-color: rgba(246, 183, 73, 0.15);
}
.custom-blockquote.blockquote.blockquote-warning .blockquote-footer {
  color: #f6b749;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-warning {
  border-color: #f6b749;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-warning .blockquote-footer {
  color: #f6b749;
}

.custom-blockquote.blockquote.blockquote-danger {
  color: #ff6c6c;
  border-color: #ff6c6c;
  background-color: rgba(255, 108, 108, 0.15);
}
.custom-blockquote.blockquote.blockquote-danger .blockquote-footer {
  color: #ff6c6c;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-danger {
  border-color: #ff6c6c;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-danger .blockquote-footer {
  color: #ff6c6c;
}

.custom-blockquote.blockquote.blockquote-light {
  color: #eff2f7;
  border-color: #eff2f7;
  background-color: rgba(239, 242, 247, 0.15);
}
.custom-blockquote.blockquote.blockquote-light .blockquote-footer {
  color: #eff2f7;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-light {
  border-color: #eff2f7;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-light .blockquote-footer {
  color: #eff2f7;
}

.custom-blockquote.blockquote.blockquote-dark {
  color: #141821;
  border-color: #141821;
  background-color: rgba(20, 24, 33, 0.15);
}
.custom-blockquote.blockquote.blockquote-dark .blockquote-footer {
  color: #141821;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-dark {
  border-color: #141821;
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-dark .blockquote-footer {
  color: #141821;
}

.custom-blockquote.blockquote.blockquote-dark {
  color: var(--tb-dark);
  border-color: var(--tb-dark);
  background-color: rgba(var(--tb-dark-rgb), 0.15);
}
.custom-blockquote.blockquote.blockquote-dark .blockquote-footer {
  color: var(--tb-dark);
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-dark {
  border-color: var(--tb-dark);
}
.custom-blockquote.blockquote.blockquote-outline.blockquote-dark .blockquote-footer {
  color: var(--tb-dark);
}

.form-check {
  position: relative;
  text-align: left;
}
.form-check .form-check-input {
  cursor: pointer;
}

.form-check-primary .form-check-input:checked {
  background-color: #438eff;
  border-color: #438eff;
}

.form-radio-primary .form-check-input:checked {
  border-color: #438eff;
  background-color: #438eff;
}
.form-radio-primary .form-check-input:checked:after {
  background-color: #438eff;
}

.form-check-secondary .form-check-input:checked {
  background-color: #8561f9;
  border-color: #8561f9;
}

.form-radio-secondary .form-check-input:checked {
  border-color: #8561f9;
  background-color: #8561f9;
}
.form-radio-secondary .form-check-input:checked:after {
  background-color: #8561f9;
}

.form-check-success .form-check-input:checked {
  background-color: #2dcb73;
  border-color: #2dcb73;
}

.form-radio-success .form-check-input:checked {
  border-color: #2dcb73;
  background-color: #2dcb73;
}
.form-radio-success .form-check-input:checked:after {
  background-color: #2dcb73;
}

.form-check-info .form-check-input:checked {
  background-color: #4ab0c1;
  border-color: #4ab0c1;
}

.form-radio-info .form-check-input:checked {
  border-color: #4ab0c1;
  background-color: #4ab0c1;
}
.form-radio-info .form-check-input:checked:after {
  background-color: #4ab0c1;
}

.form-check-warning .form-check-input:checked {
  background-color: #f6b749;
  border-color: #f6b749;
}

.form-radio-warning .form-check-input:checked {
  border-color: #f6b749;
  background-color: #f6b749;
}
.form-radio-warning .form-check-input:checked:after {
  background-color: #f6b749;
}

.form-check-danger .form-check-input:checked {
  background-color: #ff6c6c;
  border-color: #ff6c6c;
}

.form-radio-danger .form-check-input:checked {
  border-color: #ff6c6c;
  background-color: #ff6c6c;
}
.form-radio-danger .form-check-input:checked:after {
  background-color: #ff6c6c;
}

.form-check-light .form-check-input:checked {
  background-color: #eff2f7;
  border-color: #eff2f7;
}

.form-radio-light .form-check-input:checked {
  border-color: #eff2f7;
  background-color: #eff2f7;
}
.form-radio-light .form-check-input:checked:after {
  background-color: #eff2f7;
}

.form-check-dark .form-check-input:checked {
  background-color: #141821;
  border-color: #141821;
}

.form-radio-dark .form-check-input:checked {
  border-color: #141821;
  background-color: #141821;
}
.form-radio-dark .form-check-input:checked:after {
  background-color: #141821;
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

.form-check-right {
  padding-left: 0;
  display: inline-block;
}
.form-check-right .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.6em;
}
.form-check-right .form-check-label {
  display: block;
}
.form-check-right.form-switch .form-check-input {
  margin-right: -2.56em;
}

.form-check-outline.form-check-primary .form-check-input:checked[type=checkbox] {
  color: #438eff;
  background-color: transparent;
  border-color: #438eff;
}

.form-check-outline.form-check-secondary .form-check-input:checked[type=checkbox] {
  color: #8561f9;
  background-color: transparent;
  border-color: #8561f9;
}

.form-check-outline.form-check-success .form-check-input:checked[type=checkbox] {
  color: #2dcb73;
  background-color: transparent;
  border-color: #2dcb73;
}

.form-check-outline.form-check-info .form-check-input:checked[type=checkbox] {
  color: #4ab0c1;
  background-color: transparent;
  border-color: #4ab0c1;
}

.form-check-outline.form-check-warning .form-check-input:checked[type=checkbox] {
  color: #f6b749;
  background-color: transparent;
  border-color: #f6b749;
}

.form-check-outline.form-check-danger .form-check-input:checked[type=checkbox] {
  color: #ff6c6c;
  background-color: transparent;
  border-color: #ff6c6c;
}

.form-check-outline.form-check-light .form-check-input:checked[type=checkbox] {
  color: #eff2f7;
  background-color: transparent;
  border-color: #eff2f7;
}

.form-check-outline.form-check-dark .form-check-input:checked[type=checkbox] {
  color: #141821;
  background-color: transparent;
  border-color: #141821;
}

.form-check-outline .form-check-input {
  position: relative;
}
.form-check-outline .form-check-input:checked[type=checkbox] {
  background-image: none;
}
.form-check-outline .form-check-input:checked[type=checkbox]::before {
  content: "\f012c";
  font-family: "Material Design Icons";
  top: -2px;
  position: absolute;
  font-weight: 700;
  font-size: 11px;
  left: 1px;
}

.form-radio-outline.form-radio-primary .form-check-input:checked[type=radio] {
  color: #438eff;
  background-color: transparent;
  border-color: #438eff;
}

.form-radio-outline.form-radio-secondary .form-check-input:checked[type=radio] {
  color: #8561f9;
  background-color: transparent;
  border-color: #8561f9;
}

.form-radio-outline.form-radio-success .form-check-input:checked[type=radio] {
  color: #2dcb73;
  background-color: transparent;
  border-color: #2dcb73;
}

.form-radio-outline.form-radio-info .form-check-input:checked[type=radio] {
  color: #4ab0c1;
  background-color: transparent;
  border-color: #4ab0c1;
}

.form-radio-outline.form-radio-warning .form-check-input:checked[type=radio] {
  color: #f6b749;
  background-color: transparent;
  border-color: #f6b749;
}

.form-radio-outline.form-radio-danger .form-check-input:checked[type=radio] {
  color: #ff6c6c;
  background-color: transparent;
  border-color: #ff6c6c;
}

.form-radio-outline.form-radio-light .form-check-input:checked[type=radio] {
  color: #eff2f7;
  background-color: transparent;
  border-color: #eff2f7;
}

.form-radio-outline.form-radio-dark .form-check-input:checked[type=radio] {
  color: #141821;
  background-color: transparent;
  border-color: #141821;
}

.form-radio-outline .form-check-input {
  position: relative;
}
.form-radio-outline .form-check-input:checked[type=radio] {
  background-image: none;
}
.form-radio-outline .form-check-input:checked[type=radio]::before {
  content: "\f0765";
  font-family: "Material Design Icons";
  top: 0px;
  position: absolute;
  font-size: 8px;
  left: 2.2px;
}

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 22px;
  line-height: 22px;
}
.form-switch-md .form-check-input {
  width: 40px;
  height: 20px;
  left: -0.5rem;
  position: relative;
}
.form-switch-md .form-check-label {
  vertical-align: middle;
}

.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
}
.form-switch-lg .form-check-input {
  width: 48px;
  height: 24px;
  left: -0.75rem;
  position: relative;
}

.input-group-text {
  margin-bottom: 0px;
}

.form-switch-primary .form-check-input:checked {
  background-color: #438eff;
  border-color: #438eff;
}

.form-switch-custom.form-switch-primary .form-check-input:checked::before {
  color: #438eff;
}

.form-switch-secondary .form-check-input:checked {
  background-color: #8561f9;
  border-color: #8561f9;
}

.form-switch-custom.form-switch-secondary .form-check-input:checked::before {
  color: #8561f9;
}

.form-switch-success .form-check-input:checked {
  background-color: #2dcb73;
  border-color: #2dcb73;
}

.form-switch-custom.form-switch-success .form-check-input:checked::before {
  color: #2dcb73;
}

.form-switch-info .form-check-input:checked {
  background-color: #4ab0c1;
  border-color: #4ab0c1;
}

.form-switch-custom.form-switch-info .form-check-input:checked::before {
  color: #4ab0c1;
}

.form-switch-warning .form-check-input:checked {
  background-color: #f6b749;
  border-color: #f6b749;
}

.form-switch-custom.form-switch-warning .form-check-input:checked::before {
  color: #f6b749;
}

.form-switch-danger .form-check-input:checked {
  background-color: #ff6c6c;
  border-color: #ff6c6c;
}

.form-switch-custom.form-switch-danger .form-check-input:checked::before {
  color: #ff6c6c;
}

.form-switch-light .form-check-input:checked {
  background-color: #eff2f7;
  border-color: #eff2f7;
}

.form-switch-custom.form-switch-light .form-check-input:checked::before {
  color: #eff2f7;
}

.form-switch-dark .form-check-input:checked {
  background-color: #141821;
  border-color: #141821;
}

.form-switch-custom.form-switch-dark .form-check-input:checked::before {
  color: #141821;
}

.form-switch-custom .form-check-input {
  position: relative;
  background-image: none;
}
.form-switch-custom .form-check-input::before {
  content: "\f0765";
  font-family: "Material Design Icons";
  top: -9px;
  position: absolute;
  font-size: 20px;
  left: -3px;
  color: var(--tb-secondary-color);
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch-custom .form-check-input::before {
    transition: none;
  }
}
.form-switch-custom .form-check-input:checked {
  background-image: none;
  background-color: var(--tb-secondary-bg);
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch-custom .form-check-input:checked {
    transition: none;
  }
}
.form-switch-custom .form-check-input:checked::before {
  right: -3px;
  left: auto;
}
.form-switch-custom .form-check-input:focus {
  background-image: none;
}

.form-switch-right {
  display: inline-block;
  padding-right: 0.8em;
  margin-bottom: 0;
  padding-left: 0 !important;
}
.form-switch-right .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.6em;
  margin-top: 0.1em !important;
}
.form-switch-right label {
  margin-bottom: 0;
  margin-right: 1rem;
}

.card-radio {
  padding: 0;
}
.card-radio .form-check-label {
  background-color: var(--tb-secondary-bg);
  border: 1px solid var(--tb-border-color);
  border-radius: 0.25rem;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 32px;
}
.card-radio .form-check-label:hover {
  cursor: pointer;
}
.card-radio .form-check-input {
  display: none;
}
.card-radio .form-check-input:checked + .form-check-label {
  border-color: #438eff !important;
}
.card-radio .form-check-input:checked + .form-check-label:before {
  content: "\eb80";
  font-family: "remixicon";
  position: absolute;
  top: 2px;
  right: 6px;
  font-size: 16px;
  color: #438eff;
}
.card-radio.dark .form-check-input:checked + .form-check-label:before {
  color: #fff;
}

[data-layout-mode=dark] .form-switch .form-check-input, [data-layout-mode=dark] .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ced4da'/%3e%3c/svg%3e");
}

.form-icon {
  position: relative;
}
.form-icon .form-control-icon {
  padding-left: 2.7rem;
  position: relative;
}
.form-icon i {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 18px;
  display: flex;
  align-items: center;
}
.form-icon.right .form-control-icon {
  padding-right: 2.7rem;
  padding-left: 0.9rem;
  position: relative;
}
.form-icon.right i {
  left: auto;
  right: 18px;
}

[data-layout-mode=dark] .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.list-group-fill-primary.list-group-item {
  background-color: #438eff !important;
  border-color: #438eff;
  color: #fff;
}

.list-group-fill-secondary.list-group-item {
  background-color: #8561f9 !important;
  border-color: #8561f9;
  color: #fff;
}

.list-group-fill-success.list-group-item {
  background-color: #2dcb73 !important;
  border-color: #2dcb73;
  color: #fff;
}

.list-group-fill-info.list-group-item {
  background-color: #4ab0c1 !important;
  border-color: #4ab0c1;
  color: #fff;
}

.list-group-fill-warning.list-group-item {
  background-color: #f6b749 !important;
  border-color: #f6b749;
  color: #fff;
}

.list-group-fill-danger.list-group-item {
  background-color: #ff6c6c !important;
  border-color: #ff6c6c;
  color: #000;
}

.list-group-fill-light.list-group-item {
  background-color: #eff2f7 !important;
  border-color: #eff2f7;
  color: #000;
}

.list-group-fill-dark.list-group-item {
  background-color: #141821 !important;
  border-color: #141821;
  color: #fff;
}

.list-group-item {
  margin-bottom: 0px;
}
.list-group-item .list-text {
  color: var(--tb-secondary-color);
}
.list-group-item.active .list-title {
  color: #fff;
}
.list-group-item.active .list-text {
  color: rgba(255, 255, 255, 0.5);
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

[data-simplebar-track=primary] .simplebar-scrollbar:before {
  background: #438eff;
}

[data-simplebar-track=secondary] .simplebar-scrollbar:before {
  background: #8561f9;
}

[data-simplebar-track=success] .simplebar-scrollbar:before {
  background: #2dcb73;
}

[data-simplebar-track=info] .simplebar-scrollbar:before {
  background: #4ab0c1;
}

[data-simplebar-track=warning] .simplebar-scrollbar:before {
  background: #f6b749;
}

[data-simplebar-track=danger] .simplebar-scrollbar:before {
  background: #ff6c6c;
}

[data-simplebar-track=light] .simplebar-scrollbar:before {
  background: #eff2f7;
}

[data-simplebar-track=dark] .simplebar-scrollbar:before {
  background: #141821;
}

/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*=language-],
pre[class*=language-] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*=language-]::-moz-selection,
pre[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection,
code[class*=language-] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*=language-]::selection,
pre[class*=language-] ::selection,
code[class*=language-]::selection,
code[class*=language-] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none;
  }
}
/* Code blocks */
pre[class*=language-] {
  margin: 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: var(--tb-secondary-bg) !important;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #ff6c6c;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  /* This background color was intended by the author of this theme. */
  background: hsla(0deg, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #d63284;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/* Prism editor */
:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #eff2f7;
}

code[class*=language-],
pre[class*=language-] {
  color: #878a99;
  text-shadow: none;
}

.language-markup::-webkit-scrollbar, .language-js::-webkit-scrollbar {
  -webkit-appearance: none;
}
.language-markup::-webkit-scrollbar:vertical, .language-js::-webkit-scrollbar:vertical {
  width: 10px;
}
.language-markup::-webkit-scrollbar:horizontal, .language-js::-webkit-scrollbar:horizontal {
  height: 10px;
}
.language-markup::-webkit-scrollbar-thumb, .language-js::-webkit-scrollbar-thumb {
  background-color: rgba(var(--tb-dark-rgb), 0.1);
  border-radius: 10px;
  border: 2px solid var(--tb-light);
}
.language-markup::-webkit-scrollbar-track, .language-js::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--tb-light);
}

#scroll-top {
  width: 30px;
  height: 30px;
  position: fixed;
  bottom: 65px;
  right: 30px;
  background: #343a40;
  border-color: transparent;
  border-radius: 3px;
  color: #ffffff;
  transition: all 0.5s ease;
}

.btn-clipboard {
  position: absolute !important;
  right: 15px !important;
  z-index: 1 !important;
}

div.code-toolbar > .toolbar {
  opacity: 1 !important;
}
div.code-toolbar > .toolbar button {
  display: inline-block !important;
  margin: 0.375rem 0.5rem !important;
  padding: 0.25rem 0.75rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-radius: 0.2rem !important;
  border: 1px solid rgba(var(--tb-primary-rgb), 0.35) !important;
  background-color: transparent;
  color: var(--tb-primary) !important;
  box-shadow: none !important;
}
div.code-toolbar > .toolbar button:focus {
  outline: none !important;
  box-shadow: none !important;
}
div.code-toolbar > .toolbar button:hover {
  background-color: var(--tb-primary) !important;
  color: #fff !important;
}

.swal2-container .swal2-title {
  padding: 24px 24px 0;
  font-size: 20px;
  font-weight: 500;
}

.swal2-popup {
  padding-bottom: 24px;
  border-radius: var(--tb-border-radius-lg);
  background-color: var(--tb-secondary-bg);
  color: var(--tb-body-color);
}
.swal2-popup .swal2-title {
  color: var(--tb-heading-color);
}
.swal2-popup .swal2-html-container {
  color: var(--tb-body-color);
}

.swal2-footer {
  border-top: 1px solid var(--tb-border-color);
  color: var(--tb-body-color);
}

.swal2-html-container {
  font-size: 16px;
}

.swal2-icon.swal2-question {
  border-color: #4ab0c1;
  color: #4ab0c1;
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #2dcb73;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(45, 203, 115, 0.3);
}
.swal2-icon.swal2-warning {
  border-color: #f6b749;
  color: #f6b749;
}

.swal2-styled:focus {
  box-shadow: none;
}

.swal2-loader {
  border-color: var(--tb-primary) transparent var(--tb-primary) transparent;
}

.swal2-timer-progress-bar {
  background-color: rgba(45, 203, 115, 0.4);
}

.swal2-progress-steps .swal2-progress-step {
  background: var(--tb-primary);
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: var(--tb-primary);
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step, .swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: rgba(var(--tb-primary-rgb), 0.3);
}
.swal2-progress-steps .swal2-progress-step-line {
  background: var(--tb-primary);
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  border-left-color: var(--tb-primary) !important;
  border-right-color: var(--tb-primary) !important;
}

.swal2-file, .swal2-input, .swal2-textarea {
  border: 1px solid var(--tb-border-color);
}
.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  box-shadow: none;
  border-color: #a1c7ff;
}

.swal2-input {
  height: auto;
  display: block;
  padding: 0.6rem 0.9rem;
  font-size: 0.9063rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--tb-body-color);
  background-color: var(--tb-secondary-bg);
  background-clip: padding-box;
  border: var(--tb-border-width) solid var(--tb-border-color);
}

.swal2-close {
  font-family: var(--tb-font-sans-serif);
  font-weight: 300;
  font-size: 28px;
}
.swal2-close:focus {
  box-shadow: none;
}
.swal2-close:hover {
  color: var(--tb-primary);
}

.swal2-validation-message {
  background-color: transparent;
}

.dropzone {
  min-height: 230px;
  border: 2px dashed var(--tb-border-color);
  background: var(--tb-secondary-bg);
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 24px;
  width: 100%;
  margin: 1em 0;
}

.nested-list .list-group-item {
  background-color: rgba(var(--tb-primary-rgb), 0.05);
  border-color: rgba(var(--tb-primary-rgb), 0.05);
}

.nested-list, .nested-1, .nested-2, .nested-3 {
  margin-top: 5px;
}

.nested-sortable-handle .handle {
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}
.nested-sortable-handle .list-group-item {
  padding-left: 42px;
}

.swiper-button-next, .swiper-button-prev {
  height: 32px;
  width: 32px;
  background-color: rgba(var(--tb-primary-rgb), 0.2);
  backdrop-filter: blur(2px);
  border-radius: 8px;
}
.swiper-button-next::after, .swiper-button-prev::after {
  font-family: remixicon;
  font-size: 28px;
  color: rgba(var(--tb-primary-rgb), 1);
  transition: all 0.3s ease;
}

.swiper-button-prev::after {
  content: "\ea64" !important;
}

.swiper-button-next::after {
  content: "\ea6e" !important;
}

.swiper-pagination-bullet {
  width: 22px;
  height: 5px;
  background-color: #fff;
  border-radius: 50px;
}
.swiper-pagination-bullet .swiper-pagination-bullet-active {
  opacity: 1;
}

.dynamic-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: #fff;
  opacity: 0.5;
  transition: all 0.5s ease;
}
.dynamic-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  width: 20px;
}

.swiper-pagination-fraction {
  color: #fff;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
}

.pagination-custom .swiper-pagination-bullet {
  height: 25px;
  width: 25px;
  line-height: 25px;
  border-radius: 8px;
  background-color: #fff;
  opacity: 0.5;
  transition: all 0.5s ease;
}
.pagination-custom .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: #8561f9;
  opacity: 1;
}

.swiper-pagination-progressbar {
  height: 6px !important;
  background-color: rgba(45, 203, 115, 0.25);
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #2dcb73;
}

.swiper-scrollbar {
  background-color: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(2px);
  padding: 1.2px;
  height: 6px !important;
}
.swiper-scrollbar .swiper-scrollbar-drag {
  background-color: #fff;
}

.swiper-pagination-dark .swiper-pagination-bullet {
  background-color: #8561f9;
}
.swiper-pagination-dark .dynamic-pagination .swiper-pagination-bullet {
  background-color: #8561f9;
}
.swiper-pagination-dark.pagination-custom .swiper-pagination-bullet {
  color: #fff;
}
.swiper-pagination-dark.pagination-custom .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
.swiper-pagination-dark.swiper-scrollbar {
  background-color: rgba(20, 24, 33, 0.35);
}

.multi-wrapper {
  border: none;
  position: relative;
}
.multi-wrapper::before {
  content: "\ea61";
  position: absolute;
  font-family: "remixicon";
  left: 50%;
  transform: translateX(-50%);
  bottom: 86px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 50%;
  color: var(--tb-secondary-color);
  background-color: var(--tb-light);
  z-index: 1;
}
.multi-wrapper .non-selected-wrapper {
  border: 1px solid var(--tb-border-color);
  background-color: var(--tb-secondary-bg);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.multi-wrapper .selected-wrapper::-webkit-scrollbar, .multi-wrapper .non-selected-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}
.multi-wrapper .selected-wrapper::-webkit-scrollbar:vertical, .multi-wrapper .non-selected-wrapper::-webkit-scrollbar:vertical {
  width: 10px;
}
.multi-wrapper .selected-wrapper::-webkit-scrollbar:horizontal, .multi-wrapper .non-selected-wrapper::-webkit-scrollbar:horizontal {
  height: 9px;
}
.multi-wrapper .selected-wrapper::-webkit-scrollbar-thumb, .multi-wrapper .non-selected-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(var(--tb-secondary-rgb), 0.2);
  border-radius: 10px;
  border: 2px solid var(--tb-secondary-bg);
}
.multi-wrapper .selected-wrapper::-webkit-scrollbar-track, .multi-wrapper .non-selected-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--tb-secondary-bg);
}
.multi-wrapper .item-group .group-label {
  font-size: 12px;
}
.multi-wrapper .item {
  color: var(--tb-body-color);
}
.multi-wrapper .item:hover {
  background-color: rgba(var(--tb-primary-rgb), 0.1);
}
.multi-wrapper .selected-wrapper {
  border: 1px solid var(--tb-border-color);
  background: var(--tb-secondary-bg);
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.multi-wrapper .search-input {
  flex: 0 0 auto;
  padding: 0.6rem 0.9rem;
  font-size: 0.9063rem;
  color: var(--tb-body-color);
  background-color: var(--tb-secondary-bg);
  border: var(--tb-border-width) solid var(--tb-border-color);
  border-radius: var(--tb-border-radius);
  margin-bottom: 16px;
}
.multi-wrapper .search-input::placeholder {
  color: var(--tb-secondary-color);
}
.multi-wrapper .header {
  font-weight: 600;
  color: var(--tb-body-color);
}

.pcr-app {
  background: var(--tb-secondary-bg);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  border-radius: 4px;
  border: 1px solid #eff2f7;
}

.pickr .pcr-button {
  height: 60px;
  width: 150px;
  border-radius: 0.25rem;
}
.pickr .pcr-button::after, .pickr .pcr-button::before {
  border-radius: 0.25rem;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
  margin-right: 0.75em;
  margin-left: 0;
}
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser, .pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
  margin-left: 0.75em;
  margin-right: 0;
}
.pcr-app[data-theme=monolith] .pcr-result {
  min-width: 100%;
}
.pcr-app .pcr-interaction .pcr-type.active {
  background: var(--tb-primary);
}
.pcr-app .pcr-interaction .pcr-result {
  background-color: var(--tb-secondary-bg);
  color: var(--tb-body-color);
  border: 1px solid var(--tb-border-color);
  border-radius: var(--tb-border-radius);
}
.pcr-app .pcr-interaction input {
  border-radius: var(--tb-border-radius) !important;
}
.pcr-app .pcr-interaction input:focus {
  box-shadow: none;
  background-color: var(--tb-secondary-bg);
  border-color: #a1c7ff;
}
.pcr-app .pcr-interaction .pcr-save {
  background: #2dcb73 !important;
}
.pcr-app .pcr-interaction .pcr-clear, .pcr-app .pcr-interaction .pcr-cancel {
  background: #ff6c6c !important;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  height: auto;
}

.input-step {
  border: 1px solid var(--tb-border-color);
  display: inline-flex;
  overflow: visible;
  height: 37.5px;
  border-radius: var(--tb-border-radius);
  background: var(--tb-secondary-bg);
  padding: 4px;
}
.input-step input {
  width: 4em;
  height: 100%;
  text-align: center;
  border: 0;
  background: transparent;
  color: var(--tb-body-color);
  border-radius: var(--tb-border-radius);
}
.input-step input:focus-visible {
  outline: 0;
}
.input-step button {
  width: 1.4em;
  font-weight: 300;
  height: 100%;
  line-height: 0.1em;
  font-size: 1.4em;
  padding: 0.2em !important;
  background: var(--tb-light);
  color: var(--tb-body-color);
  border: none;
  border-radius: var(--tb-border-radius);
}
.input-step.light {
  background: var(--tb-light);
}
.input-step.light button {
  background-color: var(--tb-secondary-bg);
}
.input-step.light-input {
  background: var(--tb-light);
}
.input-step.light-input input {
  background-color: var(--tb-secondary-bg);
}
.input-step.full-width {
  display: flex;
  width: 100%;
}
.input-step.full-width button {
  flex-shrink: 0;
}
.input-step.full-width input {
  flex-grow: 1;
}

.input-step.step-primary button {
  background-color: #438eff;
  color: #fff;
}

.input-step.step-secondary button {
  background-color: #8561f9;
  color: #fff;
}

.input-step.step-success button {
  background-color: #2dcb73;
  color: #fff;
}

.input-step.step-info button {
  background-color: #4ab0c1;
  color: #fff;
}

.input-step.step-warning button {
  background-color: #f6b749;
  color: #fff;
}

.input-step.step-danger button {
  background-color: #ff6c6c;
  color: #fff;
}

.input-step.step-light button {
  background-color: #eff2f7;
  color: #fff;
}

.input-step.step-dark button {
  background-color: #141821;
  color: #fff;
}

.ck {
  font-family: var(--tb-font-sans-serif) !important;
}
.ck.ck-reset_all, .ck.ck-reset_all * {
  color: var(--tb-body-color) !important;
}
.ck.ck-toolbar {
  background: rgba(var(--tb-light-rgb), 0.75) !important;
}
.ck p {
  margin-bottom: 0;
}
.ck.ck-toolbar {
  border: 1px solid var(--tb-border-color) !important;
}
.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap !important;
}
.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: transparent !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  border-top: 0 !important;
  background-color: var(--tb-secondary-bg) !important;
  border-color: var(--tb-border-color) !important;
  box-shadow: none !important;
}
.ck.ck-dropdown__panel {
  background: var(--tb-secondary-bg) !important;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  border-radius: var(--tb-border-radius-lg);
}
.ck.ck-list {
  background: var(--tb-secondary-bg) !important;
}
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: 0;
  right: auto !important;
}
.ck.ck-editor__editable_inline[dir=ltr] {
  text-align: left !important;
}
.ck.ck-dropdown__panel {
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12) !important;
  border-radius: var(--tb-border-radius-lg) !important;
  border: 1px solid var(--tb-border-color-translucent) !important;
}

.ck.ck-button:focus, .ck.ck-button:active,
a.ck.ck-button:focus,
a.ck.ck-button:active {
  box-shadow: none !important;
  border: 1px solid var(--tb-light) !important;
}
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: var(--tb-light) !important;
}
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background: var(--tb-light) !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 0.25rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ck-editor__editable {
  min-height: 245px !important;
}

.ck[class*=ck-heading_heading] {
  font-weight: 500 !important;
}

.ck.ck-button.ck-on:not(.ck-disabled):hover, .ck.ck-button.ck-on:not(.ck-disabled):active,
a.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):active {
  box-shadow: none !important;
}

.ck.ck-tooltip .ck-tooltip__text {
  background: var(--tb-tertiary-bg) !important;
  color: #eff2f7 !important;
}
.ck.ck-input-text {
  background: var(--tb-secondary-bg) !important;
  border: 1px solid var(--tb-border-color) !important;
}
.ck.ck-input-text:focus {
  border: 1px solid #a1c7ff !important;
  box-shadow: none !important;
}
.ck.ck-balloon-panel {
  background: var(--tb-secondary-bg) !important;
  border: 1px solid var(--tb-border-color-translucent) !important;
}
.ck.ck-balloon-panel[class*=arrow_n]:after {
  border-bottom-color: var(--tb-secondary-bg) !important;
}
.ck.ck-balloon-panel[class*=arrow_n]::before {
  border-bottom-color: var(--tb-border-color-translucent) !important;
}

.ck.ck-labeled-field-view > .ck.ck-labeled-field-view__input-wrapper > .ck.ck-label {
  background: var(--tb-secondary-bg) !important;
}

.ck-editor-reverse .ck-editor {
  display: flex;
  flex-direction: column-reverse;
}
.ck-editor-reverse .ck.ck-editor__main > .ck-editor__editable {
  border: 1px solid var(--tb-border-color) !important;
  border-bottom: 0 !important;
}
.ck-editor-reverse .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck-editor-reverse .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 0.25rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ck-editor-reverse .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck-editor-reverse .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

[dir=rtl] .ck.ck-toolbar > .ck-toolbar__items {
  flex-direction: row-reverse;
}

.gridjs-container {
  color: var(--tb-body-color);
  padding: 0;
  display: block;
}

.gridjs-wrapper {
  box-shadow: none;
  border: 1px solid var(--tb-border-color);
  border-radius: 0px;
}
.gridjs-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
}
.gridjs-wrapper::-webkit-scrollbar:vertical {
  width: 12px;
}
.gridjs-wrapper::-webkit-scrollbar:horizontal {
  height: 12px;
}
.gridjs-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(var(--tb-dark-rgb), 0.075);
  border-radius: 10px;
  border: 2px solid var(--tb-secondary-bg);
}
.gridjs-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--tb-secondary-bg);
}

.gridjs-footer {
  border: none !important;
  padding: 12px 0 0;
}

.gridjs-table {
  width: 100%;
}

.gridjs-tbody, td.gridjs-td {
  background-color: transparent;
}

th.gridjs-th,
td.gridjs-td {
  border: 1px solid var(--tb-border-color);
  padding: 0.75rem 0.6rem;
}

th.gridjs-th {
  border-top: 0;
  color: var(--tb-body-color);
  background-color: rgba(var(--tb-light-rgb), 0.75);
}
th.gridjs-th-sort:focus, th.gridjs-th-sort:hover {
  background-color: rgba(var(--tb-light-rgb), 0.85);
}

.gridjs-head {
  padding-top: 0;
}

.gridjs-footer {
  box-shadow: none;
  border: 1px solid var(--tb-border-color);
  border-top: 0;
  background-color: transparent;
}

.gridjs-summary {
  color: var(--tb-secondary-color);
  margin-top: 8px !important;
}

.gridjs-pagination .gridjs-pages button {
  margin-left: 0.3rem;
  border-radius: 0.25rem !important;
  border: 1px solid var(--tb-border-color);
  background-color: var(--tb-secondary-bg);
  color: var(--tb-link-color);
}
.gridjs-pagination .gridjs-pages button:last-child {
  border-right: 1px solid var(--tb-border-color);
}
.gridjs-pagination .gridjs-pages button:disabled, .gridjs-pagination .gridjs-pages button:hover:disabled, .gridjs-pagination .gridjs-pages button[disabled] {
  color: var(--tb-secondary-color);
  background-color: var(--tb-secondary-bg);
}
.gridjs-pagination .gridjs-pages button:hover {
  background-color: var(--tb-tertiary-bg);
  color: var(--tb-link-hover-color);
}
.gridjs-pagination .gridjs-pages button:focus {
  box-shadow: none;
}
.gridjs-pagination .gridjs-pages button:last-child:hover, .gridjs-pagination .gridjs-pages button:first-child:hover {
  background-color: transparent;
}
.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #438eff;
  color: #fff;
  border-color: #438eff;
  font-weight: 500;
}

.gridjs-search {
  position: relative;
  float: left;
}
.gridjs-search::before {
  content: "\f0d1";
  font-family: "remixicon";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 14px;
  color: var(--tb-secondary-color);
}

input.gridjs-input {
  border-color: var(--tb-border-color);
  background-color: var(--tb-secondary-bg);
  color: var(--tb-body-color);
  line-height: 1.5;
  padding: 0.6rem 0.9rem 0.6rem 2.025rem;
  border-radius: var(--tb-border-radius);
  font-size: 0.9063rem;
}
input.gridjs-input:focus {
  box-shadow: none;
  border-color: #a1c7ff;
  background-color: var(--tb-secondary-bg);
}
input.gridjs-input::placeholder {
  color: var(--tb-secondary-color);
}

th.gridjs-th .gridjs-th-content {
  float: none;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
}

button.gridjs-sort {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 20px;
}

th.gridjs-th-sort .gridjs-th-content {
  width: calc(100% - 10px);
}

button.gridjs-sort-asc, button.gridjs-sort-desc {
  background-size: 7px;
}

.table-card .gridjs-head {
  padding: 16px 16px 5px;
}
.table-card .gridjs-wrapper {
  border-top: 0;
  border-radius: 0;
  border-width: 1px 0;
}
.table-card .gridjs-footer {
  padding: 8px 16px;
}

.gridjs-tr-selected td {
  background-color: var(--tb-tertiary-bg);
}
.gridjs-tr-selected .gridjs-td .gridjs-checkbox[type=checkbox] {
  background-color: #438eff;
  border-color: #438eff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.gridjs-td .gridjs-checkbox {
  width: 1.1em;
  height: 1.1em;
  vertical-align: top;
  background-color: var(--tb-secondary-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--tb-border-width) solid var(--tb-border-color);
  appearance: none;
  color-adjust: exact;
}
.gridjs-td .gridjs-checkbox[type=checkbox] {
  border-radius: 0.25em;
}

.gridjs-border-none td.gridjs-td, .gridjs-border-none th.gridjs-th {
  border-right-width: 0;
  border-left-width: 0;
}

.gridjs-loading-bar {
  background-color: var(--tb-secondary-bg);
}

[data-bs-theme=dark] button.gridjs-sort-neutral, [data-bs-theme=dark] button.gridjs-sort-asc, [data-bs-theme=dark] button.gridjs-sort-desc {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.listjs-pagination {
  margin-bottom: 0;
  justify-content: flex-end;
  gap: 8px;
}
.listjs-pagination li .page {
  display: block;
  padding: 0.375rem 0.75rem;
  color: var(--tb-link-color);
  background-color: var(--tb-secondary-bg);
  border: var(--tb-border-width) solid var(--tb-border-color);
  border-radius: 0.25rem;
}
.listjs-pagination li.active .page {
  color: #fff;
  background-color: #438eff;
  border-color: #438eff;
}

.pagination-wrap {
  user-select: none;
  display: flex;
  align-items: center;
}
.pagination-wrap a {
  text-decoration: none;
  display: inline-block;
}

.pagination-next,
.pagination-prev {
  color: var(--tb-primary);
  font-weight: 500;
  padding: 0.375rem 0.75rem;
  background-color: var(--tb-secondary-bg);
  border: var(--tb-border-width) solid var(--tb-border-color);
  border-radius: 0.25rem;
}
.pagination-next:hover,
.pagination-prev:hover {
  color: #a9cbff;
}
.pagination-next.disabled,
.pagination-prev.disabled {
  color: var(--tb-secondary-color);
  cursor: default;
}
.pagination-next.disabled:hover,
.pagination-prev.disabled:hover {
  color: var(--tb-secondary-color);
}

.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: var(--tb-font-sans-serif) !important;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  color: var(--tb-primary);
  font-family: var(--tb-font-sans-serif) !important;
}

.apexcharts-tooltip {
  border: 1px solid var(--tb-border-color) !important;
  background-color: var(--tb-secondary-bg) !important;
  box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px !important;
}
.apexcharts-tooltip * {
  font-family: var(--tb-font-sans-serif) !important;
  color: #878a99 !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  background-color: rgba(var(--tb-light-rgb), 0.75) !important;
  border-bottom: 1px solid var(--tb-border-color) !important;
}

.apexcharts-tooltip.apexcharts-theme-dark * {
  color: #fff !important;
}

.apexcharts-legend-series {
  font-weight: 500;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: rgba(var(--tb-light-rgb), 0.75);
}

.apexcharts-legend-text {
  color: #878a99 !important;
  font-family: var(--tb-font-sans-serif) !important;
  font-size: 13px !important;
}

.apexcharts-pie-label {
  fill: #fff !important;
}

.apexcharts-title-text, .apexcharts-subtitle-text {
  fill: var(--tb-emphasis-color);
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: var(--tb-font-sans-serif) !important;
  fill: var(--tb-emphasis-color);
}

.apexcharts-yaxis-title {
  font-weight: 500;
}

#dynamicloadedchart-wrap {
  margin: 0px auto;
  max-width: 800px;
  position: relative;
}

.chart-box {
  padding-left: 0;
}

#chart-year,
#chart-quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-left: 0;
  padding-top: 20px;
  background: var(--tb-secondary-bg);
  border: 1px solid var(--tb-border-color);
}

#chart-year {
  float: left;
  position: relative;
  transition: 1s ease transform;
  z-index: 3;
}
#chart-year.chart-quarter-activated {
  transform: translateX(0);
  transition: 1s ease transform;
}

#chart-quarter {
  float: left;
  position: relative;
  z-index: -2;
  transition: 1s ease transform;
}
#chart-quarter.active {
  transition: 1.1s ease-in-out transform;
  transform: translateX(0);
  z-index: 1;
}

@media screen and (min-width: 480px) {
  #chart-year {
    transform: translateX(50%);
  }
  #chart-quarter {
    transform: translateX(-50%);
  }
}
.apexcharts-radar-series polygon, .apexcharts-radar-series line {
  stroke: var(--tb-border-color);
}

.apexcharts-pie circle, .apexcharts-pie line {
  stroke: var(--tb-border-color);
}
.apexcharts-pie text {
  fill: var(--tb-body-color);
}

.apex-charts .apexcharts-xaxis line,
.apex-charts .apexcharts-yaxis line {
  stroke: var(--tb-border-color);
}
.apex-charts .apexcharts-xaxis-tick {
  stroke: var(--tb-border-color);
}
.apex-charts .apexcharts-pie-series path,
.apex-charts .apexcharts-bar-series path {
  stroke: var(--tb-secondary-bg);
}
.apex-charts .apexcharts-radialbar-track path {
  stroke: var(--tb-light);
}
.apex-charts .apexcharts-marker {
  stroke: var(--tb-secondary-bg);
}
.apex-charts .apexcharts-boxPlot-series path {
  stroke: var(--tb-border-color);
}
.apex-charts .apexcharts-treemap rect,
.apex-charts .apexcharts-heatmap-series rect {
  stroke: var(--tb-secondary-bg);
}

.apexcharts-xaxistooltip.apexcharts-theme-light {
  box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px;
  color: var(--tb-emphasis-color);
  background: var(--tb-secondary-bg);
  border: 1px solid var(--tb-border-color);
  font-family: var(--tb-font-sans-serif);
}
.apexcharts-xaxistooltip.apexcharts-theme-light::before {
  border-bottom-color: var(--tb-border-color);
}

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: #f3f6f9;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  background: var(--tb-primary);
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}
.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid var(--tb-primary);
}
.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid var(--tb-primary);
}

.autoComplete_wrapper {
  display: block;
}
.autoComplete_wrapper > input {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.6rem 0.9rem;
  font-size: 0.9063rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--tb-body-color);
  background-color: var(--tb-secondary-bg);
  background-clip: padding-box;
  border: var(--tb-border-width) solid var(--tb-border-color);
  border-radius: var(--tb-border-radius);
  background-image: none;
}
.autoComplete_wrapper > input::placeholder {
  padding: 0 !important;
  color: var(--tb-secondary-color) !important;
  font-size: 0.9063rem !important;
}
.autoComplete_wrapper > input:focus {
  border: var(--tb-border-width) solid #a1c7ff;
  color: var(--tb-body-color);
}
.autoComplete_wrapper > input:hover {
  color: var(--tb-body-color);
}
.autoComplete_wrapper > ul {
  border-radius: 0.25rem;
  border-color: var(--tb-border-color-translucent);
  background-color: var(--tb-secondary-bg);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  padding: 0;
  overflow: auto;
  max-height: 160px;
  margin: 0;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
.autoComplete_wrapper > ul > li {
  font-size: 0.9063rem;
  margin: 0;
  padding: 0.35rem 1.2rem;
  border-radius: 0;
  background-color: var(--tb-secondary-bg);
  color: var(--tb-body-color);
}
.autoComplete_wrapper > ul > li mark {
  color: #ff6c6c;
  font-weight: 600;
  padding: 1px;
}
.autoComplete_wrapper > ul > li[aria-selected=true], .autoComplete_wrapper > ul > li:hover {
  color: var(--tb-body-color);
  background-color: var(--tb-tertiary-bg);
}
.autoComplete_wrapper > ul .no_result {
  padding: 0.7rem 1.2rem;
  font-style: italic;
  font-weight: 500;
}

.jvm-tooltip {
  border-radius: 3px;
  background-color: var(--tb-primary);
  font-family: var(--tb-font-sans-serif);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  padding: 5px 9px;
}

.jvm-container text {
  font-family: var(--tb-font-sans-serif);
  font-size: 0.9063rem;
  fill: var(--tb-gray-700);
}

.jvm-zoom-btn {
  background-color: var(--tb-primary);
}

.leaflet-map {
  height: 300px;
}
.leaflet-map.leaflet-container {
  z-index: 0;
  font-family: "Gantari", sans-serif;
}

.fc td,
.fc th {
  border: var(--tb-border-width) solid var(--tb-border-color);
}

.fc .fc-toolbar h2 {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .fc .fc-toolbar .fc-left,
  .fc .fc-toolbar .fc-right,
  .fc .fc-toolbar .fc-center {
    float: none;
    display: block;
    text-align: center;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc .fc-toolbar .fc-today-button {
    display: none;
  }
}
.fc .fc-toolbar .btn {
  text-transform: capitalize;
}
.fc .fc-col-header-cell {
  background-color: var(--tb-light);
}
.fc .fc-col-header-cell-cushion {
  display: block;
  padding: 8px 4px;
}
.fc .fc-daygrid-day-number {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  margin: 2px;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(var(--tb-primary-rgb), 0.1);
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  background-color: var(--tb-primary);
  color: #fff;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(var(--tb-primary-rgb), 0.1);
}
.fc .fc-timegrid-col.fc-day-today {
  background-color: rgba(var(--tb-primary-rgb), 0.1);
}
.fc .fc-list-event:hover td {
  background: transparent;
}
.fc .fc-list-event-title a {
  color: #fff !important;
}
.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
  width: 100% !important;
}
.fc .fc-scrollgrid-section > * {
  border-left: var(--tb-border-width) solid var(--tb-border-color);
  border-top: var(--tb-border-width) solid var(--tb-border-color);
}
.fc .fc-scrollgrid-section-liquid > td {
  border-top: 0;
}

.fc-theme-bootstrap a:not([href]) {
  color: var(--tb-body-color);
}

.fc-event {
  color: #fff;
}

.fc th.fc-widget-header {
  background: #e9ebec;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: #e9ebec;
}
.fc-unthemed td.fc-today {
  background: #f6f8fa;
}

.fc-button {
  background: var(--tb-secondary-bg);
  border-color: #eff2f7;
  color: #2b313e;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: var(--tb-primary);
  color: #fff;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.fc-event,
.fc-event-dot {
  background-color: var(--tb-primary);
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background-color: var(--tb-primary);
}

.fc-event-title,
.fc-sticky {
  font-weight: 600 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-daygrid-event-dot {
  border-color: #fff !important;
  display: none;
}

.fc-event-time {
  display: none;
}

.fc-event .fc-content {
  color: #fff;
}

#external-events .external-event {
  text-align: left;
  padding: 8px 16px;
  margin: 6px 0;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
  color: #eff2f7;
}

.fc-prev-button,
.fc-next-button {
  position: relative;
  height: 37.5px;
  width: 37.5px;
}
.fc-prev-button::before,
.fc-next-button::before {
  position: absolute;
  font-family: remixicon !important;
  font-size: 20px;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc-prev-button::before {
  content: "\ea64";
}

.fc-next-button::before {
  content: "\ea6e";
}

.fc-toolbar-chunk .btn-group .btn {
  color: #8561f9;
  background-color: rgba(133, 97, 249, 0.15);
  border: none;
  box-shadow: none;
}
.fc-toolbar-chunk .btn-group .btn:hover, .fc-toolbar-chunk .btn-group .btn.active {
  color: #fff;
  background-color: #8561f9;
}
.fc-toolbar-chunk .fc-today-button {
  background-color: #8561f9 !important;
  border: #8561f9;
}

@media (max-width: 575.98px) {
  .fc-toolbar {
    flex-direction: column;
    gap: 16px;
  }
}

#upcoming-event-list .card:last-child {
  margin-bottom: 6px !important;
}

#event-modal .event-details {
  display: none;
}
#event-modal .view-event .event-form {
  display: none;
}
#event-modal .view-event #event-category-tag {
  display: none;
}
#event-modal .view-event .event-details {
  display: block;
}

.fc-daygrid-event-harness .fc-daygrid-event {
  backdrop-filter: blur(4px);
}

.fc-timegrid-event-harness .fc-timegrid-event {
  backdrop-filter: blur(4px);
}

.fc-timegrid-slots table tr {
  border-color: var(--tb-border-color) !important;
}

.fc-list-table {
  border-color: var(--tb-border-color);
}

.fc .fc-list-sticky .fc-list-day > * {
  position: sticky;
  top: 0;
  background: var(--tb-tertiary-bg);
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-primary-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-primary-subtle .fc-event-main {
  color: #438eff !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-primary-subtle .fc-event-title {
  color: #438eff !important;
}

.fc-list-table .fc-list-event.bg-primary-subtle {
  color: #438eff !important;
}
.fc-list-table .fc-list-event.bg-primary-subtle .fc-list-event-title > a {
  color: #438eff !important;
}
.fc-list-table .fc-list-event.bg-primary-subtle .fc-list-event-dot {
  border-color: #438eff;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-secondary-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-secondary-subtle .fc-event-main {
  color: #8561f9 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-secondary-subtle .fc-event-title {
  color: #8561f9 !important;
}

.fc-list-table .fc-list-event.bg-secondary-subtle {
  color: #8561f9 !important;
}
.fc-list-table .fc-list-event.bg-secondary-subtle .fc-list-event-title > a {
  color: #8561f9 !important;
}
.fc-list-table .fc-list-event.bg-secondary-subtle .fc-list-event-dot {
  border-color: #8561f9;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-success-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-success-subtle .fc-event-main {
  color: #2dcb73 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-success-subtle .fc-event-title {
  color: #2dcb73 !important;
}

.fc-list-table .fc-list-event.bg-success-subtle {
  color: #2dcb73 !important;
}
.fc-list-table .fc-list-event.bg-success-subtle .fc-list-event-title > a {
  color: #2dcb73 !important;
}
.fc-list-table .fc-list-event.bg-success-subtle .fc-list-event-dot {
  border-color: #2dcb73;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-info-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-info-subtle .fc-event-main {
  color: #4ab0c1 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-info-subtle .fc-event-title {
  color: #4ab0c1 !important;
}

.fc-list-table .fc-list-event.bg-info-subtle {
  color: #4ab0c1 !important;
}
.fc-list-table .fc-list-event.bg-info-subtle .fc-list-event-title > a {
  color: #4ab0c1 !important;
}
.fc-list-table .fc-list-event.bg-info-subtle .fc-list-event-dot {
  border-color: #4ab0c1;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-warning-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-warning-subtle .fc-event-main {
  color: #f6b749 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-warning-subtle .fc-event-title {
  color: #f6b749 !important;
}

.fc-list-table .fc-list-event.bg-warning-subtle {
  color: #f6b749 !important;
}
.fc-list-table .fc-list-event.bg-warning-subtle .fc-list-event-title > a {
  color: #f6b749 !important;
}
.fc-list-table .fc-list-event.bg-warning-subtle .fc-list-event-dot {
  border-color: #f6b749;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-danger-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-danger-subtle .fc-event-main {
  color: #ff6c6c !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-danger-subtle .fc-event-title {
  color: #ff6c6c !important;
}

.fc-list-table .fc-list-event.bg-danger-subtle {
  color: #ff6c6c !important;
}
.fc-list-table .fc-list-event.bg-danger-subtle .fc-list-event-title > a {
  color: #ff6c6c !important;
}
.fc-list-table .fc-list-event.bg-danger-subtle .fc-list-event-dot {
  border-color: #ff6c6c;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-light-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-light-subtle .fc-event-main {
  color: #eff2f7 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-light-subtle .fc-event-title {
  color: #eff2f7 !important;
}

.fc-list-table .fc-list-event.bg-light-subtle {
  color: #eff2f7 !important;
}
.fc-list-table .fc-list-event.bg-light-subtle .fc-list-event-title > a {
  color: #eff2f7 !important;
}
.fc-list-table .fc-list-event.bg-light-subtle .fc-list-event-dot {
  border-color: #eff2f7;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-title,
.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-main {
  color: #141821 !important;
}

.fc-timegrid-event-harness .fc-timegrid-event.bg-dark-subtle .fc-event-title {
  color: #141821 !important;
}

.fc-list-table .fc-list-event.bg-dark-subtle {
  color: #141821 !important;
}
.fc-list-table .fc-list-event.bg-dark-subtle .fc-list-event-title > a {
  color: #141821 !important;
}
.fc-list-table .fc-list-event.bg-dark-subtle .fc-list-event-dot {
  border-color: #141821;
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-main, .fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle .fc-event-title {
  color: var(--tb-dark) !important;
}

.fc-direction-ltr {
  direction: ltr;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0.75em;
}

.fg-emoji-picker {
  width: 250px !important;
  box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px !important;
  top: auto !important;
  bottom: 130px;
}
.fg-emoji-picker * {
  font-family: var(--tb-font-sans-serif) !important;
  color: var(--tb-body-color) !important;
}
@media (max-width: 991.98px) {
  .fg-emoji-picker {
    left: 14px !important;
    top: auto !important;
    bottom: 118px;
  }
}
.fg-emoji-picker .fg-emoji-picker-container-title {
  color: #141821 !important;
}
.fg-emoji-picker .fg-emoji-picker-search {
  height: 40px !important;
}
.fg-emoji-picker .fg-emoji-picker-search input {
  background-color: var(--tb-secondary-bg) !important;
  color: var(--tb-body-color) !important;
  padding: 0.6rem 0.9rem !important;
  font-size: 0.9063rem !important;
}
.fg-emoji-picker .fg-emoji-picker-search input::placeholder {
  color: var(--tb-secondary-color) !important;
}
.fg-emoji-picker .fg-emoji-picker-search svg {
  fill: var(--tb-body-color) !important;
  right: 11px;
  top: 12px;
}
.fg-emoji-picker .fg-emoji-picker-categories {
  background-color: #eff2f7 !important;
}
.fg-emoji-picker .fg-emoji-picker-categories li.active {
  background-color: rgba(var(--bs-primary-rgb), 0.2);
}
.fg-emoji-picker .fg-emoji-picker-categories a:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.2);
}

.fg-emoji-picker-grid > li:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.2) !important;
}

a.fg-emoji-picker-close-button {
  background-color: #e1e7f0 !important;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
  padding: 50px;
  font-weight: 600;
  --tb-table-accent-bg: var(--tb-secondary-bg);
}

table.dataTable > thead .sorting:before,
table.dataTable > thead .sorting_asc:before,
table.dataTable > thead .sorting_desc:before,
table.dataTable > thead .sorting_asc_disabled:before,
table.dataTable > thead .sorting_desc_disabled:before {
  content: "\f0360";
  position: absolute;
  right: 0.5rem;
  top: 12px;
  font-size: 0.8rem;
  font-family: "Material Design Icons";
}

table.dataTable > thead .sorting:after,
table.dataTable > thead .sorting_asc:after,
table.dataTable > thead .sorting_desc:after,
table.dataTable > thead .sorting_asc_disabled:after,
table.dataTable > thead .sorting_desc_disabled:after {
  content: "\f035d";
  position: absolute;
  right: 0.5rem;
  top: 18px;
  font-size: 0.8rem;
  font-family: "Material Design Icons";
}

table.dataTable > tbody > tr.child span.dtr-title {
  font-weight: 600;
  min-width: 150px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control::before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control::before {
  background-color: var(--tb-primary);
  border-color: var(--tb-secondary-bg);
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent.parent > th.dtr-control::before {
  background-color: #ff6c6c;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td.dtr-control::before {
  background-color: #ff6c6c;
}
table.dataTable > tbody > tr.child span.dtr-title {
  min-width: 135px;
}

.table-card .dataTables_length,
.table-card .dataTables_filter {
  padding: 1.2rem 1.2rem;
  padding-bottom: 0px;
}
.table-card .dataTables_info,
.table-card .dataTables_paginate {
  padding: 1.2rem 1.2rem;
}
.table-card div.dataTables_wrapper .col-md-6 {
  width: 100%;
}
.table-card div.dataTables_wrapper div.dataTables_filter input {
  width: calc(100% - 52px);
}
.table-card div.dataTables_wrapper div.dataTables_filter label {
  display: block;
}

div.dtr-modal div.dtr-modal-display {
  background-color: var(--tb-secondary-bg);
  border-color: var(--tb-border-color);
  box-shadow: none;
  height: 70%;
}
div.dtr-modal div.dtr-modal-content {
  padding: 1.25rem;
}
div.dtr-modal div.dtr-modal-content h2 {
  font-size: 1.132875rem;
  font-weight: 600;
  margin-bottom: 15px;
}
div.dtr-modal div.dtr-modal-content .dtr-details tr td {
  padding: 0.75rem 0.6rem;
}
div.dtr-modal div.dtr-modal-close {
  font-size: 24px;
  top: 9px;
  right: 11px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: var(--tb-secondary-bg);
  border-color: var(--tb-border-color);
}
div.dtr-modal div.dtr-modal-close:hover {
  background-color: var(--tb-light);
}

.dt-buttons button.dt-button,
.dt-buttons div.dt-button,
.dt-buttons a.dt-button,
.dt-buttons input.dt-button {
  border-color: var(--tb-border-color);
  background: var(--tb-light);
  color: var(--tb-body-color);
}

.toastify {
  padding: 12px 16px;
  color: #fff;
  display: inline-block;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.12), 0 10px 36px -4px rgba(77, 96, 232, 0.3);
  background: #2dcb73;
  position: fixed;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
}
.toastify.on {
  opacity: 1;
}

.toast-close {
  opacity: 0.4;
  padding: 0 5px;
  position: relative;
  left: 4px;
  margin-left: 4px;
  border: none;
  background: none;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}
.toastify-left .toast-close {
  left: -4px;
  margin-left: 0;
  margin-right: 4px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  margin: -7px 5px;
  border-radius: 2px;
}

.toastify-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: fit-content;
  max-width: -moz-fit-content;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: fit-content;
  }
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: var(--tb-secondary-bg);
  box-shadow: 1px 0 0 var(--tb-border-color), -1px 0 0 var(--tb-border-color), 0 1px 0 var(--tb-border-color), 0 -1px 0 var(--tb-border-color), 0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 1056;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
  width: 100%;
  box-shadow: none;
}
.flatpickr-calendar.inline .flatpickr-rContainer {
  display: block;
  width: 100%;
}
.flatpickr-calendar.inline .flatpickr-rContainer .flatpickr-days {
  width: 100%;
  border: var(--tb-border-width) solid var(--tb-border-color);
  border-top: none;
  border-radius: 0 0 5px 5px;
}
.flatpickr-calendar.inline .flatpickr-rContainer .flatpickr-days .dayContainer {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.flatpickr-calendar.inline .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day {
  max-width: 100%;
  border-radius: 4px;
}
.flatpickr-calendar.inline .flatpickr-time {
  border: var(--tb-border-width) solid var(--tb-border-color) !important;
  border-radius: var(--tb-border-radius);
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid var(--tb-border-color);
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar::before, .flatpickr-calendar::after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost::before, .flatpickr-calendar.arrowRight::before, .flatpickr-calendar.rightMost::after, .flatpickr-calendar.arrowRight::after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter::before, .flatpickr-calendar.arrowCenter::after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar::before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar::after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop::before, .flatpickr-calendar.arrowTop::after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop::before {
  border-bottom-color: var(--tb-primary);
}
.flatpickr-calendar.arrowTop::after {
  border-bottom-color: var(--tb-primary);
}
.flatpickr-calendar.arrowBottom::before, .flatpickr-calendar.arrowBottom::after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom::before {
  border-top-color: var(--tb-primary);
}
.flatpickr-calendar.arrowBottom::after {
  border-top-color: var(--tb-primary);
}
.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
  background-color: var(--tb-primary);
  border-radius: 5px 5px 0px 0px;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(255, 255, 255, 0.9);
  fill: rgba(255, 255, 255, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: -5px;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(255, 255, 255, 0.9);
  fill: rgba(255, 255, 255, 0.9);
  /*
  /*rtl:begin:ignore*/
  /*
  /*rtl:end:ignore*/
  /*
  /*rtl:begin:ignore*/
  /*
  /*rtl:end:ignore*/
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: rgba(255, 255, 255, 0.9);
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid var(--tb-border-color);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 100%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}
.flatpickr-current-month span.arrowUp::after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 600;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.9);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 600;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: transparent;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
}

.flatpickr-weekdays {
  background-color: var(--tb-primary);
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid var(--tb-border-color);
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: var(--tb-primary);
  color: #fff;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: 500;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: var(--tb-body-color);
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
.flatpickr-day:hover, .flatpickr-day:focus {
  background-color: rgba(var(--tb-light-rgb), 0.7);
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background-color: var(--tb-light);
  border-color: var(--tb-light);
}
.flatpickr-day.today {
  border-color: var(--tb-primary);
  background-color: rgba(var(--tb-primary-rgb), 0.1);
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: var(--tb-primary);
  background-color: rgba(var(--tb-primary-rgb), 0.15);
  color: var(--tb-dark);
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--tb-primary);
  color: #fff;
  border-color: var(--tb-primary);
}
.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 var(--tb-primary);
}
.flatpickr-day.selected.startRange .endRange, .flatpickr-day.startRange.startRange .endRange, .flatpickr-day.endRange.startRange .endRange {
  border-radius: 50px;
}
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 var(--tb-light), 5px 0 0 var(--tb-light);
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(var(--tb-body-color-rgb), 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(var(--tb-body-color-rgb), 0.3);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 var(--tb-primary), 5px 0 0 var(--tb-primary);
}
.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 var(--tb-border-color);
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 35px;
}
.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(var(--tb-body-color-rgb), 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after, .flatpickr-time .numInputWrapper span.arrowDown:after {
  border-bottom-color: var(--tb-border-color);
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: var(--tb-body-color);
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: var(--tb-body-color);
  font-weight: bold;
  width: 2%;
  user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time .flatpickr-am-pm:focus {
  background: rgba(var(--tb-primary-rgb), 0.04);
}

.flatpickr-am-pm:focus, .flatpickr-am-pm:hover,
.numInput:focus,
.numInput:hover,
.numInputWrapper:focus,
.numInputWrapper:hover {
  background-color: transparent;
}

.flatpickr-input[readonly] {
  cursor: pointer;
  background-color: var(--tb-secondary-bg);
}

[data-inline-date=true], [data-time-inline] {
  display: none;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[datepicker-inline],
[timepicker-inline] {
  display: none;
}

[data-input-flag] {
  position: relative;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list {
  max-height: 158px;
  overflow-y: auto;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list::-webkit-scrollbar {
  -webkit-appearance: none;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list::-webkit-scrollbar:vertical {
  width: 8px;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list::-webkit-scrollbar:horizontal {
  height: 10px;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list::-webkit-scrollbar-thumb {
  background-color: var(--tb-tertiary-bg);
  border-radius: 8px;
}
[data-input-flag] .dropdown-menu .dropdown-menu-list::-webkit-scrollbar-track {
  border-radius: 8px;
}

[data-option-flag-img-name] .flag-input {
  padding-left: 45px;
  background-image: url("../images/flags/us.svg");
  background-repeat: no-repeat;
  background-position: 14px 8px;
  background-size: 18px;
}

[data-option-flag-name]::before,
[data-option-flag-img-name]::before {
  content: "\f0140";
  font-family: "Material Design Icons";
  position: absolute;
  right: 0.9rem;
  top: 0.6rem;
  font-size: 16px;
}

[data-option-countrycode=false] .country-codeno {
  display: none;
}

[data-search-input=false] .searchlist-input {
  display: none;
}

.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: rgba(var(--tb-light-rgb), 0.75);
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}
.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 0.25rem;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 0.6rem 0.9rem;
  background-color: var(--tb-secondary-bg);
  border: 1px solid var(--tb-border-color);
  font-size: 0.9063rem;
  border-radius: var(--tb-border-radius);
  color: var(--tb-body-color);
  margin-bottom: 10px;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  left: auto;
  margin-top: -10px;
  margin-right: 25px;
  margin-left: 0;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.5;
}
.choices[data-type*=select-one] .choices__button:hover, .choices[data-type*=select-one] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00bcd4;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]:after {
  content: "\f0140";
  position: absolute;
  border: 0;
  border-color: #141821 transparent transparent;
  right: 11.5px;
  left: auto;
  font-family: "Material Design Icons";
  width: auto;
  height: auto;
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.choices[data-type*=select-one].is-open:after {
  margin-top: 0;
  transform: translateY(-50%) rotate(-180deg);
}
.choices[data-type*=select-multiple] .choices__inner, .choices[data-type*=text] .choices__inner {
  cursor: text;
  padding-right: 0.9rem;
}
.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=select-multiple] .choices__button:focus, .choices[data-type*=text] .choices__button:hover, .choices[data-type*=text] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*=select-multiple] .choices__list--dropdown, .choices[data-type*=text] .choices__list--dropdown {
  padding-bottom: 10px;
}
.choices[data-type*=select-multiple] .choices__list--dropdown .choices__list, .choices[data-type*=text] .choices__list--dropdown .choices__list {
  margin-bottom: 0;
}
.choices[data-type*=select-multiple] .choices__input {
  padding-top: 3px;
}

.input-light .choices__inner {
  background-color: var(--tb-light);
  border: none;
}

.choices__inner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  background-color: var(--tb-secondary-bg);
  padding: 0.25rem 3.6rem 0.1rem 0.5rem;
  border: var(--tb-border-width) solid var(--tb-border-color);
  border-radius: var(--tb-border-radius) !important;
  font-size: 0.9063rem;
  min-height: 37.5px;
  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #a1c7ff;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: initial;
  border-radius: 7px;
  padding: 2px 7px;
  font-size: 11px;
  font-weight: 400;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  margin-top: 2px;
  background-color: var(--tb-primary);
  border: 1px solid var(--tb-primary);
  word-break: break-all;
  box-sizing: border-box;
  color: #fff;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: var(--tb-primary);
  border: 1px solid var(--tb-primary);
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #8561f9;
  border: 1px solid #8561f9;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: var(--tb-secondary-bg);
  border: 1px solid var(--tb-border-color);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  top: 100%;
  margin-top: 0;
  padding: 10px 10px 20px 10px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform: translateY(1px);
}
.choices__list--dropdown.is-active {
  visibility: visible;
  animation-name: DropDownSlide;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--tb-tertiary-bg);
}
.choices__list--dropdown .choices__list {
  margin: 0 -16px -16px;
}
.choices__list--dropdown .has-no-results {
  font-style: italic;
  font-weight: 500;
}
.choices__list--dropdown .choices__item--selectable:after {
  display: none;
}

.is-open .choices__list--dropdown {
  border-color: var(--tb-border-color-translucent);
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.is-flipped .choices__list--dropdown.is-active {
  animation-name: DropDownSlideDown;
  transform: translateY(-1px);
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item {
  position: relative;
  font-size: 0.9063rem;
  padding: 0.35rem 1.2rem 0.35rem 16px;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .choices__list--dropdown .choices__item--selectable.is-highlighted:after {
    opacity: 0.5;
  }
}
.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px 16px;
  border-bottom: 1px solid var(--tb-border-color);
  color: var(--tb-secondary-color);
}

.choices__button {
  text-indent: -9999px;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: var(--tb-secondary-bg);
  color: var(--tb-body-color);
  font-size: 0.9063rem;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 2px 0 2px 2px;
}
.choices__input:focus {
  outline: 0;
}
.choices__input::placeholder {
  color: var(--tb-secondary-color);
}

.choices__placeholder {
  color: var(--tb-secondary-color);
  opacity: 1;
}

[data-bs-theme=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.ts-wrapper .ts-control {
  background-color: var(--tb-secondary-bg);
  border-color: var(--tb-border-color);
  padding: 0.6rem 0.9rem;
}
.ts-wrapper .ts-control input {
  color: var(--tb-body-color);
}
.ts-wrapper .ts-control input::placeholder {
  color: var(--tb-secondary-color);
}
.ts-wrapper.multi.has-items .ts-control {
  padding: calc(0.6rem - 1px) 0.9rem calc(0.6rem - 4px);
}
.ts-wrapper.multi .ts-control > div {
  background-color: var(--tb-light);
  color: var(--tb-body-color);
}
.ts-wrapper.focus .ts-control {
  color: var(--tb-body-color);
  box-shadow: none;
}
.ts-wrapper .ts-control, .ts-wrapper .ts-control input, .ts-wrapper .ts-dropdown {
  color: var(--tb-body-color);
}
.ts-wrapper .ts-control, .ts-wrapper .ts-wrapper.single.input-active .ts-control {
  background-color: var(--tb-secondary-bg) !important;
}
.ts-wrapper .ts-dropdown, .ts-wrapper .ts-dropdown.form-control, .ts-wrapper .ts-dropdown.form-select {
  background-color: var(--tb-secondary-bg);
}
.ts-wrapper .ts-dropdown .active {
  background-color: var(--tb-tertiary-bg);
  color: var(--tb-secondary-color);
}
.ts-wrapper .ts-dropdown .create,
.ts-wrapper .ts-dropdown .no-results,
.ts-wrapper .ts-dropdown .optgroup-header,
.ts-wrapper .ts-dropdown .option {
  padding: 0.35rem 1.2rem;
}
.ts-wrapper .option .title {
  display: block;
}
.ts-wrapper .option .url {
  font-size: 12px;
  display: block;
  color: var(--tb-emphasis-color);
}
.ts-wrapper.contacts .ts-control > div .email {
  opacity: 0.7;
}
.ts-wrapper.contacts .ts-control > div .name + .email {
  margin-left: 5px;
}
.ts-wrapper.contacts .ts-control > div .email:before {
  content: "<";
}
.ts-wrapper.contacts .ts-control > div .email:after {
  content: ">";
}
.ts-wrapper.contacts .ts-dropdown .caption {
  font-size: 12px;
  display: block;
  color: var(--tb-emphasis-color);
}

.noUi-connect {
  background: #2dcb73;
}

.noUi-handle {
  background: #2dcb73;
  border: 2px solid var(--tb-secondary-bg);
  box-shadow: none;
}

.noUi-horizontal {
  height: 4px;
}
.noUi-horizontal .noUi-handle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  right: -10px !important;
  top: -7px;
}
.noUi-horizontal .noUi-handle::before, .noUi-horizontal .noUi-handle::after {
  display: none;
}
.noUi-horizontal .noUi-handle:focus {
  outline: 0;
}

.noUi-pips-horizontal {
  height: 50px;
}

.noUi-tooltip {
  padding: 0.4rem 0.7rem;
  border-color: var(--tb-border-color);
  border-radius: var(--tb-border-radius-sm);
  background-color: var(--tb-secondary-bg);
  color: var(--tb-body-color);
}

.noUi-vertical {
  width: 4px;
}
.noUi-vertical .noUi-handle {
  height: 16px;
  width: 16px;
  right: -8px;
  top: -12px;
  left: auto;
  border-radius: 50%;
}
.noUi-vertical .noUi-handle::before, .noUi-vertical .noUi-handle::after {
  display: none;
}
.noUi-vertical .noUi-handle:focus {
  outline: 0;
}
.noUi-vertical .noUi-origin {
  top: 0;
}

.noUi-value {
  font-size: 12px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 12px;
}

.noUi-value-horizontal {
  padding-top: 4px;
}

.noUi-target {
  box-shadow: none;
  background-color: var(--tb-light);
  border-color: var(--tb-light);
}

.noUi-touch-area:focus {
  outline: 0;
}

#red, #green, #blue {
  margin: 10px;
  display: inline-block;
  height: 200px;
}

#colorpicker {
  height: 240px;
  width: 310px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid var(--tb-border-color);
}

#result {
  margin: 60px 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid var(--tb-border-color);
  box-shadow: 0 0 3px;
  border-radius: 7px;
}

#red .noUi-connect {
  background: #ff6c6c;
}

#green .noUi-connect {
  background: #2dcb73;
}

#blue .noUi-connect {
  background: var(--tb-primary);
}

.form-control.keyboard {
  max-width: 340px !important;
}

.example-val {
  font-size: 12px;
  color: var(--tb-secondary-color);
  display: block;
  margin: 15px 0;
}
.example-val:before {
  content: "Value: ";
  font-size: 12px;
  font-weight: 600;
}

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}

.c-1-color {
  background: #ff6c6c;
}

.c-2-color {
  background: #f6b749;
}

.c-3-color {
  background: #2dcb73;
}

.c-4-color {
  background: var(--tb-primary);
}

.c-5-color {
  background: #8561f9;
}

#slider-toggle {
  height: 50px;
}
#slider-toggle.off .noUi-handle {
  border-color: #ff6c6c;
}

[data-slider-color=primary] .noUi-connect {
  background: #438eff;
}
[data-slider-color=primary] .noUi-handle {
  background: #438eff;
}
[data-slider-color=primary][data-slider-style=border] .noUi-handle, [data-slider-color=primary][data-slider-style=square] .noUi-handle {
  border-color: #438eff;
}

[data-slider-color=secondary] .noUi-connect {
  background: #8561f9;
}
[data-slider-color=secondary] .noUi-handle {
  background: #8561f9;
}
[data-slider-color=secondary][data-slider-style=border] .noUi-handle, [data-slider-color=secondary][data-slider-style=square] .noUi-handle {
  border-color: #8561f9;
}

[data-slider-color=success] .noUi-connect {
  background: #2dcb73;
}
[data-slider-color=success] .noUi-handle {
  background: #2dcb73;
}
[data-slider-color=success][data-slider-style=border] .noUi-handle, [data-slider-color=success][data-slider-style=square] .noUi-handle {
  border-color: #2dcb73;
}

[data-slider-color=info] .noUi-connect {
  background: #4ab0c1;
}
[data-slider-color=info] .noUi-handle {
  background: #4ab0c1;
}
[data-slider-color=info][data-slider-style=border] .noUi-handle, [data-slider-color=info][data-slider-style=square] .noUi-handle {
  border-color: #4ab0c1;
}

[data-slider-color=warning] .noUi-connect {
  background: #f6b749;
}
[data-slider-color=warning] .noUi-handle {
  background: #f6b749;
}
[data-slider-color=warning][data-slider-style=border] .noUi-handle, [data-slider-color=warning][data-slider-style=square] .noUi-handle {
  border-color: #f6b749;
}

[data-slider-color=danger] .noUi-connect {
  background: #ff6c6c;
}
[data-slider-color=danger] .noUi-handle {
  background: #ff6c6c;
}
[data-slider-color=danger][data-slider-style=border] .noUi-handle, [data-slider-color=danger][data-slider-style=square] .noUi-handle {
  border-color: #ff6c6c;
}

[data-slider-color=light] .noUi-connect {
  background: #eff2f7;
}
[data-slider-color=light] .noUi-handle {
  background: #eff2f7;
}
[data-slider-color=light][data-slider-style=border] .noUi-handle, [data-slider-color=light][data-slider-style=square] .noUi-handle {
  border-color: #eff2f7;
}

[data-slider-color=dark] .noUi-connect {
  background: #141821;
}
[data-slider-color=dark] .noUi-handle {
  background: #141821;
}
[data-slider-color=dark][data-slider-style=border] .noUi-handle, [data-slider-color=dark][data-slider-style=square] .noUi-handle {
  border-color: #141821;
}

[data-slider-size=lg].noUi-horizontal {
  height: 12px;
}
[data-slider-size=lg].noUi-horizontal .noUi-handle {
  width: 24px;
  height: 24px;
}

[data-slider-size=md].noUi-horizontal {
  height: 8px;
}
[data-slider-size=md].noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
}

[data-slider-size=sm].noUi-horizontal {
  height: 4px;
}
[data-slider-size=sm].noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
}

[data-slider-style=line].noUi-horizontal .noUi-handle {
  width: 8px;
  border-radius: 4px;
  right: -8px;
}
[data-slider-style=line].noUi-vertical .noUi-handle {
  height: 8px;
  border-radius: 4px;
  top: -3px;
}

[data-slider-style=border] .noUi-handle {
  border-color: #2dcb73;
  background-color: var(--tb-secondary-bg);
}

[data-slider-style=square] .noUi-handle {
  border-radius: 0px;
  transform: rotate(45deg);
  height: 10px;
  width: 10px;
  top: -4px;
  border-color: #2dcb73;
  background-color: var(--tb-secondary-bg);
}

.auth-page-wrapper .auth-card {
  z-index: 1;
}
.auth-page-wrapper .auth-card .auth-image .auth-effect-2,
.auth-page-wrapper .auth-card .auth-image .auth-effect,
.auth-page-wrapper .auth-card .auth-image .auth-effect-3 {
  position: absolute;
  z-index: -1;
}
.auth-page-wrapper .auth-card .auth-image .auth-effect-2 {
  top: 0%;
  right: 0;
  transform: rotate(-45deg);
}
.auth-page-wrapper .auth-card .auth-image .auth-effect {
  top: -25px;
  left: -30px;
}
.auth-page-wrapper .auth-card .auth-image .auth-effect-3 {
  bottom: -25px;
  right: -30px;
}

.auth-page-wrapper-2 .auth-card {
  z-index: 1;
}
.auth-page-wrapper-2 .auth-card .auth-image .auth-effect-2,
.auth-page-wrapper-2 .auth-card .auth-image .auth-effect,
.auth-page-wrapper-2 .auth-card .auth-image .auth-effect-3 {
  position: absolute;
  z-index: -1;
}
.auth-page-wrapper-2 .auth-card .auth-image .auth-effect-2 {
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
}
.auth-page-wrapper-2 .auth-card .auth-image .auth-effect {
  top: -25px;
  left: -30px;
}
.auth-page-wrapper-2 .auth-card .auth-image .auth-effect-3 {
  bottom: -25px;
  right: -30px;
}

.auth-pass-inputgroup input[type=text] + .btn .ri-eye-fill:before {
  content: "\ecb6";
}

.particles-js-canvas-el {
  position: relative;
}

.signin-other-title {
  position: relative;
}
.signin-other-title:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  border-top: 1px dashed var(--tb-border-color);
  top: 10px;
}
.signin-other-title .title {
  display: inline-block;
  position: relative;
  z-index: 9;
  background-color: var(--tb-secondary-bg);
  padding: 2px 16px;
}

.auth-bg-cover {
  background-image: url("../images/auth-one-bg.jpg");
  background-size: cover;
  background-position: left center;
}
.auth-bg-cover > .bg-overlay {
  background: linear-gradient(to left, #0d6efd, #0257d5);
  opacity: 0.9;
}
.auth-bg-cover .footer {
  color: rgba(255, 255, 255, 0.5);
}

#password-contain {
  display: none;
}
#password-contain p {
  padding-left: 13px;
}
#password-contain p.valid {
  color: #2dcb73;
}
#password-contain p.valid::before {
  position: relative;
  left: -8px;
  content: "✔";
}
#password-contain p.invalid {
  color: #ff6c6c;
}
#password-contain p.invalid::before {
  position: relative;
  left: -8px;
  content: "✖";
}

.password-addon {
  z-index: 5;
}

.chat-sidebar {
  width: 80px;
}

.dash-filter-picker {
  min-width: 210px !important;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-sample {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
}

.selling-product .swiper-button-next,
.selling-product .swiper-button-prev {
  top: 34px;
  width: 28px;
  height: 28px;
  background-color: rgba(var(--tb-primary-rgb), 0.1);
  color: var(--tb-primary);
  border-radius: 0.3rem;
  right: 0px !important;
  z-index: 99;
}
.selling-product .swiper-button-prev {
  right: 35px !important;
  left: auto !important;
}

.animation-effect-6 {
  position: absolute;
  top: 10%;
  right: 4%;
  animation: rotating alternate ease-in-out infinite 6s;
}
.animation-effect-6.left {
  left: 4%;
  right: auto;
}

.animation-effect-3 {
  position: absolute;
  top: 40%;
  right: 35%;
  animation: rotating2 alternate ease-in-out infinite 8s;
}
.animation-effect-3.left {
  left: 35%;
  right: auto;
  animation: rotatingleft alternate ease-in-out infinite 8s;
}

.animation-effect-4 {
  position: absolute;
  top: 40%;
  right: 30%;
  animation: rotating4 alternate ease-in-out infinite 7s;
}
.animation-effect-4.left {
  left: 30%;
  right: auto;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
    top: 75%;
  }
  100% {
    transform: rotate(360deg);
    top: 0%;
  }
}
@keyframes rotating4 {
  0% {
    top: 75%;
  }
  100% {
    top: 3%;
  }
}
@keyframes rotating2 {
  0% {
    transform: rotate(0deg);
    top: 45%;
    right: 10%;
  }
  100% {
    transform: rotate(360deg);
    top: 80%;
    right: 45%;
  }
}
@keyframes rotatingleft {
  0% {
    transform: rotate(0deg);
    top: 45%;
    left: 10%;
  }
  100% {
    transform: rotate(360deg);
    top: 80%;
    left: 45%;
  }
}
.timeline {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.timeline-line {
  position: relative;
  width: 50%;
}
.timeline-line .timeline-box {
  padding: 15px 30px;
  position: relative;
}
.timeline-line .timeline-box::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: var(--tb-secondary-bg);
  border: 1px solid var(--tb-border-color);
  border-radius: 16px;
  z-index: 1;
}
@media (max-width: 767px) {
  .timeline-line .timeline-box::after {
    left: -9px;
  }
}
@media (max-width: 425px) {
  .timeline-line .timeline-box::after {
    display: none;
  }
}
.timeline-line .timeline-box::before {
  content: "";
  position: absolute;
  width: 23px;
  height: 1px;
  top: calc(50% - 1px);
  right: 8px;
  background: var(--tb-border-color);
  z-index: 1;
}
@media (max-width: 767px) {
  .timeline-line .timeline-box::before {
    display: none;
  }
}
.timeline-line .timeline-box .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 10px);
  text-align: center;
  font-size: 14px;
  font-style: italic;
  color: var(--tb-secondary-color);
  text-transform: uppercase;
  z-index: 1;
}
.timeline-line .timeline-box .content {
  padding: 30px;
  background: var(--tb-secondary-bg);
  position: relative;
  border: 1px solid var(--tb-border-color);
  border-radius: calc(0.3rem * 2);
}
@media (max-width: 425px) {
  .timeline-line .timeline-box {
    padding: 0 !important;
    padding-top: 15px !important;
    margin-top: 50px !important;
  }
}
.timeline-line:nth-child(1) .timeline-box::after, .timeline-line:nth-child(5n-4) .timeline-box::after {
  border-color: var(--tb-primary);
}
.timeline-line:nth-child(2) .timeline-box::after, .timeline-line:nth-child(5n-3) .timeline-box::after {
  border-color: #2dcb73;
}
.timeline-line:nth-child(3) .timeline-box::after, .timeline-line:nth-child(5n-2) .timeline-box::after {
  border-color: #8561f9;
}
.timeline-line:nth-child(4) .timeline-box::after, .timeline-line:nth-child(5n-1) .timeline-box::after {
  border-color: #4ab0c1;
}
.timeline-line:nth-child(5) .timeline-box::after, .timeline-line:nth-child(5n-0) .timeline-box::after {
  border-color: #f6b749;
}
.timeline-line::after {
  content: "";
  position: absolute;
  width: 1px;
  background: var(--tb-border-color);
  top: 0;
  bottom: 0;
  right: 0;
}
@media (max-width: 425px) {
  .timeline-line::after {
    display: none;
  }
}
.timeline-line:first-child::after {
  top: 50%;
}
.timeline-line:last-child::after {
  bottom: 50%;
}
.timeline-line:nth-child(odd) {
  left: 0;
}
.timeline-line:nth-child(odd) .date {
  right: -105px;
}
@media (max-width: 767px) {
  .timeline-line:nth-child(odd)::after {
    right: 100%;
  }
  .timeline-line:nth-child(odd) .date {
    right: 85%;
  }
}
@media (max-width: 425px) {
  .timeline-line:nth-child(odd) .date {
    right: 35%;
    top: -13px;
  }
}
.timeline-line:nth-child(even) {
  left: 50%;
}
.timeline-line:nth-child(even)::after {
  left: -1px;
}
.timeline-line:nth-child(even)::before {
  left: 8px;
}
.timeline-line:nth-child(even) .date {
  left: -105px;
}
.timeline-line:nth-child(even) .timeline-box::after {
  left: -8px;
}
.timeline-line:nth-child(even) .timeline-box::before {
  left: 8px;
}
.timeline-line:nth-child(even) .timeline-box .content {
  padding: 30px;
  border-radius: calc(0.3rem * 2);
}
@media (max-width: 767px) {
  .timeline-line:nth-child(even) {
    left: 0;
  }
  .timeline-line:nth-child(even) .date {
    left: 3%;
  }
}
@media (max-width: 425px) {
  .timeline-line:nth-child(even) .date {
    left: 35%;
    top: -15px;
  }
}
@media (max-width: 767px) {
  .timeline-line {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .timeline-line .timeline-box {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }
  .timeline-line .timeline-box:nth-child(even) .timeline-line .timeline-box:nth-child(odd) {
    left: 0%;
  }
  .timeline-line .timeline-box:nth-child(even) .timeline-line .timeline-box:nth-child(odd)::after {
    left: 82px;
  }
  .timeline-line .timeline-box:nth-child(even) .timeline-line .timeline-box:nth-child(odd)::before {
    left: 100px;
    border-color: transparent var(--tb-border-color) transparent transparent;
  }
  .timeline-line .timeline-box:nth-child(even) .timeline-line .timeline-box:nth-child(odd) .date {
    right: auto;
    left: 15px;
  }
  .timeline-line .timeline-box:nth-child(even) .timeline-line .timeline-box:nth-child(odd) .icon {
    right: auto;
    left: 146px;
  }
  .timeline-line .timeline-box:nth-child(even) .timeline-line .timeline-box:nth-child(odd) .content {
    padding: 30px 30px 30px 90px;
  }
}
.horizontal-timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.horizontal-timeline::before {
  content: "";
  position: absolute;
  width: 100%;
  top: 174px;
  left: 0;
  height: 2px;
  background-color: var(--tb-border-color);
}
.horizontal-timeline .swiper-slide .item-box {
  margin: 227px 0px 0px;
  background-color: transparent;
  box-shadow: none;
}
.horizontal-timeline .swiper-slide .timeline-content {
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.horizontal-timeline .swiper-slide .timeline-content::before {
  content: "";
  display: block;
  position: absolute;
  background-color: var(--tb-secondary-bg);
  border: 1px dashed;
  border-color: var(--tb-border-color) var(--tb-border-color) transparent transparent;
  width: 20px;
  height: 20px;
  left: 0px;
  top: -10px;
  right: 0;
  margin: 0 auto;
  transform: rotate(-45deg);
}
.horizontal-timeline .swiper-slide .time {
  position: absolute;
  top: -63px;
  right: 0px;
  left: 0px;
  margin: 0px auto;
}
.horizontal-timeline .swiper-slide:nth-child(even) {
  margin-top: 5px;
  transform: rotate(-180deg);
}
.horizontal-timeline .swiper-slide:nth-child(even) .timeline-content {
  transform: rotate(180deg);
}
.horizontal-timeline .swiper-slide:nth-child(even) .timeline-content::before {
  bottom: -10px;
  top: auto;
  transform: rotate(135deg);
}
.horizontal-timeline .swiper-slide:nth-child(even) .time {
  top: -62px;
  transform: rotate(180deg);
}
.horizontal-timeline .swiper-button-next,
.horizontal-timeline .swiper-button-prev {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #5096ff;
}
.horizontal-timeline .swiper-button-next::after,
.horizontal-timeline .swiper-button-prev::after {
  font-size: 24px;
  color: var(--tb-secondary-bg);
}
.horizontal-timeline .swiper-button-next.swiper-button-disabled,
.horizontal-timeline .swiper-button-prev.swiper-button-disabled {
  background-color: rgba(var(--tb-primary-rgb), 0.5);
  opacity: 1;
  cursor: auto;
  backdrop-filter: blur(25px);
  pointer-events: none;
}
.horizontal-timeline .swiper-button-next {
  right: 0;
}
.horizontal-timeline .swiper-button-next::after {
  content: "\ea6e";
  font-family: remixicon;
}
.horizontal-timeline .swiper-button-prev {
  left: 0;
}
.horizontal-timeline .swiper-button-prev::after {
  content: "\ea64";
  font-family: remixicon;
}

.acitivity-timeline {
  position: relative;
  overflow: hidden;
}
.acitivity-timeline .acitivity-item {
  position: relative;
}
.acitivity-timeline .acitivity-item .flex-shrink-0 {
  z-index: 2;
}
.acitivity-timeline .acitivity-item .acitivity-avatar {
  background-color: var(--tb-secondary-bg);
  border: 3px solid var(--tb-secondary-bg);
  height: 32px;
  width: 32px;
}
.acitivity-timeline .acitivity-item:before {
  content: "";
  position: absolute;
  border-left: 1px dashed var(--tb-border-color);
  left: 16px;
  height: 100%;
  top: 5px;
  z-index: 0;
}
.acitivity-timeline .acitivity-item:last-child::before {
  border-color: transparent;
}

.acitivity-timeline-2 {
  border-radius: 12px;
  position: relative;
}
.acitivity-timeline-2 li {
  padding-bottom: 1.5rem;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}
.acitivity-timeline-2 li:last-child {
  border: 0px;
  padding-bottom: 0;
}
.acitivity-timeline-2 li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: var(--tb-secondary-bg);
  border: 1px solid var(--tb-primary);
  box-shadow: 3px 3px 0px #dceaff;
  box-shadow: 3px 3px 0px #dceaff;
  border-radius: 50%;
  position: absolute;
  left: -8px;
  top: 0px;
}

.error-basic-img {
  max-width: 450px;
}

.error-500 .title {
  font-size: 250px;
}
.error-500 .error-500-img {
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .error-500 .title {
    font-size: 150px;
  }
  .error-500 .error-500-img {
    width: 20% !important;
    top: 43px;
  }
}
@media (max-width: 767.98px) {
  .error-500 .title {
    font-size: 68px;
    margin-top: 35px;
  }
  .error-500 .error-500-img {
    position: relative;
    top: 0px;
  }
}

.error-text {
  text-shadow: 4px 4px rgba(45, 203, 115, 0.4);
}
@media (min-width: 1200px) {
  .error-text {
    font-size: 10rem;
  }
}

.profile-wid-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 320px;
}
@media (max-width: 575.98px) {
  .profile-wid-bg {
    height: 445px;
  }
}
.profile-wid-bg .profile-wid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-nav.nav-pills .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.profile-nav.nav-pills .nav-link::before {
  background-color: rgba(255, 255, 255, 0.1);
}

.profile-project-card {
  border: 1px solid var(--tb-border-color);
}

.profile-project-card.profile-project-primary {
  border-left-color: #438eff;
}

.profile-project-card.profile-project-secondary {
  border-left-color: #8561f9;
}

.profile-project-card.profile-project-success {
  border-left-color: #2dcb73;
}

.profile-project-card.profile-project-info {
  border-left-color: #4ab0c1;
}

.profile-project-card.profile-project-warning {
  border-left-color: #f6b749;
}

.profile-project-card.profile-project-danger {
  border-left-color: #ff6c6c;
}

.profile-project-card.profile-project-light {
  border-left-color: #eff2f7;
}

.profile-project-card.profile-project-dark {
  border-left-color: #141821;
}

.user-profile-img {
  position: relative;
}
.user-profile-img .profile-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .user-profile-img .profile-img {
    height: 160px;
  }
}
.user-profile-img .profile-foreground-img-file-input {
  display: none;
}
.user-profile-img .profile-photo-edit {
  cursor: pointer;
}

.profile-user {
  position: relative;
  display: inline-block;
}
.profile-user .profile-photo-edit {
  position: absolute;
  right: 1.2rem;
  left: auto;
  top: 1.2rem;
  cursor: pointer;
}
.profile-user .user-profile-image {
  object-fit: cover;
}
.profile-user .profile-img-file-input {
  display: none;
}

.profile-timeline .accordion-item {
  position: relative;
}
.profile-timeline .accordion-item .accordion-button {
  background-color: transparent;
}
.profile-timeline .accordion-item .accordion-button::after {
  background: none;
}
.profile-timeline .accordion-item::before {
  content: "";
  border-left: 2px dashed var(--tb-border-color);
  position: absolute;
  height: 100%;
  left: 23px;
}
.profile-timeline .accordion-item:first-child::before {
  top: 8px;
}
.profile-timeline .accordion-item:last-child::before {
  height: 20px;
  top: 3px;
}

.profile-setting-img {
  position: relative;
}
.profile-setting-img .overlay-content {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.profile-setting-img .profile-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .profile-setting-img .profile-img {
    height: 160px;
  }
}
.profile-setting-img .profile-foreground-img-file-input {
  display: none;
}
.profile-setting-img .profile-photo-edit {
  cursor: pointer;
}

@media (max-width: 575.98px) {
  [data-layout=horizontal] .profile-foreground {
    margin-top: 0 !important;
  }
}
.profile-basic {
  height: 180px;
}

.sitemap-content {
  width: 100%;
  max-width: 1142px;
  margin: 0 auto;
  padding: 0 20px;
}
.sitemap-content * {
  position: relative;
}

.sitemap-horizontal {
  position: relative;
}
.sitemap-horizontal ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.sitemap-horizontal ul a {
  display: block;
  background: var(--tb-light);
  border: 2px solid var(--tb-secondary-bg);
  box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px;
  font-size: 0.9063rem;
  height: 60px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sitemap-horizontal ul a span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.administration .director > li > a {
  width: 50%;
  margin: 0 auto 0px auto;
  border-radius: 4px;
}
.administration .subdirector {
  position: absolute;
  width: 100%;
}
.administration .subdirector::after {
  content: "";
  display: block;
  width: 0;
  height: 130px;
  border-left: 2px dashed var(--tb-border-color);
  left: 45.45%;
  position: relative;
}
.administration .subdirector > li:first-child {
  width: 18.59%;
  height: 64px;
  margin: 0 auto 92px auto;
  padding-top: 25px;
  border-bottom: 2px dashed var(--tb-border-color);
  z-index: 1;
  float: right;
  right: 27.2%;
  border-left: 2px dashed var(--tb-border-color);
}
.administration .subdirector > li:first-child a {
  width: 100%;
  left: 25px;
}
@media screen and (max-width: 767px) {
  .administration .subdirector > li:first-child {
    width: 40%;
    right: 10%;
    margin-right: 2px;
  }
  .administration .subdirector::after {
    left: 49.8%;
  }
}

.departments {
  width: 100%;
}
.departments > li:first-child {
  width: 18.59%;
  height: 64px;
  margin: 0 auto 92px auto;
  padding-top: 25px;
  border-bottom: 2px dashed var(--tb-border-color);
  z-index: 1;
  float: left;
  left: 27%;
}
.departments > li:first-child a {
  width: 100%;
  right: 25px;
}
.departments > li:nth-child(2) {
  margin-left: 0;
  clear: left;
}
.departments > li:nth-child(2).department:before {
  border: none;
}
.departments::after {
  content: "";
  display: block;
  position: absolute;
  width: 81.1%;
  height: 22px;
  border-top: 2px dashed var(--tb-border-color);
  border-right: 2px dashed var(--tb-border-color);
  border-left: 2px dashed var(--tb-border-color);
  margin: 0 auto;
  top: 130px;
  left: 9.1%;
}
@media screen and (max-width: 767px) {
  .departments > li:first-child {
    width: 40%;
    left: 10%;
    margin-left: 2px;
  }
  .departments::after {
    border-right: none;
    left: 0;
    width: 50%;
  }
}

.department {
  border-left: 2px dashed var(--tb-border-color);
  float: left;
  margin-left: 1.75%;
  margin-bottom: 60px;
  width: 18.25%;
}
.department::before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 22px;
  border-left: 2px dashed var(--tb-border-color);
  z-index: 1;
  top: -22px;
  left: 50%;
  margin-left: -4px;
}
.department > a {
  margin: 0 0 -26px -4px;
  z-index: 1;
}
.department ul {
  margin-top: 0px;
  margin-bottom: 0px;
}
.department ul li {
  padding-left: 25px;
  border-bottom: 2px dashed var(--tb-border-color);
  height: 80px;
}
.department ul li a {
  background: var(--tb-secondary-bg);
  margin-top: 48px;
  position: absolute;
  z-index: 1;
  width: 90%;
  height: 60px;
  vertical-align: middle;
  right: -1px;
  text-align: center;
}
.department:first-child {
  margin-left: 0;
  clear: left;
}
@media screen and (min-width: 768px) {
  .department:last-child:before {
    border: none;
  }
}
@media screen and (max-width: 767px) {
  .department {
    float: none;
    width: 100%;
    margin-left: 0;
  }
  .department::before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 60px;
    border-left: 2px dashed #fff;
    z-index: 1;
    top: -60px;
    left: 0%;
    margin-left: -4px;
  }
  .department:nth-child(2)::before {
    display: none;
  }
}

.hori-sitemap ul {
  padding: 0;
  padding-top: 10px;
  text-align: center;
}
.hori-sitemap ul li {
  position: relative;
}
@media (max-width: 575.98px) {
  .hori-sitemap ul {
    text-align: left;
  }
  .hori-sitemap ul .parent-title a {
    padding-left: 0;
  }
  .hori-sitemap ul .parent-title a:after {
    display: none;
  }
  .hori-sitemap ul .parent-title:before {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .hori-sitemap > ul {
    position: relative;
  }
  .hori-sitemap > ul li {
    padding-top: 10px;
  }
  .hori-sitemap > ul li .second-list,
  .hori-sitemap > ul li .sub-list {
    position: relative;
  }
  .hori-sitemap > ul li .second-list:before,
  .hori-sitemap > ul li .sub-list:before {
    content: "";
    height: calc(100% - 14px);
    border-right: 2px dashed var(--tb-border-color);
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .hori-sitemap > ul li .sub-list:before {
    height: 38%;
  }
  .hori-sitemap > ul li a {
    position: relative;
    padding: 4px 16px 4px 36px;
  }
  .hori-sitemap > ul li a:after {
    content: "";
    width: 24px;
    border-top: 2px dashed var(--tb-border-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .hori-sitemap > ul li ul {
    margin-left: 36px;
  }
}
@media (min-width: 576px) {
  .hori-sitemap ul {
    padding-top: 20px;
  }
  .hori-sitemap ul li {
    padding-top: 30px;
  }
  .hori-sitemap ul li:before {
    content: "";
    height: 24px;
    width: 0;
    border-right: 2px dashed var(--tb-border-color);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0px auto;
  }
  .hori-sitemap ul li:after {
    content: "";
    width: 100%;
    border-top: 2px dashed var(--tb-border-color);
    position: absolute;
    top: 0;
    left: 50%;
  }
  .hori-sitemap ul li:last-of-type:after {
    display: none;
  }
  .hori-sitemap ul li.parent-title::before {
    content: "";
    height: 23px;
    border-right: 2px dashed var(--tb-border-color);
    position: absolute;
    top: 28px;
    left: 0;
  }
  .hori-sitemap ul li.parent-title::after {
    border: none;
  }
  .hori-sitemap ul li .sub-title {
    position: relative;
  }
  .hori-sitemap ul li .sub-title::before {
    content: "";
    height: 21px;
    border-right: 2px dashed var(--tb-border-color);
    position: absolute;
    top: 27px;
    left: 49%;
    margin: 0 auto;
  }
}
.hori-sitemap a {
  color: var(--tb-body-color);
  padding: 4px 0px;
  display: block;
}

.verti-sitemap a {
  color: var(--tb-body-color);
  display: block;
}
.verti-sitemap .parent-title a {
  padding-left: 0;
}
.verti-sitemap .parent-title a:before {
  display: none;
}
.verti-sitemap .parent-title:before {
  display: none;
}
.verti-sitemap .first-list {
  position: relative;
  padding-top: 10px;
}
.verti-sitemap .first-list:before {
  content: "";
  border-left: 2px dashed var(--tb-border-color);
  position: absolute;
  top: 0;
  height: 100%;
  bottom: 0;
  left: 0;
}
.verti-sitemap .first-list .list-wrap a, .verti-sitemap .first-list li a {
  position: relative;
  padding: 10px 16px 4px 36px;
}
.verti-sitemap .first-list .list-wrap a::before, .verti-sitemap .first-list li a::before {
  content: "";
  width: 24px;
  border-top: 2px dashed var(--tb-border-color);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.verti-sitemap .first-list .second-list, .verti-sitemap .first-list .third-list {
  margin-left: 42px;
}
.verti-sitemap .first-list .third-list, .verti-sitemap .first-list .second-list {
  position: relative;
}
.verti-sitemap .first-list .third-list li, .verti-sitemap .first-list .second-list li {
  position: relative;
}
.verti-sitemap .first-list .third-list li:before, .verti-sitemap .first-list .second-list li:before {
  content: "";
  height: 100%;
  border-left: 2px dashed var(--tb-border-color);
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px auto;
}
.verti-sitemap .first-list .third-list li:last-child::before, .verti-sitemap .first-list .second-list li:last-child::before {
  height: 13px;
}
.verti-sitemap .first-list:last-child::before {
  height: 25px;
}

.treemap-elem {
  margin: 1em;
  padding: 0;
  list-style: none;
}
.treemap-elem ul {
  padding: 0;
  list-style: none;
}
.treemap-elem input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.treemap-elem input ~ ul {
  display: none;
}
.treemap-elem input:checked ~ ul {
  display: block;
}
.treemap-elem li {
  line-height: 1.2;
  position: relative;
  padding: 0 0 1em 2em;
}
.treemap-elem ul li {
  padding: 1.5em 0 0 2em;
}
.treemap-elem ul li li {
  padding-top: 1em;
}
.treemap-elem > li:last-child {
  padding-bottom: 0;
}
.treemap-elem .elem-label {
  position: relative;
  display: inline-block;
}
.treemap-elem label.elem-label {
  cursor: pointer;
}
.treemap-elem label.elem-label:hover {
  color: var(--tb-primary);
}
.treemap-elem label.elem-label:before {
  background-color: var(--tb-primary);
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 1em 0 -3em;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  font-family: bootstrap-icons;
  content: "\f4fe";
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.treemap-elem [type=checkbox]:checked ~ label.elem-label:before {
  content: "\f2ea";
}
.treemap-elem[treemap-icon=arrow] label.elem-label:before {
  content: "\f231";
}
.treemap-elem[treemap-icon=arrow] [type=checkbox]:checked ~ label.elem-label:before {
  content: "\f229";
}
.treemap-elem li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.5em;
  display: block;
  width: 0;
  border-left: 1px dashed var(--tb-border-color);
  content: "";
}
.treemap-elem .elem-label:after {
  position: absolute;
  top: 0;
  left: -2.5em;
  display: block;
  height: 0.5em;
  width: 2.1em;
  border-bottom: 1px dashed var(--tb-border-color);
  border-left: 1px dashed var(--tb-border-color);
  border-radius: 0 0 0 7px;
  content: "";
}
.treemap-elem label.elem-label:after {
  border-bottom: 0;
  border-top: 1px dashed var(--tb-border-color);
  top: 0.5em;
}
.treemap-elem [type=checkbox]:checked ~ label.elem-label:after {
  border-radius: 0 7px 0 0;
  border-top: 1px dashed var(--tb-border-color);
  border-right: 1px dashed var(--tb-border-color);
  border-bottom: 0;
  border-left: 0;
  bottom: 0;
  top: 0.5em;
  height: auto;
}
.treemap-elem li:last-child:before {
  height: 1.25em;
  bottom: auto;
}
.treemap-elem > li:last-child:before {
  display: none;
}

.treemap-elem[treemap-color=primary] label.elem-label:before {
  background-color: #438eff;
}

.treemap-elem[treemap-color=secondary] label.elem-label:before {
  background-color: #8561f9;
}

.treemap-elem[treemap-color=success] label.elem-label:before {
  background-color: #2dcb73;
}

.treemap-elem[treemap-color=info] label.elem-label:before {
  background-color: #4ab0c1;
}

.treemap-elem[treemap-color=warning] label.elem-label:before {
  background-color: #f6b749;
}

.treemap-elem[treemap-color=danger] label.elem-label:before {
  background-color: #ff6c6c;
}

.treemap-elem[treemap-color=light] label.elem-label:before {
  background-color: #eff2f7;
}

.treemap-elem[treemap-color=dark] label.elem-label:before {
  background-color: #141821;
}

.treemap-elem[treemap-color=light] label.elem-label:before {
  background-color: var(--tb-secondary-bg);
  color: var(--tb-primary);
}

.team-box .team-cover, .profile-offcanvas .team-cover, .modal-team-cover {
  display: none;
  position: relative;
  margin-bottom: -140px;
}
.team-box .team-cover img, .profile-offcanvas .team-cover img, .modal-team-cover img {
  height: 140px;
  width: 100%;
  object-fit: cover;
}
.team-box .team-cover::before, .profile-offcanvas .team-cover::before, .modal-team-cover::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #004ec3, #438eff);
  opacity: 0.6;
}

.team-list.grid-view-filter {
  flex-flow: row wrap;
}
.team-list.grid-view-filter .col {
  flex: 0 0 auto;
  width: 25%;
}
.team-list.grid-view-filter .team-box {
  overflow: hidden;
}
.team-list.grid-view-filter .team-box .team-row {
  align-items: start;
}
.team-list.grid-view-filter .team-box .team-row .col {
  width: 100%;
}
.team-list.grid-view-filter .team-box .team-cover {
  display: block;
}
.team-list.grid-view-filter .team-box .team-settings .col {
  width: 50% !important;
  flex: 0 0 auto;
}
.team-list.grid-view-filter .team-box .team-settings .btn-star {
  color: #fff;
}
.team-list.grid-view-filter .team-box .team-settings .dropdown > a {
  color: #fff;
}
.team-list.grid-view-filter .team-box .team-profile-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 36px;
}
.team-list.grid-view-filter .team-box .team-profile-img .avatar-lg {
  font-size: 22px;
}
.team-list.grid-view-filter .team-box .team-profile-img .team-content {
  margin-left: 0px;
  margin-top: 25px;
  text-align: center;
}
.team-list.grid-view-filter .team-box .view-btn {
  width: 100%;
  margin-top: 25px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .team-list.grid-view-filter .col {
    flex: 0 0 auto;
    width: 33.33%;
  }
}
@media (max-width: 1199.98px) {
  .team-list.grid-view-filter .col {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .team-list.grid-view-filter .col {
    flex: 0 0 auto;
    width: 100%;
  }
}
.team-list.list-view-filter {
  flex-direction: column;
}
.team-list.list-view-filter .team-box {
  margin-bottom: 10px;
}
.team-list.list-view-filter .team-box .team-row {
  align-items: center;
  justify-content: space-between;
}
.team-list.list-view-filter .team-box .team-profile-img {
  display: flex;
  align-items: center;
}
.team-list.list-view-filter .team-box .team-profile-img .avatar-lg {
  height: 4rem;
  width: 4rem;
  font-size: 16px;
}
.team-list.list-view-filter .team-box .team-profile-img .team-content {
  margin-left: 15px;
}
.team-list.list-view-filter .team-box .team-settings {
  width: auto;
  flex: 0 0 auto;
  -webkit-box-ordinal-group: 7;
  order: 6;
}
.team-list.list-view-filter .team-box .btn-star {
  color: var(--tb-primary);
}
@media (max-width: 767.98px) {
  .team-list.list-view-filter {
    flex-direction: row;
  }
  .team-list.list-view-filter .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .team-list.list-view-filter .team-box .team-settings {
    width: 100%;
    flex: 0 0 auto;
    order: -1;
    margin-bottom: 10px;
  }
  .team-list.list-view-filter .team-box .team-settings .col {
    width: 50%;
  }
  .team-list.list-view-filter .team-box .team-profile-img {
    margin-bottom: 25px;
  }
  .team-list.list-view-filter .team-box .view-btn {
    width: 100%;
    margin-top: 25px;
  }
}

.modal-team-cover {
  display: block;
}

.list-grid-nav .nav-link.active {
  background-color: #4ab0c1;
  color: #fff;
}

.profile-offcanvas .team-cover {
  margin-bottom: -132px;
  display: block;
  z-index: -1;
}
.profile-offcanvas .btn-star {
  color: #fff;
}
.profile-offcanvas .dropdown > a {
  color: #fff;
}

.countdownlist {
  text-align: center;
  display: flex;
  gap: 24px;
}
.countdownlist .countdownlist-item {
  width: 25%;
}
.countdownlist .countdownlist-item:last-of-type .count-num::after {
  display: none;
}
.countdownlist .count-title {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
  color: rgba(var(--tb-dark-rgb), 0.5);
  text-transform: uppercase;
}
.countdownlist .count-num {
  background-color: var(--tb-light);
  padding: 16px 10px;
  position: relative;
  border-radius: calc(0.3rem * 2);
  font-weight: 600;
  font-size: 32px;
}
@media (max-width: 575.98px) {
  .countdownlist .count-num {
    font-size: 18px;
  }
}
.countdownlist .count-num::after {
  content: ":";
  font-size: 20px;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--tb-dark);
}

.move-animation {
  animation: mover 1s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-16px);
  }
}
.countdown-input-subscribe {
  position: relative;
}
.countdown-input-subscribe input {
  height: 50px;
  padding-right: 95px;
}
.countdown-input-subscribe .btn {
  position: absolute;
  right: 4px;
  top: 5px;
}

.search-more-results {
  position: relative;
  overflow: hidden;
}
.search-more-results .nav-icon {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
}
.search-more-results .nav-icon i {
  font-size: 20px;
}
@media (max-width: 767.98px) {
  .search-more-results .nav-icon {
    font-size: 14px;
  }
  .search-more-results .nav-icon i {
    font-size: 18px;
  }
}

.video-list .list-element {
  display: none;
}
.video-list .list-element:nth-child(1) {
  display: block;
}
.video-list .list-element:nth-child(2) {
  display: block;
}
.video-list .list-element:nth-child(3) {
  display: block;
}

.search-voice {
  height: 120px;
  width: 120px;
  line-height: 120px;
  margin: 0px auto;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
  position: relative;
}
.search-voice i {
  line-height: 56px;
  font-size: 30px;
}
.search-voice .voice-wave {
  position: absolute;
  width: 120px;
  height: 120px;
  z-index: -1;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  opacity: 0;
  border-radius: 100px;
  animation: voice-wave 1.8s infinite;
  background-color: var(--tb-light);
}
.search-voice .voice-wave:nth-child(2) {
  animation-delay: 0.3s;
}
.search-voice .voice-wave:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes voice-wave {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.images-menu .swiper-slide {
  width: auto;
  display: inline-block;
}

#remove-actions {
  display: none;
}

.contact-sidebar-menu ul li a {
  display: block;
  padding: 6px 0px;
  color: var(--tb-body-color);
  transition: all 0.5s ease;
}
.contact-sidebar-menu ul li a:hover, .contact-sidebar-menu ul li a.active {
  color: var(--tb-primary);
}

.chat-user-img {
  position: relative;
}
.chat-user-img .user-status {
  width: 10px;
  height: 10px;
  background-color: #adb5bd;
  border-radius: 50%;
  border: 2px solid var(--tb-secondary-bg);
  position: absolute;
  right: 0;
  left: auto;
  bottom: 0;
}
.chat-user-img.online .user-status {
  background-color: #2dcb73;
}
.chat-user-img.away .user-status {
  background-color: #f6b749;
}

.chat-conversation {
  height: calc(100vh - 299px);
}
@media (max-width: 991.98px) {
  .chat-conversation {
    height: calc(100vh - 275px);
  }
}
.chat-conversation .simplebar-content-wrapper {
  display: flex;
  flex-direction: column;
}
.chat-conversation .simplebar-content-wrapper .simplebar-content {
  margin-top: auto;
}
.chat-conversation .chat-conversation-list {
  padding-top: 10px;
  margin-bottom: 0;
}
.chat-conversation .chat-conversation-list > li {
  display: flex;
}
.chat-conversation li:last-of-type .conversation-list {
  margin-bottom: 0;
}
.chat-conversation .chat-list.left .check-message-icon {
  display: none;
}
.chat-conversation .chat-list .message-box-drop {
  visibility: hidden;
}
.chat-conversation .chat-list:hover .message-box-drop {
  visibility: visible;
}
.chat-conversation .chat-avatar {
  margin: 0 16px 0 0;
}
.chat-conversation .chat-avatar img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.chat-conversation .chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 12px;
  width: 100%;
}
.chat-conversation .chat-day-title .title {
  background-color: #fff;
  position: relative;
  font-size: 13px;
  z-index: 1;
  padding: 6px 12px;
  border-radius: 5px;
}
.chat-conversation .chat-day-title:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: rgba(67, 142, 255, 0.2);
  top: 10px;
}
.chat-conversation .chat-day-title .badge {
  font-size: 12px;
}
.chat-conversation .conversation-list {
  margin-bottom: 24px;
  display: inline-flex;
  position: relative;
  align-items: flex-end;
  max-width: 80%;
}
@media (max-width: 575.98px) {
  .chat-conversation .conversation-list {
    max-width: 90%;
  }
}
.chat-conversation .conversation-list .ctext-wrap {
  display: flex;
  margin-bottom: 10px;
}
.chat-conversation .conversation-list .ctext-content {
  word-wrap: break-word;
  word-break: break-word;
}
.chat-conversation .conversation-list .ctext-wrap-content {
  padding: 12px 20px;
  background-color: var(--tb-light);
  position: relative;
  border-radius: 12px 12px 12px 0px;
  box-shadow: rgba(135, 138, 153, 0.1) 0px 5px 20px -6px;
}
@media (max-width: 575.98px) {
  .chat-conversation .conversation-list .ctext-wrap-content .attached-file .attached-file-avatar {
    display: none;
  }
  .chat-conversation .conversation-list .ctext-wrap-content .attached-file .dropdown .dropdown-toggle {
    display: block;
  }
}
.chat-conversation .conversation-list .conversation-name {
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
.chat-conversation .conversation-list .dropdown .dropdown-toggle {
  font-size: 18px;
  padding: 4px;
  color: #878a99;
}
.chat-conversation .conversation-list .dropdown .dropdown-toggle::after {
  display: none;
}
@media (max-width: 575.98px) {
  .chat-conversation .conversation-list .dropdown .dropdown-toggle {
    display: none;
  }
}
.chat-conversation .conversation-list .chat-time {
  font-size: 12px;
  margin-top: 4px;
  text-align: right;
}
.chat-conversation .conversation-list .message-img {
  border-radius: 0.2rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.chat-conversation .conversation-list .message-img .message-img-list {
  position: relative;
}
.chat-conversation .conversation-list .message-img img {
  max-width: 150px;
}
.chat-conversation .conversation-list .message-img .message-img-link {
  position: absolute;
  right: 10px;
  left: auto;
  bottom: 10px;
}
.chat-conversation .conversation-list .message-img .message-img-link li > a {
  font-size: 18px;
  color: #fff;
  display: inline-block;
  line-height: 20px;
  width: 26px;
  height: 24px;
  border-radius: 3px;
  background-color: rgba(20, 24, 33, 0.7);
  text-align: center;
}
.chat-conversation .right {
  justify-content: flex-end;
}
.chat-conversation .right .chat-avatar {
  order: 3;
  margin-right: 0px;
  margin-left: 16px;
}
.chat-conversation .right .chat-time {
  text-align: left;
  color: #878a99;
}
.chat-conversation .right .conversation-list {
  text-align: right;
}
.chat-conversation .right .conversation-list .ctext-wrap {
  justify-content: flex-end;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content {
  order: 2;
  background-color: rgba(var(--tb-primary-rgb), 1);
  color: var(--tb-white);
  text-align: right;
  border-radius: 12px 12px 0px 12px;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content .replymessage-block {
  background-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(var(--vz-success-rgb), 1);
  color: #141821;
}
.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content .replymessage-block .conversation-name {
  color: rgba(var(--vz-success-rgb), 1);
}
.chat-conversation .right .conversation-list .conversation-name {
  justify-content: flex-end;
}
.chat-conversation .right .conversation-list .conversation-name .check-message-icon {
  order: 1;
}
.chat-conversation .right .conversation-list .conversation-name .time {
  order: 2;
}
.chat-conversation .right .conversation-list .conversation-name .name {
  order: 3;
}
.chat-conversation .right .conversation-list .dropdown {
  order: 1;
}
.chat-conversation .right .dot {
  background-color: #141821;
}

.chat-input-feedback {
  display: none;
  position: absolute;
  top: -20px;
  left: 4px;
  font-size: 12px;
  color: #ff6c6c;
}
.chat-input-feedback.show {
  display: block;
}