//
// _vertical.scss
// 

.app-content {
    margin-left: $vertical-menu-width;
    overflow: hidden;

    .content {
        padding: 0 15px 10px 15px;
        margin-top: $header-height;
    }
}

.main-content {
    transition: all .1s ease-out;

    @media (min-width: 768px) {
        margin-left: $vertical-menu-width;
    }
}

.page-content {
    padding: calc(#{$header-height} + #{$top-tagbar-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} * 0.7) $footer-height calc(#{$grid-gutter-width} * 0.5);
}

.navbar-menu {
    width: $vertical-menu-width;
    z-index: $vertical-menu-z-index;
    background: var(--#{$prefix}vertical-menu-bg);
    border-right: 1px solid var(--#{$prefix}vertical-menu-border);
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: $top-tagbar-height;
    padding: 0 0 calc(#{$header-height} + 25px) 0;
    transition: all .1s ease-out;

    .navbar-nav {
        .nav-link {
            display: flex;
            align-items: center;
            padding: $vertical-menu-item-padding-y $vertical-menu-item-padding-x;
            color: var(--#{$prefix}vertical-menu-item-color);
            font-size: $vertical-menu-item-font-size;
            font-family: $vertical-menu-item-font-family;

            &.active {
                color: var(--#{$prefix}vertical-menu-item-active-color);
            }

            &:hover {
                color: var(--#{$prefix}vertical-menu-item-hover-color);
            }

            i {
                display: inline-block;
                min-width: var(--#{$prefix}vertical-menu-item-icon-width);
                font-size: 16px;
                line-height: inherit;
            }

            svg {
                width: 18px;
                margin-right: 0.665rem;
                color: var(--#{$prefix}vertical-menu-item-color);
            }

            .badge {
                margin-left: auto;
                margin-right: -2px;
                z-index: 1;
            }

            &:hover {
                color: var(--#{$prefix}vertical-menu-item-hover-color);
            }

            &[data-bs-toggle=collapse] {
                &:after {
                    display: block;
                    content: "\F0142";
                    font-family: 'Material Design Icons';
                    margin-left: auto;
                    transition: transform .2s;
                    font-size: 1.05rem;
                    position: absolute;
                    right: 18px;
                    color: var(--#{$prefix}vertical-menu-title-color);
                }

                &[aria-expanded=true] {
                    color: var(--#{$prefix}vertical-menu-item-active-color);

                    &:after {
                        transform: rotate(90deg);
                        color: var(--#{$prefix}vertical-menu-item-active-color);
                    }

                    &:before {
                        opacity: 1;
                        background-color: var(--#{$prefix}vertical-menu-item-active-color);
                    }
                }
            }

            &.collapsed {
                &.active {
                    &::after {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .nav-sm {
            padding-left: var(--#{$prefix}vertical-menu-item-icon-width);

            .nav-link {
                padding: $vertical-menu-sub-item-padding-y $vertical-menu-sub-item-padding-x  !important;
                color: var(--#{$prefix}vertical-menu-sub-item-color);
                white-space: none;
                position: relative;
                font-size: $vertical-menu-sub-item-font-size;
                font-family: $vertical-menu-sub-item-font-family;

                &:hover {
                    color: var(--#{$prefix}vertical-menu-sub-item-hover-color);

                }

                &.active {
                    color: var(--#{$prefix}vertical-menu-item-active-color);
                }
            }

            .nav-sm {
                padding-left: 15px;

                .nav-link {

                    &:before {
                        height: 5px;
                        width: 5px;
                        left: 5px;
                        border-radius: 50%;
                        background-color: transparent;
                        border: 1px solid;
                        top: 16px;
                    }
                }
            }
        }
    }

    .btn-vertical-sm-hover {
        color: $text-muted;
        display: none;
    }
}

.navbar-brand-box {
    text-align: center;
    padding: 0 1.3rem;
    transition: all .1s ease-out;

    @media (max-width: 767.98px) {
        display: none;
    }
}

.hamburger-icon {
    width: 20px;
    height: 14px;
    position: relative;
    cursor: pointer;
    display: inline-block;

    span {
        background-color:  var(--#{$prefix}header-item-sub-color);
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width: 100%;
        height: 2px;
        display: block;
        left: 0px;

        &:nth-child(1) {
            top: 0;
            width: 80%;
        }

        &:nth-child(2) {
            top: 6px;
        }

        &:nth-child(3) {
            bottom: 0;
            width: 60%;
        }
    }

    .vertical-menu-btn:hover &:not(.open) {
        span {

            &:nth-child(1) {
                top: -1px;
            }

            &:nth-child(3) {
                bottom: -1px;
            }
        }
    }

    &.open {
        transform: rotate(-90deg);

        span {

            &:nth-child(1) {
                left: 1px;
                top: 5px;
                width: 20px;
                transform: rotate(90deg);
                transition-delay: 150ms;
            }

            &:nth-child(2) {
                left: 3px;
                top: 13px;
                width: 10px;
                transform: rotate(45deg);
                transition-delay: 50ms;
            }

            &:nth-child(3) {
                left: 9px;
                top: 13px;
                width: 10px;
                transform: rotate(-45deg);
                transition-delay: 100ms;
            }
        }
    }
}

.logo {
    line-height: $header-height;

    .logo-sm {
        display: none;
    }
}

.logo-light {
    display: none;
}

[data-layout="vertical"] {
    .app-menu {
        .row {
            margin: 0;

            >* {
                width: 100%;
                padding: 0;
            }
        }

        @media (max-width: 767.98px) {
            margin-left: -100%;
            padding: 10px 0 20px 0;
        }

    }

    .navbar-menu {
        .container-fluid {
            padding: 0;
        }
    }

    .navbar-brand-box {
        @media (max-width: 767.98px) {
            display: none;
        }
    }

    .horizontal-logo {
        display: none;
    }

    /********** sm-hover **********/
    &[data-sidebar-size="sm-hover"] {

        .main-content {
            margin-left: $vertical-menu-width-sm;

            @media (max-width: 767.98px) {
                margin-left: 0;
            }
        }

        .logo {
            span.logo-lg {
                display: none;
            }

            span.logo-sm {
                display: inline-block;
            }
        }

        .btn-vertical-sm-hover {
            display: inline-block;
        }

        @media (min-width: 768px) {
            .topnav-hamburger {
                display: none;
            }
        }

        // Side menu
        .navbar-menu {
            width: $vertical-menu-width-sm;

            .btn-vertical-sm-hover {
                display: none;

                i {
                    &.ri-record-circle-line:before {
                        content: "\EB7D";
                    }
                }
            }

            // Sidebar Menu
            .navbar-nav {

                .badge {
                    display: none;
                }

                .menu-title {
                    text-align: center;

                    span {
                        display: none;
                    }

                    i {
                        display: block;
                        line-height: 36px;
                        font-size: 1rem;
                    }
                }

                .nav-link {
                    span {
                        display: none;
                    }

                    i {
                        font-size: 18px;
                    }

                    &:after,
                    &:before {
                        display: none;
                    }
                }

                .menu-dropdown {
                    display: none;
                }
            }

            &:hover {
                width: $vertical-menu-width  !important;
                border-right: 1px solid $border-color;

                @media (min-width: 1024.99px) {
                    .btn-vertical-sm-hover {
                        display: inline-block;
                    }

                    .navbar-brand-box {
                        text-align: left;
                    }
                }

                .navbar-nav {
                    .menu-dropdown.show {
                        display: block;
                    }
                }

                .nav-link {

                    i {
                        font-size: 18px;
                    }

                    span,
                    &:after,
                    &:before {
                        display: inline-block;
                    }
                }

                .logo {
                    span.logo-lg {
                        display: inline-block;
                    }

                    span.logo-sm {
                        display: none;
                    }
                }

                .menu-title {
                    text-align: left;

                    span {
                        display: inline-block;
                    }

                    i {
                        display: none;
                    }
                }
            }
        }
    }

    /********** sm-hover-active **********/
    &[data-sidebar-size="sm-hover-active"] {
        .navbar-brand-box {
            text-align: left;
        }

        .topnav-hamburger {
            display: none;
        }

        .btn-vertical-sm-hover {
            display: inline-block;
        }
    }

    &:is([data-sidebar-size="sm-hover"],[data-sidebar-size="sm-hover-active"]) {
        .navbar-header {
            @media (min-width: 1025px) {
                padding-left: $grid-gutter-width;
            }
        }
    }

    /********** sidebar sm **********/
    &[data-sidebar-size="sm"] {
        @media (min-width: 768px) {
            min-height: 1400px;

            .main-content {
                margin-left: $vertical-menu-width-sm;
            }
        }

        #page-topbar {
            z-index: calc(#{$vertical-menu-z-index} + 1);
        }

        .logo {
            span.logo-lg {
                display: none;
            }

            span.logo-sm {
                display: inline-block;
            }
        }

        // Side menu
        .navbar-menu {
            position: absolute;
            width: $vertical-menu-width-sm !important;
            padding-top: $header-height;

            .simplebar-mask,
            .simplebar-content-wrapper {
                overflow: visible !important;
            }

            .simplebar-scrollbar,
            .vertical-menu-btn {
                display: none !important;
            }

            .simplebar-offset {
                bottom: 0 !important;
            }

            // Sidebar Menu
            .navbar-nav {

                .badge {
                    display: none !important;
                }

                .menu-title {
                    text-align: center;
                    font-size: 1rem;

                    span {
                        display: none;
                    }

                    i {
                        display: block;
                        line-height: 36px;
                    }
                }

                .nav-link {
                    span {
                        display: none;
                    }

                    i {
                        font-size: 18px;
                    }

                    svg {
                        margin-right: 0px;
                    }

                    &:after,
                    &:before {
                        display: none;
                    }
                }

                .menu-dropdown {
                    display: none;
                    height: auto !important;
                }

                .nav-item {
                    position: relative;

                    &:hover {

                        >a.menu-link {
                            position: relative;
                            width: calc(200px + #{$vertical-menu-width-sm});
                            color: $white;
                            background: $vertical-menu-item-hover-bg;
                            transition: none;

                            span {
                                display: inline-block;
                                padding-left: 25px;
                            }

                            &:after {
                                display: block;
                                transform: rotate(90deg);
                                color: $white;
                            }
                        }

                        >.menu-dropdown {
                            display: block;
                            left: $vertical-menu-width-sm;
                            position: absolute;
                            width: 200px;
                            background: $vertical-menu-bg;
                            height: auto !important;
                            padding: 0.5rem 0;
                            border-radius: 0 0 3px 3px;
                            box-shadow: $vertical-menu-dropdown-box-shadow;
                        }
                    }
                }

                .nav-sm {
                    padding: 0;

                    .nav-item {
                        &:hover {
                            >.nav-link {
                                color: var(--#{$prefix
            }vertical-menu-item-hover-color);

                                &:after {
                                    color: inherit;
                                }
                            }
                        }
                    }

                    .nav-link {
                        &:after {
                            display: block !important;
                            transform: rotate(0deg) !important;
                        }
                    }

                    .menu-dropdown {
                        left: 100% !important;
                        top: 0;
                        border-radius: 3px !important;
                    }
                }
            }
        }

        .navbar-brand-box {
            position: fixed;
            padding: 0;
            width: calc(70px - 1px);
            z-index: 1;
            top: $top-tagbar-height;
            background: $vertical-menu-bg;
        }
        &[data-layout-style=detached] {
            .navbar-brand-box { 
                top: auto;
            }
        }
    }

    /********** sidebar md **********/
    &[data-sidebar-size="md"] {
        @media (min-width: 768px) {
            .main-content {
                margin-left: $vertical-menu-width-md;
            }
        }

        .navbar-brand-box {
            width: $vertical-menu-width-md;
        }

        // Side menu
        .navbar-menu {
            width: $vertical-menu-width-md  !important;

            // Sidebar Menu
            .navbar-nav {
                .nav-link {
                    display: block;
                    text-align: center;
                    padding: $vertical-menu-sub-item-padding-y $vertical-menu-sub-item-padding-x*0.35;

                    i {
                        display: block;
                    }

                    svg {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    &:before {
                        display: none !important;
                    }

                    &[data-bs-toggle=collapse]:after {
                        position: relative;
                        display: inline-block;
                        right: 0;
                        top: 3px;
                    }

                    &.menu-link {
                        &[data-bs-toggle=collapse]:after {
                            display: none;
                        }
                    }
                }

                .badge {
                    display: none !important;
                }

                .nav-sm {
                    padding-left: 0;
                }
            }


            .menu-title {
                text-align: center;

                span {
                    text-decoration: underline;
                }
            }
        }
    }

    /********** dark **********/
    &[data-sidebar="dark"] {
        &[data-sidebar-size="sm"] {
            // Side menu
            .navbar-menu {
                .navbar-nav {
                    .nav-sm {
                        .nav-link {
                            &:after {
                                display: block !important;
                                transform: rotate(0deg) !important;
                            }
                        }

                        .menu-dropdown {
                            left: 100% !important;
                            top: 0;
                            border-radius: 3px !important;
                        }
                    }
                }
            }
        }

        .logo-dark {
            display: none;
        }

        .logo-light {
            display: inline-block;
        }

    }

    &:is([data-sidebar="gradient"], [data-sidebar="gradient-2"], [data-sidebar="gradient-3"], [data-sidebar="gradient-4"]) {
        .logo-dark {
            display: none;
        }

        .logo-light {
            display: inline-block;
        }
}

    /********** light **********/
    &[data-sidebar="light"] {
        .logo-dark {
            display: inline-block;
        }

        .logo-light {
            display: none;
        }
    }

    /********** detached **********/
    &[data-layout-style="detached"] {

        #layout-wrapper,
        .main-content {
            min-height: 100vh;
        }

        @media (min-width: 1024.1px) {
            .main-content {
                position: relative;
            }

            #layout-wrapper {
                max-width: 95%;
                margin: 0 auto;
                padding-left: $grid-gutter-width;
            }

            .navbar-header {
                padding-left: $grid-gutter-width;
            }

            .navbar-menu {
                top: calc(#{$header-height} + calc(#{$grid-gutter-width} * 2.5));
                bottom: $grid-gutter-width;
                padding: 0;
                border-right: $vertical-menu-bg;
                border-radius: 5px;
                padding: 10px 0;
                z-index: 1;

                .navbar-brand-box {
                    display: none;
                }
            }
        }

        .footer {
            border-top: 1px dashed var(--#{$prefix}border-color);
        }

        .auth-page-wrapper {
            .footer {
                border-top: none;
            }
        }

        &[data-sidebar-size="sm"] {
            @media (min-width: 768px) {

                #layout-wrapper,
                .main-content {
                    min-height: 1400px;
                }
            }
        }


    }

   
}

.menu-title {
    letter-spacing: .05em;  
    cursor: default;
    font-size: 11px;
    text-transform: uppercase;
    color: var(--#{$prefix}vertical-menu-title-color);
    font-weight: $font-weight-semibold;

    span {
        padding: 12px 20px;
        display: inline-block;
    }

    i {
        display: none;
    }
}

.vertical-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($dark, 0.35);
    z-index: 1003;
    display: none;
}

// Mobile Menu
.vertical-sidebar-enable {
    .vertical-overlay {
        display: block;
    }

    .app-menu {
        margin-left: 0 !important;
        z-index: 1004;
    }
}

// rtl
[dir="rtl"] {
    .navbar-menu {
        .navbar-nav {
            .nav-link[data-bs-toggle=collapse] {
                &:after {
                    transform: rotate(180deg);
                }

                &[aria-expanded=true]:after {
                    transform: rotate(270deg);
                }
            }
        }
    }
}

.bg-vertical-gradient {
    background: linear-gradient(to left, #{$cyan-700}, #{$cyan-800});
}

.bg-vertical-gradient-2 {
    background: linear-gradient(to left, #{$teal-700}, #{$teal-800});
}

.bg-vertical-gradient-3 {
    background: linear-gradient(to left, #{$purple-700}, #{$purple-800});
}

.bg-vertical-gradient-4 {
    background: linear-gradient(to right, #{$green-700}, #{$green-800});
}

//sidebar-images
.sidebar-background {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
    opacity: .07;
}

[data-sidebar-image="img-1"] {
    .sidebar-background {
        background-image: url('../images/sidebar/img-1.jpg');
    }
}

[data-sidebar-image="img-2"] {
    .sidebar-background {
        background-image: url('../images/sidebar/img-2.jpg');
    }
}

[data-sidebar-image="img-3"] {
    .sidebar-background {
        background-image: url('../images/sidebar/img-3.jpg');
    }
}

[data-sidebar-image="img-4"] {
    .sidebar-background {
        background-image: url('../images/sidebar/img-4.jpg');
    }
}

[data-topbar="dark"] {
    .btn-ghost-dark {
        color: $gray-200;
        &:hover,
        &:focus,
        &:active {
            color: $gray-200;
            background-color: rgba($gray-200, 0.1)
        }
    }
}

.back-btn{
    position: fixed;
    left: 12px;
    bottom: 24px;
}